export function filterBuildAttendance(
  institutionId,
  selectedMode,
  selectedOrganiser,
  selectedAssessmentType,
  selectedStudyCircleId,
  selectedSubjectId,
) {
  const filter = [];
  filter.push({
    member: 'AssessmentResponse.institutionId',
    operator: 'equals',
    values: [institutionId],
  });
  if (+selectedMode !== -1) {
    filter.push({
      member: 'AssessmentMode.id',
      operator: 'equals',
      values: [selectedMode],
    });
  }
  if (+selectedAssessmentType !== -1) {
    filter.push({
      member: 'AssessmentType.id',
      operator: 'equals',
      values: [selectedAssessmentType],
    });
  }
  if (+selectedOrganiser !== -1) {
    filter.push({
      member: 'AssessmentOrganizer.id',
      operator: 'equals',
      values: [selectedOrganiser],
    });
  }
  if (+selectedStudyCircleId !== -1) {
    filter.push({
      member: 'StudyCircle.id',
      operator: 'equals',
      values: [selectedStudyCircleId],
    });
  }
  if (+selectedSubjectId !== -1) {
    filter.push({
      member: 'Subject.id',
      operator: 'equals',
      values: [selectedSubjectId],
    });
  }
  return filter;
}
