import React from 'react';
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import { orangeArrow, greenArrow } from '../../pages/Performance/Images/Images';
import { BsArrowRight } from 'react-icons/all';

const PerformanceTimeBar = (props) => {
  const { styles, time, groupTime, timeMark, trend } = props;

  const convertTimeMark = timeMark?.map((ele) => {
    return { ...ele, label: String(ele.label) };
  });
  // console.log("timeMark", timeMark, typeof timeMark, convertTimeMark)

  const CustomSlider = withStyles({
    rail: {
      backgroundImage: 'linear-gradient(45deg, #00A051, #D4B90E,#FFBE00,#FF9E00,#FF0000)',
      height: 13,
      borderRadius: '10px',
      opacity: 1,
    },
    track: { color: 'none', height: 10 },
  })(Slider);

  function valuetext(value) {
    return `${value}`;
  }

  return (
    <div className={styles.time}>
      <div className={styles.time_text}>
        <div className={styles.time_text_top}>
          <div className={styles.time_text_time}>TIME</div>
          <div className={styles.time_text_btn}>
            <span className={styles.time_text_num} style={{ paddingRight: '20px' }}>
              {time}
              <span className={styles.sec}>sec.</span>{' '}
            </span>
            <span style={{ marginLeft: '-20px' }}>
              {trend == '1' ? (
                <img src={greenArrow} alt="green_arrow" />
              ) : trend == '0' ? (
                <img src={BsArrowRight} alt="green_arrow" />
              ) : (
                <img src={orangeArrow} alt="orange arrow" />
              )}
            </span>
          </div>
        </div>
        <div className={styles.time_text_bottom}>
          <div className={styles.time_text_bottom_avg}>group avg.</div>
          <div>
            <span className={styles.time_text_num}>
              {groupTime}
              <span className={styles.sec}>sec.</span>{' '}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.timing}>
        <CustomSlider
          min={0}
          max={60}
          step={1}
          track={false}
          aria-labelledby="range-slider"
          valueLabelDisplay="auto"
          getAriaValueText={valuetext}
          mark={time}
          value={+time}
          marks={convertTimeMark}
        />
      </div>
    </div>
  );
};

export default PerformanceTimeBar;
