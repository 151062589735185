import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { makeStyles } from '@material-ui/core/styles';
//material-ui core components
import { List, Hidden, Drawer, ListItem, ListItemText, Dialog, Button, DialogActions, DialogTitle, Slide } from '@material-ui/core';
import styles from './SideBarStyle';
import img from '../../assets/images/left-sidebar-icons/logout.png';
import stylesToast from './sideBar.module.css';
// import { Clear } from '@material-ui/icons';
const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(prop, ref) {
  return <Slide direction="up" ref={ref} {...prop} />;
});

const SideBar = (props) => {
  const { occupation, routes, handleDrawerToggle, open, avatar, color, lecture } = props;

  const [modalopen, setModalOpen] = useState(false);

  const classes = useStyles();
  //varify if the routeName is active
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const handleClickOpen = () => {
    setModalOpen(true);
  };

  const handleClose = (values) => {
    if (values === 'yes') {
      setModalOpen(false);
      window.localStorage.clear();
    } else {
      setModalOpen(false);
    }
  };

  const logout = () => {
    document.body.style.overflowY = 'hidden';
    window.localStorage.clear();
  };

  //route contents link
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        // console.log(prop.name,':prop',key,':key')
        var listItemClasses = classNames({
          [' ' + classes[color]]: activeRoute(prop.layout + prop.path),
        });
        const whiteFontClasses = classNames({
          [' ' + classes.whiteFont]: activeRoute(prop.layout + prop.path),
        });
        return (
          //navigation links which navigates each contents
          <NavLink to={prop.layout + prop.path} className={classes.item} activeClassName="active" key={key}>
            <ListItem button className={classes.itemLink + listItemClasses}>
              {typeof prop.icon === 'string' ? (
                <img src={prop.icon} className={classNames(classes.itemIcon, whiteFontClasses)} alt="item Icon" />
              ) : (
                <prop.icon className={classNames(classes.itemIcon, whiteFontClasses)} />
              )}
              <ListItemText
                primary={prop.name}
                className={classNames(classes.itemText, whiteFontClasses)}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );
  //top header text in sidebar
  var brand = (
    <div className={classes.logo} style={{ borderBottom: 'none' }}>
      <div className={classNames(classes.logoLink)}>
        <div className={classes.logoImage}>
          <img src={avatar} alt="logo" className={classes.img} />
        </div>
        <div
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
            letterSpacing: 4,
            fontSize: '14px',
            paddingBottom: 15,
            lineHeight: 'normal',
          }}>
          {lecture}
        </div>
        <div
          style={{
            textAlign: 'center',
            lineHeight: 0,
            letterSpacing: 4,
            fontSize: '12px',
            marginTop: 0,
            paddingTop: 0,
          }}>
          {occupation}
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <Hidden mdUp implementation="css">
        {/* temporary drawer hides in mobile view */}
        <Drawer
          variant="temporary"
          anchor={'right'}
          open={open}
          classes={{ paper: classNames(classes.drawerPaper) }}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}>
          {brand}
          <div className={classes.sidebarWrapper}>
            <div>{links}</div>
            <Link to="/">
              <div className={classes.logout}>
                <div className={classes.images}>
                  <img src={img} alt="logoutImg" style={{ width: '24px', height: '24px' }} />
                </div>
                <div className={classes.logoutTxt} onClick={logout}>
                  Logout
                </div>
                <hr className={classes.hr_line}></hr>
                <div
                  style={{
                    color: '#73736fd4',
                    marginLeft: '15px',
                    marginTop: '-2px',
                    fontSize: '12px',
                  }}>
                  2020 &copy;Read analytics
                </div>
              </div>
            </Link>
          </div>
        </Drawer>
        {/* temporary drawer hides in mobile view */}
      </Hidden>
      <Hidden smDown implementation="css">
        {/* permanent drawer shows on mobile devices  */}
        <Drawer anchor={'left'} variant="permanent" open classes={{ paper: classNames(classes.drawerPaper) }}>
          {brand}
          <div
            className={classes.sidebarWrapper}
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 'calc(100vh - 211px)' }}>
            <div>{links}</div>
            {/* <Link to="/"> */}
            <div
              className={classes.logout}
              onClick={handleClickOpen}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignItems: 'center',
                width: '78%',
                padding: 0,
              }}>
              <div
                style={{
                  display: 'flex',
                }}>
                <div className={classes.images}>
                  <img src={img} alt="logoutImg" style={{ width: '24px', height: '24px' }} />
                </div>
                <div className={classes.logoutTxt} onClick={logout}>
                  Logout
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <hr className={classes.hr_line} style={{ width: '100%' }}></hr>
                <div
                  style={{
                    color: '#73736fd4',
                    marginLeft: '15px',
                    marginTop: '-2px',
                    fontSize: '12px',
                  }}>
                  2020 &copy;Read analytics
                </div>
              </div>
            </div>
            {/* </Link> */}
          </div>
          <Dialog
            open={modalopen}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description">
            <DialogTitle id="alert-dialog-slide-title">{'Are you sure to Logout?'}</DialogTitle>
            <DialogActions>
              <Button onClick={() => handleClose('cancel')} style={{ color: 'white', backgroundColor: '#919191' }}>
                Cancel
              </Button>
              <Button
                onClick={() => handleClose('yes')}
                style={{
                  backgroundColor: '#FC8802',
                  color: 'white',
                  padding: '0px',
                }}>
                <Link
                  to="/"
                  style={{
                    textDecoration: 'none',
                    color: 'white',
                    padding: '7px 19px',
                  }}>
                  Yes
                </Link>
              </Button>
            </DialogActions>
          </Dialog>
        </Drawer>
        {/* permanent drawer shows on mobile devices  */}
      </Hidden>
      <ToastContainer progressClassName={stylesToast.toastProgress} />
    </div>
  );
};
SideBar.propTypes = {
  handleDrawerToggle: PropTypes.func,
  logo: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};
export default SideBar;
