import React from 'react';
import Carousel, { consts } from 'react-elastic-carousel';
import roundTwoDecimal from '../roundTwoDecimal/roundTwoDecimal';
import darkNext from '../../assets/images/dark-next-arrow.png';
import darkPrev from '../../assets/images/dark-prev-arrow.png';
import gauseIcon from '../../assets/images/ra-org.png';

const AssessmentHeaderCarousel = (props) => {
  const {
    styles,
    assessmentDetails,
    grpSessionList,
    handleSessionCodeClick,
    readVelocityTopic,
    sessionCode,
    readVelocitySession,
    handleViewTopicPerformanceClick,
  } = props;
  return (
    <div className={styles.boxTwo_main_top}>
      <div className={styles.boxTwo_main_top_left}>
        <div>
          <span className={styles.box2_text1}>{assessmentDetails.topic_name}</span>
          <span className={styles.box2_text2} onClick={handleViewTopicPerformanceClick}>
            View Topic Performance
          </span>
        </div>
        <div className={styles.box2_list_left}>
          {grpSessionList?.length > 0 && grpSessionList?.length > 6 ? (
            <Carousel
              itemsToShow={7}
              initialActiveIndex={0}
              focusOnSelect={true}
              pagination={false}
              renderArrow={({ type, onClick }) => {
                const pointer =
                  type === consts.PREV ? (
                    <img src={darkPrev} alt="prev arr" style={{ marginTop: '0', cursor: 'pointer' }} />
                  ) : (
                    <img src={darkNext} alt="next arr" style={{ marginTop: '0', cursor: 'pointer' }} />
                  );
                return <div onClick={onClick}>{pointer}</div>;
              }}
            >
              {grpSessionList?.map((obj, index) => {
                return (
                  <div
                    //eslint-disable-next-line
                    className={sessionCode && sessionCode == obj?.session_code ? styles.box2_list_box_active : styles.box2_list_box}
                    key={index}
                    onClick={() =>
                      handleSessionCodeClick(obj?.assessment_id, obj?.session_code, obj?.assessment_date, obj?.study_circle_id)
                    }
                  >
                    <div>{obj?.session_code}</div>
                  </div>
                );
              })}
            </Carousel>
          ) : grpSessionList?.length > 0 ? (
            grpSessionList?.map((obj, index) => {
              return (
                <div
                  //eslint-disable-next-line
                  className={sessionCode && sessionCode == obj?.session_code ? styles.box2_list_box_active : styles.box2_list_box}
                  key={index}
                  onClick={() =>
                    handleSessionCodeClick(obj?.assessment_id, obj?.session_code, obj?.assessment_date, obj?.study_circle_id)
                  }
                >
                  <div>{obj?.session_code}</div>
                </div>
              );
            })
          ) : (
            'No group session found for selected topic'
          )}
        </div>
      </div>
      <div className={styles.boxTwo_main_top_right}>
        <div className={styles.box2_right}>
          <div className={styles.box2_right_L}>
            <div className={styles.box2_right_gause}>
              <div className={styles.box2_right_gause_img}>
                <img src={gauseIcon} alt="gause" />
              </div>
              <div className={styles.box2_right_gause_text}>Read Velocity</div>
            </div>
          </div>
          <div className={styles.box2_right_R}>
            <div className={styles.box2_right_session}>
              <div className={styles.box2_right_session_num}>
                {readVelocitySession['AssessmentResponse.avgVelocity']
                  ? roundTwoDecimal(readVelocitySession['AssessmentResponse.avgVelocity'])
                  : 0}
              </div>
              <div className={styles.box2_right_session_text}>Session</div>
            </div>
            <div className={styles.box2_right_session}>
              <div className={styles.box2_right_session_num}>{roundTwoDecimal(readVelocityTopic)}</div>
              <div className={styles.box2_right_session_text}>Topic</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentHeaderCarousel;
