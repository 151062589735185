import { CUBEJS_API } from '../../config/Api';
import cubejs from '@cubejs-client/core';
import { toast } from 'react-toastify';

function cubeFunction() {
  var cubeJsToken = window.localStorage.getItem('cmn_cubeJs_token');
  var cubejsAPIFunction = cubejs(cubeJsToken, {
    apiUrl: CUBEJS_API,
  });
  return cubejsAPIFunction;
}

function handleError(queryName, error) {
  console.log(queryName, error);
  if (error?.response?.error) {
    console.log(error.response.error);
    toast.error(error.response.error);
  }
  return error;
}

//RA-DASH-01 - Summary by Organizer
export async function fetchOrganiserSummary(institution_id, academicStartDate, academicEndDate) {
  // console.log("institution_id, academicStartDate, academicEndDate RA-DASH-01", { institution_id, academicStartDate, academicEndDate })
  const cubeJsApi = cubeFunction();
  const qScore = {
    measures: ['AssessmentResponse.assessmentCount', 'AssessmentResponse.questionsCount'],
    timeDimensions: [
      {
        dimension: 'AssessmentResponse.assessmentDate',
        dateRange: [`${academicStartDate}`, `${academicEndDate}`],
      },
    ],
    order: {
      'Assessment.count': 'desc',
    },
    dimensions: ['AssessmentOrganizer.name', 'AssessmentOrganizer.id'],
    filters: [
      {
        member: 'AssessmentResponse.institutionId',
        operator: 'equals',
        values: [`${institution_id}`],
      },
      {
        member: 'AssessmentOrganizer.name',
        operator: 'set',
      },
    ],
  };
  try {
    // const result = await cubeJsApi.load(qScore);
    const startTime = Date.now();
    const result = await cubeJsApi.load(qScore);
    const endTime = Date.now();
    const timeTaken = endTime - startTime;
    console.log('Time taken for fetchOrganiserSummary', timeTaken);
    // console.log('result inside cube query --RA-DASH-01  fetchOrganiserSummary', result);
    return result?.loadResponses[0]?.data;
  } catch (error) {
    handleError('RA-DASH-01', error);
  }
}

// RA-DASH-02: Fetch recent session table
export async function fetchRecentSessionTable(institution_id, academicStartDate, academicEndDate, academicPeriodId) {
  // console.log("institution_id, academicStartDate, academicEndDate RA-DASH-02", { institution_id, academicStartDate, academicEndDate })

  const cubeJsApi = cubeFunction();
  const qScore = {
    dimensions: [
      'AssessmentResponse.assessmentId',
      'AssessmentResponse.assessmentDate',
      'GroupSession.code',
      'Topic.name',
      'AssessmentType.name',
      'AssessmentOrganizer.name',
      'StudyCircle.name',
      // "StudyCircle.id",
      'AssessmentResponse.studyCircleId',
      'Assessment.groupSessionId',
      'Assessment.startTime',
      'Assessment.endTime',
    ],
    order: {
      'AssessmentResponse.assessmentDate': 'desc',
    },
    timeDimensions: [
      {
        dimension: 'AssessmentResponse.assessmentDate',
        dateRange: [`${academicStartDate}`, `${academicEndDate}`],
      },
    ],
    measures: [
      'AssessmentResponse.attendanceTotal',
      'AssessmentResponse.attendancePresent',
      'AssessmentResponse.avgVelocity',
      'AssessmentResponse.totalCorrects',
      'AssessmentResponse.totalNearCorrects',
      'AssessmentResponse.totalIncorrects',
      'AssessmentResponse.totalNotAnswereds',
      // "HandRaise.count"
      'AssessmentResponse.handRaiseCount',
    ],
    filters: [
      {
        member: 'AssessmentResponse.institutionId',
        operator: 'equals',
        values: [`${institution_id}`],
      },
      {
        //added filter on 6-Aug-2024
        member: 'AssessmentResponse.academicPeriod',
        operator: 'equals',
        values: [`${academicPeriodId}`],
      },
    ],
    limit: 5,
  };

  try {
    // const result = await cubeJsApi.load(qScore);
    // console.log("result inside cube query --fetchResentSessionTable", result)
    const startTime = Date.now();
    const result = await cubeJsApi.load(qScore);
    const endTime = Date.now();
    const timeTaken = endTime - startTime;
    console.log('Time taken for fetchRecentSessionTable', timeTaken);
    return result?.loadResponses[0]?.data;
  } catch (error) {
    handleError('RA-DASH-02', error);
  }
}

// Trend Query 1(RA-DASH-03-01)
export async function fetchDashboardValues(institution_id, academicStartDate, academicEndDate) {
  // console.log("institution_id, academicStartDate, academicEndDate Trend Query 1(RA-DASH-03-01)", { institution_id, academicStartDate, academicEndDate })

  const cubeJsApi = cubeFunction();
  const qScore = {
    order: {
      'AssessmentResponse.assessmentDate': 'desc',
    },
    // avgVelocityUntilLastMonth, studentLearningTrend Measures added here
    measures: [
      'AssessmentResponse.avgVelocity',
      'AssessmentResponse.avgVelocityUntilLastMonth',
      'AssessmentResponse.studentLearningTrend',
    ],
    timeDimensions: [
      {
        dimension: 'AssessmentResponse.assessmentDate',
        dateRange: [`${academicStartDate}`, `${academicEndDate}`],
      },
    ],
    filters: [
      {
        member: 'AssessmentResponse.institutionId',
        operator: 'equals',
        values: [`${institution_id}`],
      },
      {
        member: 'Student.name',
        operator: 'set',
      },
    ],
    dimensions: [
      'AssessmentResponse.studentId',
      'StudyCircleStudent.rollNumber',
      'Student.name',
      'StudyCircle.name',
      'Student.profilePicUrl',
      // "AssessmentResponse.raStudentGroupKey"
    ],
  };

  try {
    // const result = await cubeJsApi.load(qScore);
    // console.log("result inside cube query --fetchDashboardValues", result)
    const startTime = Date.now();
    const result = await cubeJsApi.load(qScore);
    const endTime = Date.now();
    const timeTaken = endTime - startTime;
    console.log('Time taken for fetchDashboardValues', timeTaken);
    return result?.loadResponses[0]?.data;
  } catch (error) {
    handleError('Trend Query 1(RA-DASH-03-01)', error);
  }
}

// Trend Query 2(RA-DASH-03-02)
export async function fetchDashboardTrend(institution_id, academicStartDate, academicEndDate) {
  // console.log("institution_id, academicStartDate, academicEndDate Trend Query 2(RA-DASH-03-02)", { institution_id, academicStartDate, academicEndDate })

  const cubeJsApi = cubeFunction();
  const qScore = {
    measures: ['AssessmentResponse.avgVelocity', 'AssessmentResponse.rowNumStudentTrend'],
    timeDimensions: [
      {
        dimension: 'AssessmentResponse.assessmentDate',
        granularity: 'month',
        dateRange: [`${academicStartDate}`, `${academicEndDate}`],
      },
    ],
    dimensions: ['AssessmentResponse.raStudentGroupKey'],

    filters: [
      {
        member: 'AssessmentResponse.institutionId',
        operator: 'equals',
        values: [`${institution_id}`],
      },
      {
        member: 'Student.name',
        operator: 'set',
      },
    ],
  };

  try {
    // const result = await cubeJsApi.load(qScore);
    // console.log("result inside cube query --fetchDashboardTrend", result)
    const startTime = Date.now();
    const result = await cubeJsApi.load(qScore);
    const endTime = Date.now();
    const timeTaken = endTime - startTime;
    console.log('Time taken for fetchDashboardTrend', timeTaken);
    return result?.loadResponses[0]?.data;
  } catch (error) {
    handleError('Trend Query 2(RA-DASH-03-02)', error);
  }
}
