import React from 'react';
import { Line } from 'react-chartjs-2';

const StudentRankChart = (props) => {
  const { value } = props;

  // console.log("value inside StudentRankChart", value)
  const data = {
    labels: ['1', '2', '3', '4'],
    datasets: [{ backgroundColor: 'rgba(179, 226, 256,.4)', data: value, borderWidth: 1, fill: true }],
  };
  const options = {
    scaleShowLabels: false,
    bezierCurve: false,
    layout: { padding: { top: 25 } },
    elements: { point: { radius: 0 }, line: { tension: 0 } },
    legend: { display: false },
    scales: {
      yAxes: [
        {
          ticks: { display: false, scaleShowLabels: false, min: -5, max: 5, stepSize: 3 },
          gridLines: { drawBorder: false, display: false },
          scaleLabel: { display: true },
        },
      ],
      xAxes: [{ gridLines: { display: false, drawBorder: false }, ticks: { display: false } }],
    },
  };

  return <Line data={data} options={options} width={95} height={100} />;
};

export default StudentRankChart;
