export { default as studio } from '../../../assets/images/studio.png';
export { default as classroom } from '../../../assets/images/classroom.png';
export { default as attendance } from '../../../assets/images/attendance.png';
export { default as darkNextArrow } from '../../../assets/images/dark-next-arrow.png';
export { default as darkPrevArrow } from '../../../assets/images/dark-prev-arrow.png';
export { default as darkPrevArrowDisable } from '../../../assets/images/dark-prev-arrow-disable.png';
export { default as calanderIcon } from '../../../assets/images/calender-icon.png';
export { default as NextArrow } from '../../../assets/images/next-arrow.png';
export { default as PrevArrow } from '../../../assets/images/prev-arrow.png';
export { default as ArrowLeft } from '../../../assets/images/arrow-left.png';
export { default as ArrowRight } from '../../../assets/images/arrow-right.png';
export { default as gause } from '../../../assets/images/ra-org.png';
export { default as readVelocityImg } from '../../../assets/images/read-velocity.png';
export { default as handRaise } from '../../../assets/images/raise-hand.png';
export { default as stucle } from '../../../assets/images/stucle.png';
export { default as allMode } from '../../../assets/images/all-mode.png';
