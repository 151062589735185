import { fetchScoreGraph } from '../../../../api/cube/studentAPI';
import percentage from './percentage';

export async function getScoreGraph(data) {
  // const scoreGraph = await fetchScoreGraph(filters);
  if (Object.keys(data).length > 0) {
    // const data = scoreGraph.loadResponses[0].data[0];
    const totalCorrects = +data['StudentSummary_YEAR.totalCorrects'];
    const totalIncorrects = +data['StudentSummary_YEAR.totalIncorrects'];
    const totalNearCorrects = +data['StudentSummary_YEAR.totalNearCorrects'];
    const totalNotAnswered = +data['StudentSummary_YEAR.totalNotAnswered'];

    const totalcount = totalCorrects + totalIncorrects + totalNearCorrects + totalNotAnswered;

    const dataObject = {
      correct: totalCorrects,
      incorrect: totalIncorrects,
      nearCorrect: totalNearCorrects,
      notAnswered: totalNotAnswered,
      total: totalcount,
      correctPer: typeof totalCorrects === 'number' ? percentage(totalCorrects, totalcount) + '%' : '--',
      incorrectPer: typeof totalIncorrects === 'number' ? percentage(totalIncorrects, totalcount) + '%' : '--',
      nearCorrectPer: typeof totalNearCorrects === 'number' ? percentage(totalNearCorrects, totalcount) + '%' : '--',
      notAnsweredPer: typeof totalNotAnswered === 'number' ? percentage(totalNotAnswered, totalcount) + '%' : '--',
    };
    // console.log("dataObject inside getScoreGraph", dataObject);
    return dataObject;
  } else {
    return {
      correct: 0,
      incorrect: 0,
      nearCorrect: 0,
      notAnswered: 0,
      total: 0,
      correctPer: 0,
      incorrectPer: 0,
      nearCorrectPer: 0,
      notAnsweredPer: 0,
    };
  }
}
