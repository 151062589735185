import https from '../../init/https';

//RA-LOG-05: Login to institution
export async function loginInstitution(user_id, institutionId) {
  console.log('RA-LOG-05: Login to institution', { user_id, institutionId });
  try {
    const getStudyCircleOpt = await https.post(`/login/${user_id}/login-to-inst/${institutionId}`);
    console.log('response RA-LOG-05: Login to institution', getStudyCircleOpt);
    return getStudyCircleOpt;
  } catch (err) {
    console.log('err RA-LOG-05: Login to institution', err);
    throw err;
  }
}
