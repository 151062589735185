import React, { Suspense, lazy } from 'react';
import moment from 'moment';
import LoadingBars from '../LoadingBars/LoadingBars';
import readvelocity from '../../assets/images/read-velocity.png';
import up from '../../assets/images/top-arrow.png';
import down from '../../assets/images/down-arrow.png';
import raiseHand from '../../assets/images/raise-hand.png';

// Implementation of Lazy loading
const ResultChart = lazy(() => import('../../pages/Session/results_chart'));
// import ResultChart from '../../pages/Session/results_chart';

const AssessmentTable = (props) => {
  const { styles, states, isLoading, sortSession, setStates, tableClick } = props;

  return (
    <div className={styles.tableResponsive}>
      {isLoading === true ? (
        <LoadingBars />
      ) : (
        <table id="students" className={states.t_body.length === 0 ? styles.sessionTable : ''}>
          <thead className={styles.sessTableHeader}>
            <tr className={styles.thead_row}>
              {states.t_head.map((el, index) => {
                return (
                  <th key={index} className={states.t_body.length === 0 ? styles.sessionTable_th : ''}>
                    <div
                      className={
                        el === 'Group Session #'
                          ? styles.th_el
                          : el === 'Type' || el === 'Title'
                            ? styles.th_start
                            : el === 'Date'
                              ? styles.pdl
                              : styles.th
                      }>
                      <span className={el === 'Attendance' /* || el === "Group Session #"  */ ? styles.mrl : ''}>{el}</span>
                      {el === 'Attendance' ? null : (
                        <div className="arrow">
                          <span className="arrUp" onClick={() => sortSession(true, el, states, setStates)}>
                            <img src={up} alt="up" />
                          </span>
                          <span className="arrDown" onClick={() => sortSession(false, el, states, setStates)}>
                            <img src={down} alt="down" />
                          </span>
                        </div>
                      )}
                    </div>
                  </th>
                );
              })}
              <th className={states.t_body.length === 0 ? styles.sessionTable_th : ''}>
                <img src={readvelocity} alt="raise" className={styles.readVelocity} />
              </th>
              <th className={states.t_body.length === 0 ? styles.sessionTable_th : ''}>
                <img src={raiseHand} alt="raise" className={styles.handRaise} />
              </th>
              <th className={states.t_body.length === 0 ? styles.sessionTable_th : ''}>
                <div className={styles.sessionResult}>Results</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {states.t_body.length === 0 ? (
              <tr
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: 'bold',
                }}>
                <td role="img" aria-label="jsx-a11y/accessible-emoji">
                  No Data Found.
                </td>
              </tr>
            ) : (
              states.t_body.map((el, index) => {
                let rv = el['AssessmentResponse.avgVelocity'];
                // let ap = el['AssessmentResponse.attendancePresent'];
                let range = rv;
                return (
                  <tr
                    key={index}
                    className="sessionTableRow"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      window.localStorage.setItem('groupSessionCode', el['GroupSession.code']);
                      window.localStorage.setItem('assesmentDate', el['AssessmentResponse.assessmentDate']);
                      window.localStorage.setItem(
                        'studyCircleId',
                        // el["StudyCircle.id"]
                        +el['AssessmentResponse.studyCircleId'],
                      );
                      tableClick(el['AssessmentResponse.assessmentId']);
                    }}>
                    <td key={Math.random()}>
                      <div className="Date" style={{ width: '60px' }}>
                        <div className="Day">
                          {moment(el['AssessmentResponse.assessmentDate']).format('ddd') +
                            '   ' +
                            moment(el['AssessmentResponse.assessmentDate']).format('D')}
                        </div>
                        <div className="Time">
                          {moment(el['AssessmentResponse.assessmentDate']).format('MMM') +
                            '   ' +
                            moment(el['AssessmentResponse.assessmentDate']).format('YYYY')}
                        </div>
                      </div>
                    </td>
                    <td className="sessionAssessmentTd">{el['GroupSession.code']}</td>
                    <td className={styles.topic}>
                      <div className={styles.topicVal}>{el['Topic.name']}</div>
                    </td>
                    <td className={styles.type}>
                      <div className={styles.typeVal}>{el['AssessmentType.name']}</div>
                    </td>
                    <td className="sessionOrganizerTd">{el['AssessmentOrganizer.name']}</td>
                    <td className="sessionStudyCircleTd">{el['StudyCircle.name']}</td>
                    <td className={styles.attendance}>
                      {el['AssessmentResponse.attendancePresent'] + '/' + el['AssessmentResponse.attendanceTotal']}
                    </td>
                    <td
                      className={
                        range >= 1 && range <= 2
                          ? 'a'
                          : range >= 2 && range <= 3
                            ? 'b'
                            : range >= 3 && range <= 4
                              ? 'c'
                              : range >= 4 && range <= 5
                                ? 'dM'
                                : 'a'
                      }>
                      <span
                        className={
                          range >= 1 && range <= 2
                            ? 'readVelocity_red'
                            : range >= 2 && range <= 3
                              ? 'readVelocity_yellow'
                              : range >= 3 && range <= 4
                                ? 'readVelocity_blue'
                                : range >= 4 && range <= 5
                                  ? 'readVelocity_green'
                                  : 'readVelocity_red'
                        }>
                        {(+el['AssessmentResponse.avgVelocity'] || 0).toFixed(2)}
                      </span>
                    </td>
                    <td className={styles.like}>{el['AssessmentResponse.handRaiseCount'] || 0}</td>
                    <td>
                      {
                        <Suspense fallback={<div>{'loading...'}</div>}>
                          <ResultChart
                            data={[
                              +el['AssessmentResponse.totalCorrects'],
                              +el['AssessmentResponse.totalNearCorrects'],
                              +el['AssessmentResponse.totalIncorrects'],
                              +el['AssessmentResponse.totalNotAnswereds'],
                            ]}
                            key={el['AssessmentResponse.assessmentId']}
                          />
                        </Suspense>
                      }
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AssessmentTable;
