import React, { memo } from 'react';
import { GridContainer } from '../../components/grid/index';
import StudentDetailDropdown from '../StudentDetailDropdown/StudentDetailDropdown';

import StudentImg from '../../assets/images/user2.png';

const StudentBasicInfo = (props) => {
  const { styles, studentName, studentProfilePic, studyCircleName, rollNumber, selectedValue, handleDropDown, options } = props;
  // console.log("options inside StudentBasicInfo", options)
  return (
    <div className={styles.top}>
      <GridContainer
        style={{
          backgroundColor: '#ffffff',
          padding: '10px',
          boxShadow: '5px 5px 10px 0 rgba(51, 51, 51, 0.05)',
        }}>
        <div className={styles.std_dtl}>
          <div className={styles.std_dtl_left}>
            <div className={styles.left_std_info}>
              <img
                src={studentProfilePic === null ? StudentImg : studentProfilePic}
                alt="student"
                className={styles.left_std_img}
              />
              <div className={styles.left_std_dtl}>
                <div className={styles.left_std_name}>{studentName}</div>
                <div className={styles.left_std_class}>
                  <div className={styles.left_std_rank + ' ' + styles.left_std_rank_no}>
                    <div>R# {rollNumber}</div>
                  </div>
                  <div className={styles.left_std_classname}>
                    <div>Study Circle: {studyCircleName}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.std_dtl_right} style={{ display: 'none' }}>
            <div className={styles.feedbacks}>
              <div className={styles.feedbacks_repo}>{'Test'}</div>
              <div className={styles.feedbacks_feed}>{'Test'}</div>
            </div>
          </div>
        </div>
        <div className={styles.StudentTopDropdownDiv}>
          <StudentDetailDropdown
            className={styles.topDropdown}
            name="mode"
            option={'Mode'}
            value={selectedValue.assessmentModeId}
            onChangeFun={handleDropDown}
            options={options.modeOptions}
            selectedId={'assessment_mode_id'}
            disable={true}
          />
          <StudentDetailDropdown
            className={styles.topDropdown}
            name="assessmentType"
            option={'Type'}
            value={selectedValue.assessmentTypeId}
            onChangeFun={handleDropDown}
            options={options.assessmentOptions}
            selectedId={'assessment_type_id'}
            disable={true}
          />
          <StudentDetailDropdown
            className={styles.topDropdown}
            name="organiser"
            option={'Organizer'}
            value={selectedValue.organiserId}
            onChangeFun={handleDropDown}
            options={options.organiserOptions}
            selectedId={'organiser_type_id'}
            disable={true}
          />
        </div>
      </GridContainer>
    </div>
  );
};

export default memo(StudentBasicInfo);
