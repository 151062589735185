import React from 'react';
// import styles from "./TestPage.module.css";
import cubejs from '@cubejs-client/core';
// import { QueryRenderer } from "@cubejs-client/react";
import { CUBEJS_API } from '../../config/Api';
import { useEffect } from 'react';
import { useState } from 'react';
// import { useCubeQuery } from "@cubejs-client/react";
// import { CubeProvider } from "@cubejs-client/react";

var count = 0;

function TestPage() {
  const [testState, setTestState] = useState(0);
  const q = {
    measures: ['Assessment.count', 'AssessmentResponse.questionsCount'],
    timeDimensions: [
      {
        dimension: 'AssessmentResponse.assessmentDate',
      },
    ],
    filters: [
      {
        member: 'Institution.id',
        operator: 'equals',
        values: ['244'],
      },
    ],
    dimensions: ['AssessmentOrganizer.name'],
    order: {
      'Assessment.count': 'desc',
    },
  };

  const cubeJsToken = window.localStorage.getItem('cmn_cubeJs_token');
  // console.log(cubeJsToken);
  const cubejsApi = cubejs(cubeJsToken, {
    apiUrl: CUBEJS_API,
  });

  useEffect(() => {
    async function fetchResult() {
      const result = await cubejsApi.load(q);
      console.log(result);
      count++;
      setTestState(count);
    }
    fetchResult();
    //eslint-disable-next-line
  }, []);

  console.log('testState', count, testState);

  async function handleClick() {
    console.log('Clicked');
    try {
      const result = await cubejsApi.load(q);
      console.log(result);
    } catch (error) {
      console.log(error.response);
    }
  }

  return (
    <div>
      <h1>Test Page</h1>
      <button onClick={handleClick}>Click</button>
      {/* <div>
        <QueryRenderer
          query={{
            dimensions: [
              "Assessment.title",
              "Assessment.date",
              "AssessmentType.id",
              "AssessmentType.name",
              "AssessmentOrganizer.id",
              "AssessmentOrganizer.name",
              "StudyCircle.id",
              "StudyCircle.name",
              "AssessmentMode.id",
              "AssessmentMode.name",
              "Subject.id",
              "Subject.name",
            ],
            order: {
              "AssessmentResponse.count": "desc",
            },
            measures: [
              "AssessmentResponse.count",
              "AssessmentResponse.totalCorrects",
              "AssessmentResponse.totalNearCorrects",
              "AssessmentResponse.totalIncorrects",
              "AssessmentResponse.totalNotAnswereds",
              "AssessmentResponse.avgVelocity",
            ],
          }}
          cubejsApi={cubejsApi}
          render={({ resultSet }) => {
            if (!resultSet) {
              return "Loading...";
            } else {
              // console.log(resultSet.loadResponses[0].data);
              console.log(resultSet);
              // return resultSet.loadResponses[0].data;
              return null;
            }
          }}
        />
        </div> */}
    </div>
  );
}

export default TestPage;
