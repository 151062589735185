import React from 'react';
import { AccuracyChart } from '../../pages/Performance/PerformanceSubjectDetails/Charts/index';
import { greenArrow, orangeArrow } from '../../pages/Performance/Images/Images';
import { BsArrowRight } from 'react-icons/all';

const PerformanceAccuracySpeedometer = (props) => {
  const { styles, cubeData } = props;

  return (
    <div className={styles.Accuracy}>
      <div className={styles.Accuracy_text}>
        <div className={styles.Accuracy_text_top}>
          <div className={styles.Accuracy_text_Accuracy}>Accuracy</div>
          <div className={styles.Accuracy_text_btn}>
            <span className={styles.Accuracy_text_num}>{`${cubeData.accuracy}`}</span>
            <span>
              {cubeData.accuracyPerformanceTrend == '1' ? (
                <img src={greenArrow} alt="green_arrow" />
              ) : cubeData.accuracyPerformanceTrend == '0' ? (
                <BsArrowRight className={styles.trendZero} />
              ) : (
                <img src={orangeArrow} alt="orange arrow" />
              )}
            </span>
          </div>
        </div>
        <div className={styles.Accuracy_text_bottom}>
          <div className={styles.Accuracy_text_bottom_avg}>Group Avg.</div>
          <div className={styles.Accuracy_text_bottom_num}>{`${cubeData.grpAccuracy}`}</div>
        </div>
      </div>
      <div
        className={styles.Accuracy_gause_chart}
        // style={{ border: "1px solid blue" }}
      >
        <AccuracyChart value={cubeData.accuracy} />
      </div>
    </div>
  );
};

export default PerformanceAccuracySpeedometer;
