import https from '../init/https';

// RA-AP-02: Get academic period given board and date
export const fetchAcademicPeriod = async (date, boardId) => {
  try {
    const academicInfo = await https.get(`/academic_period/fordate?board_id=${boardId}&date=${date}`);
    // console.log('academicInfo', academicInfo);
    return academicInfo?.data?.data;
  } catch (err) {
    console.log('error in fetching academic period', err);
  }
};

// RA-AP-04: Get academic_periods for an institution
export const fetchInstitutionAcademicPeriod = async (instId) => {
  try {
    const instAcademicPeriod = await https.get(`/academic_period/forinst?institution_id=${instId}`);
    // console.log('instAcademicPeriodList', instAcademicPeriod);
    return instAcademicPeriod?.data?.data;
  } catch (err) {
    console.log('error in fetching academic period', err);
    throw err;
  }
};
