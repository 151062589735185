import React from 'react';
import LoadingBars from '../LoadingBars/LoadingBars';
import up from '../../assets/images/top-arrow.png';
import down from '../../assets/images/down-arrow.png';
import roundTwoDecimal from '../roundTwoDecimal/roundTwoDecimal';
import { sortAttendance } from '../../pages/Attendance/sortAttendance';
import { rvImg } from '../../assets/img/imageConsts';

const AttendenceTable = (props) => {
  const { styles, states, cubeData, isLoading, setCubeData } = props;
  // console.log("cubeData inside AttendenceTable", cubeData)
  return (
    <div className={styles.tableResponsive}>
      {isLoading ? <LoadingBars /> : null}
      <table className={styles.attendTable}>
        <thead className={styles.theader}>
          <tr style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
            {states.t_head.map((el, index) => {
              return (
                <th key={index} className={styles.attend_header}>
                  <div className={el === 'Read Velocity' ? styles.attend_headerNameRV : styles.attend_headerName}>
                    {el === 'Read Velocity' ? <img src={rvImg} alt="readVelocity" /> : <span>{el}</span>}
                    {el === 'Study Circle' /* || el === "Attendance" */ ? (
                      ''
                    ) : (
                      <div className="arrow">
                        <span className="arrUp" onClick={() => sortAttendance(true, el, cubeData, setCubeData)}>
                          <img src={up} alt="up" />
                        </span>
                        <span className="arrDown" onClick={() => sortAttendance(false, el, cubeData, setCubeData)}>
                          <img src={down} alt="down" />
                        </span>
                      </div>
                    )}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className={styles.tableBody}>
          {cubeData?.tableData?.length === 0 ? (
            <tr
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 'bold',
              }}
            >
              <td role="img" aria-label="jsx-a11y/accessible-emoji">
                No Data Found.
              </td>
            </tr>
          ) : (
            cubeData?.tableData?.map((el, index) => {
              return (
                <tr className={styles.attendBody} key={index}>
                  <td>
                    <div>{el['StudyCircle.name']}</div>
                  </td>
                  <td>
                    <div>{el['Subject.name']}</div>
                  </td>
                  <td>
                    <div>{el['AssessmentOrganizer.name']}</div>
                  </td>
                  <td>
                    <div>{el['AssessmentType.name']}</div>
                  </td>
                  <td>
                    <div>{el['AssessmentResponse.assessmentCount']}</div>
                  </td>
                  <td>
                    <div>{el['AssessmentResponse.questionsCount']}</div>
                  </td>
                  <td>
                    <div>{el['AssessmentResponse.attendancePresent'] + '/' + el['AssessmentResponse.attendanceTotal']}</div>
                  </td>
                  <td
                    className={
                      el['AssessmentResponse.avgVelocity'] > 1 && el['AssessmentResponse.avgVelocity'] < 2
                        ? 'a'
                        : el['AssessmentResponse.avgVelocity'] > 2 && el['AssessmentResponse.avgVelocity'] < 3
                          ? 'b'
                          : el['AssessmentResponse.avgVelocity'] > 3 && el['AssessmentResponse.avgVelocity'] < 4
                            ? 'c'
                            : el['AssessmentResponse.avgVelocity'] > 4 && el['AssessmentResponse.avgVelocity'] <= 5
                              ? 'dM'
                              : 'a'
                    }
                    style={{ width: '70px' }}
                  >
                    <span
                      className={
                        el['AssessmentResponse.avgVelocity'] > 1 && el['AssessmentResponse.avgVelocity'] < 2
                          ? 'readVelocity_red'
                          : el['AssessmentResponse.avgVelocity'] > 2 && el['AssessmentResponse.avgVelocity'] < 3
                            ? 'readVelocity_yellow'
                            : el['AssessmentResponse.avgVelocity'] > 3 && el['AssessmentResponse.avgVelocity'] < 4
                              ? 'readVelocity_blue'
                              : el['AssessmentResponse.avgVelocity'] > 4 && el['AssessmentResponse.avgVelocity'] <= 5
                                ? 'readVelocity_green'
                                : 'readVelocity_red'
                      }
                      style={{ margin: '0', marginLeft: '4px' }}
                    >
                      {roundTwoDecimal(el['AssessmentResponse.avgVelocity'])}
                    </span>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AttendenceTable;
