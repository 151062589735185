// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".sideBar_toastProgress__1b2Xe {\n  /* background: #bbb8b5; */\n  animation-name: sideBar_ripple__HZOYx;\n}\n\n@keyframes sideBar_ripple__HZOYx {\n  0% {\n    width: 0;\n  }\n\n  100% {\n    width: 100%;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"toastProgress": "sideBar_toastProgress__1b2Xe",
	"ripple": "sideBar_ripple__HZOYx"
};
module.exports = exports;
