import React from 'react';
import { GridContainer } from '../grid';
import Carousel, { consts } from 'react-elastic-carousel';
import prevDark from '../../assets/images/dark-prev-arrow.png';
import nextDark from '../../assets/images/dark-next-arrow.png';
import readVelocityBlack from '../../assets/images/ra-black.png';
import StudentRankChart from '../../pages/Students/StudentsDetailscreen/StudentRankChart';

const StudentReadVelocity = (props) => {
  const { readVelocityData, styles } = props;

  return (
    <>
      <div className={styles.carousel_row}>
        <GridContainer
          style={{
            backgroundColor: '#ffffff',
            boxShadow: '5px 5px 10px 0 rgba(51, 51, 51, 0.05)',
          }}>
          <div className={styles.carouselReadVelocity}>
            <img src={readVelocityBlack} alt="read velocity" className={styles.carouselReadVelImg} />
            <p className={styles.carouselReadVelText}>Read Velocity</p>
          </div>
          <div className={styles.slider}>
            <Carousel
              // breakPoints={breakPoints}
              pagination={false}
              itemsToShow={4}
              renderArrow={({ type, onClick }) => {
                const pointer =
                  type === consts.PREV ? (
                    <img src={prevDark} alt="prev arr" className={styles.prevDark} />
                  ) : (
                    <img src={nextDark} alt="next arr" className={styles.nextDark} />
                  );
                return <div onClick={onClick}>{pointer}</div>;
              }}>
              {readVelocityData?.map(
                (el, index) =>
                  el.sub && (
                    <div key={index} className="rank_dtl_d">
                      <div>
                        <div
                          className={
                            +el.rank > 3.9 ? 'rankDtl_rank_green' : +el.rank > 2.9 ? 'rankDtl_rank_blue' : 'rankDtl_rank_red'
                          }>
                          {+el.rank}
                        </div>
                        <div className="rankDtl_sub">{el.sub}</div>
                      </div>

                      <div style={{ marginLeft: '-25px' }}>
                        <StudentRankChart value={el.value} />
                      </div>
                    </div>
                  ),
              )}
            </Carousel>
          </div>
        </GridContainer>
      </div>
    </>
  );
};

export default StudentReadVelocity;
