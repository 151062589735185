export default function filterBuildSession(
  institutionId,
  selectedMode,
  selectedOrganiser,
  selectedType,
  selectedSubject,
  selectedStudyCircle,
) {
  const filter = [];
  filter.push({
    member: 'AssessmentResponse.institutionId',
    operator: 'equals',
    values: [institutionId],
  });
  if (+selectedMode !== -1) {
    filter.push({
      member: 'Assessment.assessmentModeId',
      operator: 'equals',
      values: [selectedMode],
    });
  }
  if (+selectedOrganiser !== -1) {
    filter.push({
      member: 'Assessment.organizerTypeId',
      operator: 'equals',
      values: [selectedOrganiser],
    });
  }
  if (+selectedType !== -1) {
    filter.push({
      member: 'Assessment.assessmentTypeId',
      operator: 'equals',
      values: [selectedType],
    });
  }
  if (+selectedStudyCircle !== -1) {
    filter.push({
      member: 'AssessmentResponse.studyCircleId',
      operator: 'equals',
      values: [selectedStudyCircle],
    });
  }
  // Moved for timeDimensions within API RA-ASSESS-02
  // if (prevDate && nextDate) {
  //   filter.push({
  //     member: "AssessmentResponse.assessmentDate",
  //     operator: "inDateRange",
  //     values: [prevDate, nextDate],
  //   });
  // }
  if (selectedSubject !== -1) {
    filter.push({
      member: 'Assessment.subjectId',
      operator: 'equals',
      values: [institutionId],
    });
  }
  return filter;
}
