import React from 'react';
import darkNext from '../../assets/images/dark-next-arrow.png';
import darkPrev from '../../assets/images/dark-prev-arrow.png';

const AssessmentSerialNumber = (props) => {
  const { styles, displayCode, handleArrowClick } = props;

  return (
    <div className={styles.box1_left}>
      <img src={darkPrev} alt="prev arr" style={{ marginTop: '0', cursor: 'pointer' }} onClick={() => handleArrowClick('prev')} />
      <div className={styles.box1_left_text} style={{ margin: '0 10px' }}>
        {displayCode}
      </div>
      <img src={darkNext} alt="next arr" style={{ marginTop: '0', cursor: 'pointer' }} onClick={() => handleArrowClick('next')} />
    </div>
  );
};

export default AssessmentSerialNumber;
