import React from 'react';
import { Doughnut, Chart } from 'react-chartjs-2';

const QuestionsChart = ({ data, handleQuestionBarClick }) => {
  const chartData = {
    datasets: [
      {
        data: data.data,
        backgroundColor: ['#022AB8', '#5AAB00', '#FF0000', '#C3C3C3'],
      },
    ],
    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: ['Near correct', 'Correct', 'Incorrect', 'Not Answered'],
  };
  const options = {
    legend: {
      display: false,
    },
    plugins: {
      labels: false,
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
  Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
    draw: function () {
      originalDoughnutDraw.apply(this, arguments);

      var chart = this.chart;
      var width = chart.chart.width,
        height = chart.chart.height,
        ctx = chart.chart.ctx;

      // console.log(chart)
      var fontSize = +(height / 70).toFixed(2);
      ctx.font = fontSize + 'em sans-serif';
      ctx.textBaseline = 'middle';

      var text = this.chart.id + 1,
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;

      ctx.fillText(text, textX, textY);
    },
  });

  return (
    <div
      style={{ cursor: 'pointer' }}
      onClick={() => {
        // console.log("click calling");
        handleQuestionBarClick(data.questionId);
      }}
    >
      <Doughnut data={chartData} options={options} width={50} height={50} />
    </div>
  );
};

export default QuestionsChart;
