import React from 'react';
import './StudentTable.css';
// import down from "../../../assets/images/down-arrow.png"
// import up from "../../../assets/images/top-arrow.png"
import Nodata from '../../../assets/images/Nodata.png';

const StudentTable = (props) => {
  const { headerTextValues, tableData, table_format, fixed_columns } = props;

  // console.log("headerTextValues", headerTextValues)
  // console.log("tableData", tableData)
  // console.log("table_format", table_format)
  // console.log("fixed_columns", fixed_columns)
  let currentLeft = 0;

  return (
    <>
      {tableData?.length > 0 ? (
        <table className="student-table">
          <thead style={{ zIndex: 1 }}>
            <tr>
              {headerTextValues.map((ele, index) => {
                if (index === 0) currentLeft = 0;
                let sticky =
                  index < fixed_columns
                    ? {
                        position: 'sticky',
                        left: `${currentLeft}px`,
                        borderRadius: index === 0 ? '10px' : '0',
                      }
                    : {};
                sticky = {
                  ...sticky,
                  ...Object.values(table_format)[index]?.css,
                };
                currentLeft += Number(Object.values(table_format)[index]?.css.width.slice(0, -2)) + 10;

                return (
                  <th key={index} className="student-table__header-values" style={sticky}>
                    <div className="student-table__header-text">{ele}</div>
                    {/* <div className="student-table__sort">
                                            <img src={up} alt="up" />
                                            <img src={down} alt="down" />
                                        </div> */}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableData &&
              tableData.length > 0 &&
              tableData?.map((obj, tableIndex) => {
                return (
                  <tr
                    key={tableIndex}
                    className={tableIndex % 2 !== 0 ? 'student-table__data-values-odd' : 'student-table__data-values-even'}
                  >
                    {headerTextValues.map((header, index) => {
                      let refVariable = table_format[header].variable;

                      if (index === 0) currentLeft = 0;
                      let sticky = index < fixed_columns ? { position: 'sticky', left: `${currentLeft}px` } : {};
                      sticky = {
                        ...sticky,
                        ...Object.values(table_format)[index]?.css,
                      };
                      currentLeft += Number(Object.values(table_format)[index]?.css.width.slice(0, -2)) + 10;

                      if (table_format[header].type === 'string') {
                        return (
                          <td
                            key={index}
                            style={sticky}
                            className={tableIndex % 2 !== 0 ? 'student-table__data-values-odd' : 'student-table__data-values-even'}
                          >
                            {Object.keys(obj).length !== 0 && obj[refVariable] ? obj[refVariable].toUpperCase() : 'Null'}
                          </td>
                        );
                      } else if (table_format[header].type === 'float') {
                        return (
                          <td
                            key={index}
                            style={sticky}
                            className={tableIndex % 2 !== 0 ? 'student-table__data-values-odd' : 'student-table__data-values-even'}
                          >
                            {Object.keys(obj).length !== 0 && Number(+obj[refVariable]).toFixed(2)}
                          </td>
                        );
                      } else if (table_format[header].type === 'number') {
                        return (
                          <td
                            key={index}
                            style={sticky}
                            className={tableIndex % 2 !== 0 ? 'student-table__data-values-odd' : 'student-table__data-values-even'}
                          >
                            {Object.keys(obj).length !== 0 && obj[refVariable]}
                          </td>
                        );
                      } else if (table_format[header].type === 'milliSec') {
                        return (
                          <td
                            key={index}
                            style={sticky}
                            className={tableIndex % 2 !== 0 ? 'student-table__data-values-odd' : 'student-table__data-values-even'}
                          >
                            {Math.round(parseInt(obj[refVariable].toString()) / 1000, 0)}
                          </td>
                        );
                      } else return null;
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      ) : (
        <div className="student-table__No-data-msg-holder">
          <img src={Nodata} alt="no-table-data" style={{ width: '300px', height: 'auto' }} />
          <div className="student-table__No-data-msg">{'No data found'}</div>
        </div>
      )}
    </>
  );
};

export default StudentTable;
