import React from 'react';
import AnswerDetailChart from '../../pages/Session/session_render/charts/AnswerDetailChart';

const AssessmentAnswerDetails = (props) => {
  const { styles, answerOptions, questionId, charts, groupInstitutionId, institutionId, startDate, endDate } = props;

  return (
    <>
      <div className={styles.Question}>
        <div className="box4">
          <div className="box4_A">
            <div className="left_Txt">Correct Options</div>

            {answerOptions.length !== 0
              ? answerOptions.map((option, keyOption) => {
                  return option.is_correct_option === true ? (
                    <div key={keyOption} className="right_grd_optA">
                      <span>{option.option_text}</span>
                    </div>
                  ) : null;
                })
              : null}
          </div>
          <div className="box4_B">
            <div className="right_Txt">Near Correct Options</div>
            {answerOptions.length !== 0
              ? answerOptions.map((option, keyOption) => {
                  return option.is_near_correct_option === true ? (
                    <div key={keyOption} className="right_grd_optA">
                      <span>{option.option_text}</span>
                    </div>
                  ) : null;
                })
              : null}
            {answerOptions.length !== 0 ? (
              answerOptions.filter((options) => {
                return options.is_near_correct_option === true;
              }).length === 0 ? (
                <span className="noneOption">None</span>
              ) : null
            ) : null}
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: '#fff',
          marginTop: '20px',
          borderRadius: '2px',
          boxShadow: '5px 5px 10px 0 rgba(51, 51, 51, 0.05)',
        }}
      >
        <div style={{ padding: '20px' }}>
          <AnswerDetailChart
            questionId={questionId ? questionId : charts[0]?.questionId ? charts[0]?.questionId : 0}
            groupInstitutionId={groupInstitutionId ? groupInstitutionId : 0}
            institutionId={institutionId}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      </div>
    </>
  );
};

export default AssessmentAnswerDetails;
