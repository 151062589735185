import React from 'react';
import { orangeArrow, greenArrow } from '../../pages/Students/Images/Images';

const StudentScoreBar = (props) => {
  const { styles, studentScore, groupScore, scoreGraph, studentScoreTrend, studentDetails } = props;

  return (
    <div className={styles.score}>
      <div className={styles.score_text}>
        <div className={styles.score_text_top}>
          <div className={styles.score_text_score}>score</div>
          <div className={styles.score_text_btn}>
            <span className={styles.score_text_num} style={{ paddingRight: '6px' }}>
              {studentScore}
            </span>
            <span>
              {+studentScoreTrend ? <img src={greenArrow} alt="green_arrow" /> : <img src={orangeArrow} alt="orange arrow" />}
            </span>
          </div>
        </div>
        <div className={styles.score_text_bottom}>
          <div className={styles.score_text_bottom_avg}>group avg.</div>
          <div className={styles.score_text_bottom_num}>{groupScore}</div>
        </div>
      </div>
      <div className={styles.score_right_div}>
        <div className={styles.score_right_div_bar}>
          <div className={styles.green} style={{ width: [scoreGraph.correctPer] }}></div>
          <div className={styles.blue} style={{ width: [scoreGraph.nearCorrectPer] }}></div>
          <div className={styles.red} style={{ width: [scoreGraph.incorrectPer] }}></div>
          <div className={styles.grey} style={{ width: [scoreGraph.notAnsweredPer] }}></div>
        </div>
        <div className={styles.score_table}>
          <div className={styles.score_table_num}>
            {/* {state.score.scores.map((el, i) => ( */}
            <div className={styles.score_val}>
              <div className={styles.score_val_total}>{studentDetails.questions.questionsCount}</div>
              <div>{scoreGraph.correctPer}</div>
              <div>{scoreGraph.nearCorrectPer}</div>
              <div>{scoreGraph.incorrectPer}</div>
              <div>{scoreGraph.notAnsweredPer}</div>
            </div>
            {/* ))} */}
          </div>
          <div className={styles.score_table_test}>
            {['questions', 'correct', 'near correct', 'incorrect', 'not answered'].map((el, index) => (
              <div
                key={index}
                className={
                  el === 'questions'
                    ? styles.question
                    : el === 'correct'
                      ? styles.correct
                      : el === 'near correct'
                        ? styles.nCorrect
                        : el === 'incorrect'
                          ? styles.iCorrect
                          : el === 'not answered'
                            ? styles.nAnswer
                            : false
                }>
                {el}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentScoreBar;
