/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { GridContainer, GridItem } from '../../components/grid/index';
import './index.css';
import Datepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import search_icon from '../../assets/images/search-icon.png';
import styles from './Session.module.css';
import { toast } from 'react-toastify';
import moment from 'moment';
import { getSessionRowsAPI } from '../../api/cube/sessionAPI';
import {
  getModes,
  getOrganiser,
  getStudyCircle,
  getSubjectStudy,
  getAssessmentType,
} from '../../api/masterReadAnalytics/dropdownAPI';
import { sortSession } from './sortSession';
import filterBuildSession from './filterBuildSession';
import { fetchAcademicPeriod } from '../../utils/fetchAcademicPeriod';
import { whichTimeFrame } from '../../components/whichTImeFrame/whichTimeFrame';
import promiseAllCall from '../../utils/promise';
import AssessmentTableDropdown from '../../components/AssessmentTableDropdown/AssessmentTableDropdown';
import AssessmentTable from '../../components/AssessmentTable/AssessmentTable';
import PerformanceTableDropdown from '../../components/PerformanceTableDropdown/PerformanceTableDropdown';

const Session = () => {
  const calendarDate =
    localStorage.getItem('oncalendardate') === null ? new Date() : new Date(localStorage.getItem('oncalendardate'));
  const stylesss = {
    monthAct: {
      backgroundColor: '#FD8500',
      color: '#fff',
      fontWeight: 500,
    },
    dayAct: {
      backgroundColor: '#FD8500',
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
      color: '#fff',
      fontWeight: 500,
    },
    yearAct: {
      backgroundColor: '#FD8500',
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
      color: '#fff',
      fontWeight: 500,
    },
  };
  // eslint-disable-next-line
  let prevDate, nextDate;
  let filter = [];
  let month = calendarDate.getMonth();
  let today = calendarDate.getDate();
  let year = calendarDate.getFullYear();

  const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  let totaldaysInMonth = getDaysInMonth(month + 1, year);
  let timeFrame = window.localStorage.getItem('timeFrame');
  if (timeFrame) {
    if (timeFrame === 'month') {
      prevDate = `${year}-${month + 1}-01`;
      nextDate = year + '-' + (month + 1) + '-' + totaldaysInMonth;
    }
    if (timeFrame === 'day') {
      prevDate = year + '-' + (month + 1) + '-' + today;
      nextDate = year + '-' + (month + 1) + '-' + today;
    }
    if (timeFrame === 'year') {
      prevDate = year + '-' + 1 + '-' + 1;
      nextDate = year + '-' + 12 + '-' + 31;
    }
  } else {
    prevDate = `${year}-${month + 1}-01`;
    nextDate = year + '-' + (month + 1) + '-' + totaldaysInMonth;
  }

  const institutionId = window.localStorage.getItem('cmn_school_id');
  const academicPeriodArr = window.localStorage.getItem('instAcademicPeriods');
  const sortedAcademicPerArr =
    academicPeriodArr?.length > 0 &&
    [...JSON.parse(academicPeriodArr)].sort(
      (a, b) => new Date(moment(a.start_date).format('YYYY-MM-DD')) - new Date(moment(b.start_date).format('YYYY-MM-DD')),
    );
  // console.log("sortedAcademicPerArr", sortedAcademicPerArr)

  const academicStartDate = sortedAcademicPerArr?.length > 0 && new Date(sortedAcademicPerArr[0].start_date);
  const academicEndDate =
    sortedAcademicPerArr?.length > 0 && new Date(sortedAcademicPerArr[sortedAcademicPerArr.length - 1].end_date);
  // console.log("date onChange", { startDate: moment(academicStartDate).format("DD-MM-YYYY"), endDate: moment(academicEndDate).format("DD-MM-YYYY") })
  const academic_period = JSON.parse(localStorage.getItem('academic_period'));

  const [isLoading, setIsLoading] = useState(false);
  const [startdate, setStartDate] = useState(calendarDate);
  const [states, setStates] = useState({
    isMonth: timeFrame ? (timeFrame == 'month' ? true : false) : true,
    isDay: timeFrame == 'day' ? true : false,
    isYear: timeFrame == 'year' ? true : false,
    t_head: ['Date', 'Group Session #', 'Title', 'Type', 'Organizer', 'Study Circle', 'Attendance'],
    t_body: [],
    modeOptions: [],
    organiserOptions: [],
    typeOptions: [],
    studyOptions: [],
    teacherOptions: [],
    subjectOptions: [],
  });
  const [selectedValues, setselectedValues] = useState({
    modeId: -1,
    organiserId: -1,
    typeId: -1,
    studyCircleId: -1,
    teacherId: -1,
    subjectId: -1,
  });
  const [filterPrevDate, setFilterPrevDate] = useState(moment(prevDate).format('YYYY-MM-DD'));
  const [filterNextDate, setFilterNextDate] = useState(moment(nextDate).format('YYYY-MM-DD'));
  const [academicPeriodId, setAcademicPeriodId] = useState(-1);

  useEffect(() => {
    const fetchDropdowns = async () => {
      try {
        const callAll = await promiseAllCall([getModes(), getOrganiser(), getAssessmentType(), getStudyCircle(institutionId)]);
        setStates((prevState) => {
          return {
            ...prevState,
            modeOptions: callAll[0]?.data?.data,
            organiserOptions: callAll[1]?.data?.data,
            typeOptions: callAll[2]?.data?.data,
            studyOptions: callAll[3]?.data?.data,
          };
        });
      } catch (err) {
        console.log('err inside fetchDropdowns', err);
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        }
      }
    };
    fetchDropdowns();
    dateData();
  }, []);

  useEffect(() => {
    const fetchDropdownDependencies = async () => {
      try {
        const subjects = await getSubjectStudy(institutionId, selectedValues.studyCircleId);
        setStates((prevState) => {
          return { ...prevState, subjectOptions: subjects?.data?.data };
        });
      } catch (err) {
        console.log('err inside fetchDropdownDependencies', err);
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        }
      }
    };
    fetchDropdownDependencies();
  }, [selectedValues.studyCircleId]);

  useEffect(() => {
    states.isYear && fetchAcademicInfo();
  }, [states.isYear]);

  useEffect(() => {
    const fetchAssessmentTable = async () => {
      console.log('fetchAssessmentTable dates', {
        prevDate: filterPrevDate,
        nextDate: filterNextDate,
      });
      try {
        filter = filterBuildSession(
          institutionId,
          selectedValues.modeId,
          selectedValues.organiserId,
          selectedValues.typeId,
          selectedValues.subjectId,
          selectedValues.studyCircleId,
        );
        const tableData = await getSessionRowsAPI(filter, filterPrevDate, filterNextDate);
        console.log('assessmentTableData', tableData?.loadResponses[0]?.data);
        setStates((prevState) => {
          return { ...prevState, t_body: tableData?.loadResponses[0]?.data };
        });
        setIsLoading(false);
      } catch (err) {
        console.log('err inside fetchDropdownDependencies', err);
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        }
        setIsLoading(false);
      }
    };
    setIsLoading(true);
    fetchAssessmentTable();
  }, [
    selectedValues.modeId,
    selectedValues.organiserId,
    selectedValues.studyCircleId,
    selectedValues.subjectId,
    selectedValues.typeId,
    filterPrevDate,
    filterNextDate,
  ]);

  const fetchAcademicInfo = async () => {
    const boardId = JSON.parse(localStorage.getItem('board_info'))?.board_id;
    const result = await fetchAcademicPeriod(moment(startdate).format('YYYY-MM-DD'), boardId);
    // console.log("result", result, { prevDate: moment(result?.start_date).format("YYYY-MM-DD"), nextDate: moment(result?.end_date).format("YYYY-MM-DD") })
    setAcademicPeriodId(result?.academic_period_id);
    localStorage.setItem('academic_period', JSON.stringify(result));
    setFilterPrevDate(moment(result?.start_date).format('YYYY-MM-DD'));
    setFilterNextDate(moment(result?.end_date).format('YYYY-MM-DD'));
    return result;
  };

  function dateData() {
    if (timeFrame) {
      setStates((prevState) => {
        return {
          ...prevState,
          isMonth: timeFrame === 'month' ? true : false,
          isDay: timeFrame === 'day' ? true : false,
          isYear: timeFrame === 'year' ? true : false,
        };
      });
    }
  }

  const handleClick = async (values) => {
    setIsLoading(true);
    if (values === 'd') {
      prevDate = `${year}-${month + 1}-${today}`;
      nextDate = year + '-' + (month + 1) + '-' + today;
      setFilterPrevDate(moment(prevDate).format('YYYY-MM-DD'));
      setFilterNextDate(moment(nextDate).format('YYYY-MM-DD'));
    } else if (values === 'm') {
      prevDate = `${year}-${month + 1}-01`;
      nextDate = year + '-' + (month + 1) + '-' + totaldaysInMonth;
      setFilterPrevDate(moment(prevDate).format('YYYY-MM-DD'));
      setFilterNextDate(moment(nextDate).format('YYYY-MM-DD'));
    }
    /*
    else if (values === 'y') {
      // prevDate = `${year}-01-01`;
      // nextDate = `${year}-12-31`;
      prevDate = moment(academic_period.start_date).format('YYYY-MM-DD');
      nextDate = moment(academic_period.end_date).format('YYYY-MM-DD');
    } 
     */
    setStates({
      ...states,
      isDay: values === 'd' ? true : false,
      isMonth: values === 'm' ? true : false,
      isYear: values === 'y' ? true : false,
    });
    whichTimeFrame(year, month, today, {
      isDay: values === 'd' ? true : false,
      isMonth: values === 'm' ? true : false,
      isYear: values === 'y' ? true : false,
    });
  };

  const handleChange = async (date, arrowdate) => {
    setIsLoading(true);
    if (arrowdate === 'prev') {
      let currentdate = new Date(date);
      let previousday = new Date(currentdate.getTime() - 24 * 60 * 60 * 1000);
      let today = states.isDay ? previousday.getDate() : currentdate.getDate();
      let month = states.isMonth ? (currentdate.getMonth() === 0 ? 11 : currentdate.getMonth() - 1) : previousday.getMonth();
      let year = states.isYear
        ? currentdate.getFullYear() - 1
        : currentdate.getMonth() === 0
          ? currentdate.getFullYear() - 1
          : previousday.getFullYear();

      const checkMinDate =
        new Date(moment(academicStartDate).format('YYYY-MM-DD')) <=
        new Date(moment(new Date(year, month, today)).format('YYYY-MM-DD'));
      const checkMaxDate =
        new Date(moment(academicEndDate).format('YYYY-MM-DD')) >=
        new Date(moment(new Date(year, month, today)).format('YYYY-MM-DD'));

      if (checkMinDate && checkMaxDate) {
        setStartDate(new Date(year, month, today));
        whichTimeFrame(year, month, today, states);
        prevDate = states.isDay
          ? `${year}-${month + 1}-${today}`
          : states.isMonth
            ? `${year}-${month + 1}-01`
            : states.isYear
              ? `${moment(academic_period.start_date).format('YYYY-MM-DD')}`
              : null;

        nextDate = states.isDay
          ? `${year}-${month + 1}-${today}`
          : states.isMonth
            ? year + '-' + (month + 1) + '-' + totaldaysInMonth
            : states.isYear
              ? `${moment(academic_period.end_date).format('YYYY-MM-DD')}`
              : null;

        // console.log("PREV prevDate, nextDate", { prevDate, nextDate, currentdate, states })
        setFilterPrevDate(moment(prevDate).format('YYYY-MM-DD'));
        setFilterNextDate(moment(nextDate).format('YYYY-MM-DD'));
      } else {
        toast.info(
          `Please select the dates between ${moment(academicStartDate).format('MMMM Do YYYY')} TO ${moment(academicEndDate).format('MMMM Do YYYY')} for accessing the data`,
        );
      }
    } else if (arrowdate === 'next') {
      let currentdate = new Date(date);
      let previousday = new Date(currentdate.getTime() + 24 * 60 * 60 * 1000);
      let today = states.isDay ? previousday.getDate() : currentdate.getDate();
      let month = states.isMonth ? (currentdate.getMonth() === 11 ? 0 : currentdate.getMonth() + 1) : previousday.getMonth();
      let year = states.isYear
        ? currentdate.getFullYear() + 1
        : currentdate.getMonth() === 11
          ? currentdate.getFullYear() + 1
          : previousday.getFullYear();

      const checkMinDate =
        new Date(moment(academicStartDate).format('YYYY-MM-DD')) <=
        new Date(moment(new Date(year, month, today)).format('YYYY-MM-DD'));
      const checkMaxDate =
        new Date(moment(academicEndDate).format('YYYY-MM-DD')) >=
        new Date(moment(new Date(year, month, today)).format('YYYY-MM-DD'));

      if (checkMinDate && checkMaxDate) {
        setStartDate(new Date(year, month, today));
        whichTimeFrame(year, month, today, states);

        prevDate = states.isDay
          ? `${year}-${month + 1}-${today}`
          : states.isMonth
            ? `${year}-${month + 1}-01`
            : states.isYear
              ? // ? `${year}-12-31`
                `${moment(academic_period.start_date).format('YYYY-MM-DD')}`
              : null;

        nextDate = states.isDay
          ? `${year}-${month + 1}-${today}`
          : states.isMonth
            ? year + '-' + (month + 1) + '-' + totaldaysInMonth
            : states.isYear
              ? // ? `${year}-12-31`
                `${moment(academic_period.end_date).format('YYYY-MM-DD')}`
              : null;
        // console.log("NEXT prevDate, nextDate", { prevDate, nextDate })
        setFilterPrevDate(moment(prevDate).format('YYYY-MM-DD'));
        setFilterNextDate(moment(nextDate).format('YYYY-MM-DD'));
      } else {
        toast.info(
          `Please select the dates between ${moment(academicStartDate).format('MMMM Do YYYY')} TO ${moment(academicEndDate).format('MMMM Do YYYY')} for accessing the data`,
        );
      }
    } else if (arrowdate === 'calander') {
      const checkMinDate = new Date(moment(academicStartDate).format('YYYY-MM-DD')) <= new Date(moment(date).format('YYYY-MM-DD'));
      const checkMaxDate = new Date(moment(academicEndDate).format('YYYY-MM-DD')) >= new Date(moment(date).format('YYYY-MM-DD'));

      if (checkMinDate && checkMaxDate) {
        setStartDate(date);
        let date_val = new Date(date);
        localStorage.setItem('oncalendardate', date_val);
        month = date_val.getMonth();
        today = date_val.getDate();
        year = date_val.getFullYear();

        prevDate = states.isDay
          ? `${year}-${month + 1}-${today}`
          : states.isMonth
            ? `${year}-${month + 1}-01`
            : states.isYear
              ? // ? `${year}-12-31`
                `${moment(academic_period.start_date).format('YYYY-MM-DD')}`
              : null;

        nextDate = states.isDay
          ? `${year}-${month + 1}-${today}`
          : states.isMonth
            ? year + '-' + (month + 1) + '-' + totaldaysInMonth
            : states.isYear
              ? // ? `${year}-12-31`
                `${moment(academic_period.end_date).format('YYYY-MM-DD')}`
              : null;
        // console.log("CALENDER prevDate, nextDate", { prevDate, nextDate })
        setFilterPrevDate(moment(prevDate).format('YYYY-MM-DD'));
        setFilterNextDate(moment(nextDate).format('YYYY-MM-DD'));
      } else {
        toast.info(
          `Please select the dates between ${moment(academicStartDate).format('MMMM Do YYYY')} TO ${moment(academicEndDate).format('MMMM Do YYYY')} for accessing the data`,
        );
      }
    }
  };

  const changeMode = async (e, name) => {
    // console.log("e, name", e.target.value, name);
    if (name === 'mode') {
      setselectedValues((prevState) => {
        return { ...prevState, modeId: e.target.value };
      });
    } else if (name === 'organizer') {
      setselectedValues((prevState) => {
        return { ...prevState, organiserId: e.target.value };
      });
    } else if (name === 'type') {
      setselectedValues((prevState) => {
        return { ...prevState, typeId: e.target.value };
      });
    } else if (name === 'studyCircle') {
      setselectedValues((prevState) => {
        return { ...prevState, studyCircleId: e.target.value };
      });
    } else if (name === 'subject') {
      setselectedValues((prevState) => {
        return { ...prevState, subjectId: e.target.value };
      });
    } else if (name === 'academic_period') {
      setAcademicPeriodId(e.target.value);

      const findAcademicPeriod = JSON.parse(academicPeriodArr)?.filter((ele) => ele.academic_period_id == e.target.value);
      setFilterPrevDate(moment(findAcademicPeriod[0]?.start_date).format('YYYY-MM-DD'));
      setFilterNextDate(moment(findAcademicPeriod[0]?.end_date).format('YYYY-MM-DD'));
    } else return null;
  };

  const tableClick = (selectedrow) => {
    localStorage.setItem('assessmentList', JSON.stringify(states.t_body));
    return (window.location.href = '/admin/assessments/' + selectedrow);
  };

  const calandarSection = () => {
    return (
      <div className="calanderContainer">
        <div className="calander">
          <div className="calCont">
            <div className="dateDate">
              {!states?.isYear ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}>
                  <img
                    src={require('../../assets/images/prev-arrow.png')}
                    alt="prev"
                    onClick={(e) => handleChange(startdate, 'prev')}
                    style={{ marginLeft: '40px' }}
                  />
                  <Datepicker
                    id={'fromdate'}
                    selected={startdate}
                    onChange={(e) => handleChange(e, 'calander')}
                    dateFormat={states.isDay ? 'd MMM yyyy' : states.isMonth ? 'MMM yyyy' : states.isYear ? 'yyyy' : null}
                    className={styles.input}
                    readOnly={false}
                    showMonthYearPicker={states.isMonth ? true : null}
                    showYearDropdown={states.isYear ? true : null}
                    // show={()=>test()}
                  />
                  <img
                    src={require('../../assets/images/next-arrow.png')}
                    alt="next"
                    onClick={(e) => handleChange(startdate, 'next')}
                  />
                  <label htmlFor="fromdate">
                    <img
                      src={require('../../assets/images/calender-icon.png')}
                      alt="cal"
                      // onChange={(e) => handleChange(e, "calander")}
                      style={{ margin: '0px 30px', cursor: 'pointer' }}
                    />
                  </label>
                </div>
              ) : (
                <PerformanceTableDropdown
                  styles={styles}
                  options={JSON.parse(academicPeriodArr)}
                  value={academicPeriodId}
                  changeMode={changeMode}
                  name={'academic_period'}
                  id_name={'academic_period_name'}
                  modeslabel={'Academic Period'}
                  defaultOption={'Academic Period'}
                  selected_id={'academic_period_id'}
                  width={'245px'}
                />
              )}
            </div>
            <div className="d_w_m_y">
              <div className="ddd" style={states.isDay ? stylesss.dayAct : null} onClick={() => handleClick('d')}>
                <p>D</p>
              </div>
              <div className="mmm" style={states.isMonth ? stylesss.monthAct : null} onClick={() => handleClick('m')}>
                <p>M</p>
              </div>
              <div className="yyy" style={states.isYear ? stylesss.yearAct : null} onClick={() => handleClick('y')}>
                <p>Y</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className={styles.top}>
        <GridContainer>
          <div className={styles.calSec}>
            <div>
              <GridItem>
                <div className={styles.cal}>
                  <div className={styles.left}>
                    <div className={styles.options}>
                      <div className={styles.searchIcon}>
                        <img src={search_icon} alt="search" />
                      </div>
                      <AssessmentTableDropdown
                        name={'mode'}
                        changeMode={changeMode}
                        modeslabel={'mode'}
                        value={selectedValues.modeId}
                        options={states.modeOptions}
                        selectedId={'assessment_mode_id'}
                        displayName={'name'}
                        defaultName={'All Mode'}
                        styles={styles}
                      />
                      <AssessmentTableDropdown
                        name={'organizer'}
                        changeMode={changeMode}
                        modeslabel={'Organizer'}
                        value={selectedValues.organiserId}
                        options={states.organiserOptions}
                        selectedId={'organiser_type_id'}
                        displayName={'name'}
                        defaultName={'All Organizers'}
                        styles={styles}
                      />
                      <AssessmentTableDropdown
                        name={'type'}
                        changeMode={changeMode}
                        modeslabel={'Type'}
                        value={selectedValues.typeId}
                        options={states.typeOptions}
                        selectedId={'assessment_type_id'}
                        displayName={'name'}
                        defaultName={'Assessment Type'}
                        styles={styles}
                      />
                      <AssessmentTableDropdown
                        name={'studyCircle'}
                        changeMode={changeMode}
                        modeslabel={'Study Circle'}
                        value={selectedValues.studyCircleId}
                        options={states.studyOptions}
                        selectedId={'study_circle_id'}
                        displayName={'study_circle_name'}
                        defaultName={'All Study Circle'}
                        styles={styles}
                      />
                      <AssessmentTableDropdown
                        name={'subject'}
                        changeMode={changeMode}
                        modeslabel={'Subjects'}
                        value={selectedValues.subjectId}
                        options={states.subjectOptions}
                        selectedId={'subject_id'}
                        displayName={'subject'}
                        defaultName={'All Subjects'}
                        styles={styles}
                      />
                    </div>
                  </div>
                  <div className={styles.right}>
                    <div className={styles.sessionCal}>{calandarSection()}</div>
                  </div>
                </div>
              </GridItem>
            </div>
          </div>
          <AssessmentTable
            states={states}
            styles={styles}
            tableClick={tableClick}
            isLoading={isLoading}
            sortSession={sortSession}
            setStates={setStates}
          />
        </GridContainer>
      </div>
    </React.Fragment>
  );
};
export default Session;
