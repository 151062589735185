import sortFunction from '../../../components/sortFunction/sortFunction';

export const sortStudents = (value, key, data, setData) => {
  switch (key) {
    case 'RA#':
      return sortFunction(data, setData, 'StudentSummary_YEAR.studentId', value, true);
    case 'Name':
      return sortFunction(data, setData, 'StudentSummary_YEAR.studentName', value, false);
    case 'Study Circle':
      return sortFunction(data, setData, 'StudentSummary_YEAR.studyCircleName', value, false);
    case 'Score':
      return sortFunction(data, setData, 'StudentSummary_YEAR.avgStudentScore', value, true);
    case 'Read Velocity':
      return sortFunction(data, setData, 'StudentSummary_YEAR.avgStudentVelocity', value, true);
    case 'Hand Raise':
      return sortFunction(data, setData, 'StudentSummary_YEAR.handRaiseCount', value, true);
    default:
      return setData([...data]);
  }
};
