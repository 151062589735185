/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { GridContainer } from '../../components/grid/index';
import PerformanceTable from './Performance_Table/PerformanceTable';
import './index.css';
import styles from './PerformanceStyle.module.css';
import Datepicker from 'react-datepicker';
import ReactLoading from 'react-loading';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  getModes,
  getOrganiser,
  getStudyCircle,
  getSubjectStudy,
  getAssessmentType,
} from '../../api/masterReadAnalytics/dropdownAPI';
import { getPerformanceRowsAPI } from '../../api/cube/performanceAPI';

import { filterBuildPerformance } from './filterBuildPerformance';
import { fetchAcademicPeriod } from '../../utils/fetchAcademicPeriod';
import { whichTimeFrame } from '../../components/whichTImeFrame/whichTimeFrame';
import promiseAllCall from '../../utils/promise';
import PerformanceTableDropdown from '../../components/PerformanceTableDropdown/PerformanceTableDropdown';

var prevDate;
var nextDate;
var totaldaysInMonth;

const Performance = (props) => {
  const calendarDate =
    localStorage.getItem('oncalendardate') === null ? new Date() : new Date(localStorage.getItem('oncalendardate'));
  let month = calendarDate.getMonth();
  let today = calendarDate.getDate();
  let year = calendarDate.getFullYear();
  const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };
  totaldaysInMonth = getDaysInMonth(month + 1, year);
  let timeFrame = window.localStorage.getItem('timeFrame');
  let filterData = JSON.parse(localStorage.getItem('performanceTableFilterObj'));
  const institutionId = window.localStorage.getItem('cmn_school_id');
  const academicPeriodArr = window.localStorage.getItem('instAcademicPeriods');
  const sortedAcademicPerArr =
    academicPeriodArr?.length > 0 &&
    [...JSON.parse(academicPeriodArr)].sort(
      (a, b) => new Date(moment(a.start_date).format('YYYY-MM-DD')) - new Date(moment(b.start_date).format('YYYY-MM-DD')),
    );
  // console.log("sortedAcademicPerArr", sortedAcademicPerArr)
  // console.log("prevDate, nextDate, totalDaysInMonth", { prevDate, nextDate, totaldaysInMonth })
  const academicStartDate = sortedAcademicPerArr?.length > 0 && new Date(sortedAcademicPerArr[0].start_date);
  const academicEndDate =
    sortedAcademicPerArr?.length > 0 && new Date(sortedAcademicPerArr[sortedAcademicPerArr.length - 1].end_date);
  // console.log("date onChange", { startDate: moment(academicStartDate).format("DD-MM-YYYY"), endDate: moment(academicEndDate).format("DD-MM-YYYY") })
  const academic_period = JSON.parse(localStorage.getItem('academic_period'));

  if (timeFrame) {
    if (timeFrame === 'month') {
      prevDate = `${year}-${month + 1}-01`;
      nextDate = year + '-' + (month + 1) + '-' + totaldaysInMonth;
    }
    if (timeFrame === 'day') {
      prevDate = year + '-' + (month + 1) + '-' + today;
      nextDate = year + '-' + (month + 1) + '-' + today;
    }
    if (timeFrame === 'year') {
      prevDate = year + '-' + 1 + '-' + 1;
      nextDate = year + '-' + 12 + '-' + 31;
    }
  } else {
    prevDate = `${year}-${month + 1}-01`;
    nextDate = year + '-' + (month + 1) + '-' + totaldaysInMonth;
  }

  const [filterPrevDate, setFilterPrevDate] = useState(moment(prevDate).format('YYYY-MM-DD'));
  const [filterNextDate, setFilterNextDate] = useState(moment(nextDate).format('YYYY-MM-DD'));
  // const [filterPrevDate, setFilterPrevDate] = useState(moment(academic_period.start_date).format('YYYY-MM-DD'));
  // const [filterNextDate, setFilterNextDate] = useState(moment(academic_period.end_date).format('YYYY-MM-DD'));
  const [performanceTableFilterObj, setPerformanceTableFilterObj] = useState({});
  const [states, setStates] = useState({
    isMonth: timeFrame ? (timeFrame == 'month' ? true : false) : true,
    isDay: timeFrame == 'day' ? true : false,
    isYear: timeFrame == 'year' ? true : false,
    t_body: [],
    modeOptions: [],
    organiserOptions: [],
    typeOptions: [],
    teacherOptions: [],
    studyCircleOptions: [],
    subjectOptions: [],
  });
  const [selectedValues, setSelectedValues] = useState({
    modeId: performanceTableFilterObj?.modeId ? performanceTableFilterObj?.modeId : -1,
    organiserId: performanceTableFilterObj?.organiserId ? performanceTableFilterObj?.organiserId : -1,
    typeId: performanceTableFilterObj?.typeId ? performanceTableFilterObj?.typeId : -1,
    studyCircleId: performanceTableFilterObj?.studyCircleId ? performanceTableFilterObj?.studyCircleId : -1,
    teacherId: performanceTableFilterObj?.teacherId ? performanceTableFilterObj?.teacherId : -1,
    subjectId: performanceTableFilterObj?.subjectId ? performanceTableFilterObj?.subjectId : -1,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(0);
  const [startdate, setStartDate] = useState(calendarDate);
  const [academicPeriodId, setAcademicPeriodId] = useState(-1);
  const stylesss = {
    monthAct: {
      backgroundColor: '#FD8500',
      color: '#fff',
      fontWeight: 500,
    },
    dayAct: {
      backgroundColor: '#FD8500',
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
      color: '#fff',
      fontWeight: 500,
    },
    yearAct: {
      backgroundColor: '#FD8500',
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
      color: '#fff',
      fontWeight: 500,
    },
  };
  let filter1;
  const academicPeriodsList = JSON.parse(localStorage.getItem('instAcademicPeriods'));

  useEffect(() => {
    const fetchDropdowndata = async () => {
      try {
        const callAll = await promiseAllCall([getModes(), getOrganiser(), getAssessmentType(), getStudyCircle(institutionId)]);
        // console.log('callAll', callAll);
        setStates((prevState) => {
          return {
            ...prevState,
            modeOptions: callAll[0]?.data?.data,
            organiserOptions: callAll[1]?.data?.data,
            typeOptions: callAll[2]?.data?.data,
            studyCircleOptions: callAll[3]?.data?.data,
          };
        });
        // setIsLoading(false);
      } catch (err) {
        console.log('err in fetchDropdowndata', err);
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        }
        // setIsLoading(false);
      }
    };
    fetchDropdowndata();
  }, []);

  useEffect(() => {
    states.isYear && fetchAcademicInfo();
  }, [states.isYear]);

  useEffect(() => {
    const fetchDropdownDependencies = async () => {
      try {
        const subjects = await getSubjectStudy(institutionId, selectedValues.studyCircleId);
        setStates((prevState) => {
          return { ...prevState, subjectOptions: subjects?.data?.data };
        });
        // setIsLoading(false);
      } catch (err) {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        }
        // setIsLoading(false);
      }
    };
    // setIsLoading(!isLoading);
    fetchDropdownDependencies();
  }, [selectedValues.studyCircleId]);

  useEffect(() => {
    const fetchCubeData = async () => {
      filter1 = filterBuildPerformance(
        institutionId,
        selectedValues.modeId,
        selectedValues.typeId,
        selectedValues.organiserId,
        selectedValues.studyCircleId,
        selectedValues.subjectId,
        states,
        academicPeriodId,
      );
      console.log('Inside UseEffect', { filterPrevDate, filterNextDate });
      try {
        const callAll = await promiseAllCall([getPerformanceRowsAPI(filter1, filterPrevDate, filterNextDate, states)]);
        let result = await callAll[0];
        result = [...result.loadResponses[0]?.data];
        // console.log('result i performance', result);
        setStates((prevState) => {
          return { ...prevState, t_body: result };
        });
        setIsLoading(false);
      } catch (err) {
        console.log('err in fetchCubeData', err);
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        }
        setIsLoading(false);
      }
    };
    setIsLoading(true);
    fetchCubeData();
  }, [
    selectedValues.modeId,
    selectedValues.organiserId,
    selectedValues.studyCircleId,
    selectedValues.subjectId,
    selectedValues.typeId,
    filterPrevDate,
    filterNextDate,
    academicPeriodId,
  ]);

  const fetchAcademicInfo = async () => {
    const boardId = JSON.parse(localStorage.getItem('board_info'))?.board_id;
    const result = await fetchAcademicPeriod(moment(startdate).format('YYYY-MM-DD'), boardId);
    localStorage.setItem('academic_period', JSON.stringify(result));
    setAcademicPeriodId(result?.academic_period_id);
    setFilterPrevDate(moment(result?.start_date).format('YYYY-MM-DD'));
    setFilterNextDate(moment(result?.end_date).format('YYYY-MM-DD'));
    return result;
  };

  const handleChange = async (date, arrowdate) => {
    // console.log("handleChange", { date, arrowdate })
    setIsLoading(true);
    if (arrowdate === 'prev') {
      let currentdate = new Date(date);
      let previousday = new Date(currentdate.getTime() - 24 * 60 * 60 * 1000);
      let today = states.isDay ? previousday.getDate() : currentdate.getDate();
      let month = states.isMonth ? (currentdate.getMonth() === 0 ? 11 : currentdate.getMonth() - 1) : previousday.getMonth();
      let year = states.isYear
        ? currentdate.getFullYear() - 1
        : currentdate.getMonth() === 0
          ? currentdate.getFullYear() - 1
          : previousday.getFullYear();

      const checkMinDate =
        new Date(moment(academicStartDate).format('YYYY-MM-DD')) <=
        new Date(moment(new Date(year, month, today)).format('YYYY-MM-DD'));
      const checkMaxDate =
        new Date(moment(academicEndDate).format('YYYY-MM-DD')) >=
        new Date(moment(new Date(year, month, today)).format('YYYY-MM-DD'));
      // console.log("PREV date onChange comparisions", { checkMinDate, checkMaxDate })

      if (checkMinDate && checkMaxDate) {
        setStartDate(new Date(year, month, today));
        whichTimeFrame(year, month, today, states);
        prevDate = states.isDay
          ? `${year}-${month + 1}-${today}`
          : states.isMonth
            ? `${year}-${month + 1}-01`
            : states.isYear
              ? `${moment(academic_period.start_date).format('YYYY-MM-DD')}`
              : null;

        nextDate = states.isDay
          ? `${year}-${month + 1}-${today}`
          : states.isMonth
            ? year + '-' + (month + 1) + '-' + totaldaysInMonth
            : states.isYear
              ? `${moment(academic_period.end_date).format('YYYY-MM-DD')}`
              : null;
        // console.log("date", { prevDate, nextDate })
        setFilterPrevDate(moment(prevDate).format('YYYY-MM-DD'));
        setFilterNextDate(moment(nextDate).format('YYYY-MM-DD'));
      } else {
        toast.info(
          `Please select the dates between ${moment(academicStartDate).format('MMMM Do YYYY')} TO ${moment(academicEndDate).format('MMMM Do YYYY')} for accessing the data`,
        );
      }
    } else if (arrowdate === 'next') {
      let currentdate = new Date(date);
      let previousday = new Date(currentdate.getTime() + 24 * 60 * 60 * 1000);
      let today = states.isDay ? previousday.getDate() : currentdate.getDate();
      let month = states.isMonth ? (currentdate.getMonth() === 11 ? 0 : currentdate.getMonth() + 1) : previousday.getMonth();
      let year = states.isYear
        ? currentdate.getFullYear() + 1
        : currentdate.getMonth() === 11
          ? currentdate.getFullYear() + 1
          : previousday.getFullYear();

      const checkMinDate =
        new Date(moment(academicStartDate).format('YYYY-MM-DD')) <=
        new Date(moment(new Date(year, month, today)).format('YYYY-MM-DD'));
      const checkMaxDate =
        new Date(moment(academicEndDate).format('YYYY-MM-DD')) >=
        new Date(moment(new Date(year, month, today)).format('YYYY-MM-DD'));

      if (checkMinDate && checkMaxDate) {
        setStartDate(new Date(year, month, today));
        whichTimeFrame(year, month, today, states);

        prevDate = states.isDay
          ? `${year}-${month + 1}-${today}`
          : states.isMonth
            ? `${year}-${month + 1}-01`
            : states.isYear
              ? // ? `${year}-12-31`
                `${moment(academic_period.start_date).format('YYYY-MM-DD')}`
              : null;

        nextDate = states.isDay
          ? `${year}-${month + 1}-${today}`
          : states.isMonth
            ? year + '-' + (month + 1) + '-' + totaldaysInMonth
            : states.isYear
              ? // ? `${year}-12-31`
                `${moment(academic_period.end_date).format('YYYY-MM-DD')}`
              : null;

        setFilterPrevDate(moment(prevDate).format('YYYY-MM-DD'));
        setFilterNextDate(moment(nextDate).format('YYYY-MM-DD'));
      } else {
        toast.info(
          `Please select the dates between ${moment(academicStartDate).format('MMMM Do YYYY')} TO ${moment(academicEndDate).format('MMMM Do YYYY')} for accessing the data`,
        );
      }
    } else if (arrowdate === 'calander') {
      const checkMinDate = new Date(moment(academicStartDate).format('YYYY-MM-DD')) <= new Date(moment(date).format('YYYY-MM-DD'));
      const checkMaxDate = new Date(moment(academicEndDate).format('YYYY-MM-DD')) >= new Date(moment(date).format('YYYY-MM-DD'));
      // console.log("calander date onChange comparisions", { checkMinDate, checkMaxDate })

      if (checkMinDate && checkMaxDate) {
        setStartDate(date);
        let date_val = new Date(date);
        localStorage.setItem('oncalendardate', date_val);
        month = date_val.getMonth();
        today = date_val.getDate();
        year = date_val.getFullYear();

        prevDate = states.isDay
          ? `${year}-${month + 1}-${today}`
          : states.isMonth
            ? `${year}-${month + 1}-01`
            : states.isYear
              ? // ? `${year}-12-31`
                `${moment(academic_period.start_date).format('YYYY-MM-DD')}`
              : null;

        nextDate = states.isDay
          ? `${year}-${month + 1}-${today}`
          : states.isMonth
            ? year + '-' + (month + 1) + '-' + totaldaysInMonth
            : states.isYear
              ? // ? `${year}-12-31`
                `${moment(academic_period.end_date).format('YYYY-MM-DD')}`
              : null;
      } else {
        toast.info(
          `Please select the dates between ${moment(academicStartDate).format('MMMM Do YYYY')} TO ${moment(academicEndDate).format('MMMM Do YYYY')} for accessing the data`,
        );
      }
    }
    setDate(date + 1);
  };

  const handleClick = async (values) => {
    setIsLoading(true);
    if (values === 'd') {
      prevDate = `${year}-${month + 1}-${today}`;
      nextDate = year + '-' + (month + 1) + '-' + today;

      setFilterPrevDate(moment(prevDate).format('YYYY-MM-DD'));
      setFilterNextDate(moment(nextDate).format('YYYY-MM-DD'));
    } else if (values === 'm') {
      prevDate = `${year}-${month + 1}-01`;
      nextDate = year + '-' + (month + 1) + '-' + totaldaysInMonth;

      setFilterPrevDate(moment(prevDate).format('YYYY-MM-DD'));
      setFilterNextDate(moment(nextDate).format('YYYY-MM-DD'));
    } else if (values === 'y') {
      prevDate = `${year}-01-01`;
      nextDate = `${year}-12-31`;

      // prevDate = moment(academic_period.start_date).format('YYYY-MM-DD');
      // nextDate = moment(academic_period.end_date).format('YYYY-MM-DD');
    }
    setStates({
      ...states,
      isDay: values === 'd' ? true : false,
      isMonth: values === 'm' ? true : false,
      isYear: values === 'y' ? true : false,
    });
    whichTimeFrame(year, month, today, {
      isDay: values === 'd' ? true : false,
      isMonth: values === 'm' ? true : false,
      isYear: values === 'y' ? true : false,
    });
    setDate(date + 1);
    // changeMode("e", "date");
  };

  const calandarSection = () => {
    return (
      <div className="calanderContainer">
        <div className="calander">
          <div className="calCont">
            <div className="dateDate">
              {!states?.isYear ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}>
                  <img
                    src={require('../../assets/images/prev-arrow.png')}
                    alt="prev"
                    onClick={(e) => handleChange(startdate, 'prev')}
                    style={{ marginLeft: '40px' }}
                  />
                  <Datepicker
                    id={'fromdate'}
                    selected={startdate}
                    onChange={(e) => handleChange(e, 'calander')}
                    dateFormat={states.isDay ? 'd MMM yyyy' : states.isMonth ? 'MMM yyyy' : states.isYear ? 'yyyy' : null}
                    className={styles.input}
                    readOnly={false}
                    showMonthYearPicker={states.isMonth ? true : null}
                    showYearDropdown={states.isYear ? true : null}
                    // show={()=>test()}
                  />
                  <img
                    src={require('../../assets/images/next-arrow.png')}
                    alt="next"
                    onClick={(e) => handleChange(startdate, 'next')}
                  />
                  <label htmlFor="fromdate">
                    <img
                      src={require('../../assets/images/calender-icon.png')}
                      alt="cal"
                      // onChange={(e) => handleChange(e, "calander")}
                      style={{ margin: '0px 30px', cursor: 'pointer' }}
                    />
                  </label>
                </div>
              ) : (
                <PerformanceTableDropdown
                  styles={styles}
                  options={academicPeriodsList}
                  value={academicPeriodId}
                  changeMode={changeMode}
                  name={'academic_period'}
                  id_name={'academic_period_name'}
                  modeslabel={'Academic Period'}
                  defaultOption={'Academic Period'}
                  selected_id={'academic_period_id'}
                  width={'245px'}
                />
              )}
            </div>
            <div className="d_w_m_y">
              <div className="ddd" style={states.isDay ? stylesss.dayAct : null} onClick={() => handleClick('d')}>
                <p>D</p>
              </div>
              <div className="mmm" style={states.isMonth ? stylesss.monthAct : null} onClick={() => handleClick('m')}>
                <p>M</p>
              </div>
              <div className="yyy" style={states.isYear ? stylesss.yearAct : null} onClick={() => handleClick('y')}>
                <p>Y</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const tableClick = (selectedRow) => {
    console.log('selectedRow', selectedRow);
    if (Object.keys(performanceTableFilterObj)?.length) {
      localStorage.setItem('performanceTableFilterObj', JSON.stringify(performanceTableFilterObj));
    }
    window.localStorage.setItem(
      'performanceData',
      JSON.stringify({
        studyCircleId: selectedRow['StudyCircle.id'],
        studyCircleName: states.isDay
          ? selectedRow['GroupPerformanceRank_Day.studyCircleName']
          : states.isMonth
            ? selectedRow['GroupPerformanceRank_Month.studyCircleName']
            : states.isYear
              ? selectedRow['GroupPerformanceRank_Year.studyCircleName']
              : null,
        assessmentModeId: states.isDay
          ? selectedRow['GroupPerformanceRank_Day.assessmentModeId']
          : states.isMonth
            ? selectedRow['GroupPerformanceRank_Month.assessmentModeId']
            : states.isYear
              ? selectedRow['GroupPerformanceRank_Year.assessmentModeId']
              : null,
        assessmentTypeId: states.isDay
          ? selectedRow['GroupPerformanceRank_Day.assessmentTypeId']
          : states.isMonth
            ? selectedRow['GroupPerformanceRank_Month.assessmentTypeId']
            : states.isYear
              ? selectedRow['GroupPerformanceRank_Year.assessmentTypeId']
              : null,
        subjectId: states.isDay
          ? selectedRow['GroupPerformanceRank_Day.subjectId']
          : states.isMonth
            ? selectedRow['GroupPerformanceRank_Month.subjectId']
            : states.isYear
              ? selectedRow['GroupPerformanceRank_Year.subjectId']
              : null,
        prevDate: prevDate,
        nextDate: nextDate,
        subjectName: states.isDay
          ? selectedRow['GroupPerformanceRank_Day.subjectName']
          : states.isMonth
            ? selectedRow['GroupPerformanceRank_Month.subjectName']
            : states.isYear
              ? selectedRow['GroupPerformanceRank_Year.subjectName']
              : null,
        calendarSel: states.isDay ? 'd' : states.isMonth ? 'm' : states.isYear ? 'y' : 'm',
      }),
    );
    const selectedStudyCirclId = states.isDay
      ? selectedRow['GroupPerformanceRank_Month.studyCircleId']
      : states.isMonth
        ? selectedRow['GroupPerformanceRank_Month.studyCircleId']
        : states.isYear
          ? selectedRow['GroupPerformanceRank_Year.studyCircleId']
          : null;

    return selectedStudyCirclId
      ? (window.location.href = '/admin/performance/' + selectedStudyCirclId)
      : toast.info('Something is wrong with the clicked performance row! Try again after sometime');
  };

  // console.log("filterData", filterData)
  const changeMode = async (e, name) => {
    if (name === 'mode') {
      setSelectedValues((prevState) => {
        return { ...prevState, modeId: e.target.value };
      });
      setPerformanceTableFilterObj((prevState) => {
        return { ...prevState, modeId: e.target.value };
      });
    } else if (name === 'organise') {
      setSelectedValues((prevState) => {
        return { ...prevState, organiserId: e.target.value };
      });
      setPerformanceTableFilterObj((prevState) => {
        return { ...prevState, organiserId: e.target.value };
      });
    } else if (name === 'type') {
      setSelectedValues((prevState) => {
        return { ...prevState, typeId: e.target.value };
      });
      setPerformanceTableFilterObj((prevState) => {
        return { ...prevState, typeId: e.target.value };
      });
    } else if (name === 'studyCircle') {
      setSelectedValues((prevState) => {
        return { ...prevState, studyCircleId: e.target.value };
      });
      setPerformanceTableFilterObj((prevState) => {
        return { ...prevState, studyCircleId: e.target.value };
      });
    } else if (name === 'subject') {
      setSelectedValues((prevState) => {
        return { ...prevState, subjectId: e.target.value };
      });
      setPerformanceTableFilterObj((prevState) => {
        return { ...prevState, subjectId: e.target.value };
      });
    } else if (name === 'academic_period') {
      setAcademicPeriodId(e.target.value);
    } else return null;
  };

  return (
    <>
      {isLoading ? (
        <div className={styles.loading_animation}>
          <ReactLoading
            type="bars"
            color="#FC8802"
            height="10%"
            width="10%"
            className={styles.reactLoadingBars}
            // className="startLoadSpin"
          />
        </div>
      ) : null}
      <GridContainer>
        <div className={styles.top_div}>
          <div className={styles.top_box}>
            <div className={styles.calDiv}>
              <div className={styles.left}>
                <div className={styles.options}>
                  <PerformanceTableDropdown
                    styles={styles}
                    options={states.modeOptions}
                    value={selectedValues.modeId}
                    changeMode={changeMode}
                    name={'mode'}
                    id_name={'name'}
                    modeslabel={'Mode'}
                    defaultOption={'All Mode'}
                    selected_id={'assessment_mode_id'}
                  />
                  <PerformanceTableDropdown
                    styles={styles}
                    options={states.organiserOptions}
                    value={selectedValues.organiserId}
                    changeMode={changeMode}
                    name={'organise'}
                    id_name={'name'}
                    modeslabel={'Organiser'}
                    defaultOption={'Organiser'}
                    selected_id={'organiser_type_id'}
                  />
                  <PerformanceTableDropdown
                    styles={styles}
                    options={states.typeOptions}
                    value={selectedValues.typeId}
                    changeMode={changeMode}
                    name={'type'}
                    id_name={'name'}
                    modeslabel={'Type'}
                    defaultOption={'All Type'}
                    selected_id={'assessment_type_id'}
                  />
                  <PerformanceTableDropdown
                    styles={styles}
                    options={states.studyCircleOptions}
                    value={selectedValues.studyCircleId}
                    changeMode={changeMode}
                    name={'studyCircle'}
                    id_name={'study_circle_name'}
                    modeslabel={'Study circle'}
                    defaultOption={'All Study Circle'}
                    selected_id={'study_circle_id'}
                  />
                  <PerformanceTableDropdown
                    styles={styles}
                    options={states.subjectOptions}
                    value={selectedValues.subjectId}
                    changeMode={changeMode}
                    name={'subject'}
                    id_name={'subject'}
                    modeslabel={'Subject'}
                    defaultOption={'All Subjects'}
                    selected_id={'subject_id'}
                  />
                </div>
              </div>
              <div style={{ marginTop: '8px' }}>{calandarSection()}</div>
            </div>
          </div>
        </div>
        <PerformanceTable PerformanceData={states.t_body} tableClick={tableClick} states={states} />
      </GridContainer>
      {/* <div style={{paddingTop:'10px'}}><PerformanceSubjectDetail/></div> */}
    </>
  );
};

export default Performance;
