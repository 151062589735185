import { getQuestionBarAPI } from '../../../../api/cube/sessionAPI';

export async function getQuestionBar(assessmentId, institutionId, startDate, endDate) {
  const result = await getQuestionBarAPI(assessmentId, institutionId, startDate, endDate);
  // console.log(result.loadResponse.results[0].data);
  const QuestionBarArray = result.loadResponse.results[0].data;
  const tempQuestionBarArray = QuestionBarArray.map(function (que) {
    return {
      questionId: que['AssessmentResponse.questionId'],
      data: [
        que['AssessmentResponse.totalNearCorrects'],
        que['AssessmentResponse.totalCorrects'],
        que['AssessmentResponse.totalIncorrects'],
        que['AssessmentResponse.totalNotAnswereds'],
      ],
    };
  });
  // tempQuestionBarArray.sort(
  //   (a, b) =>
  //     a["questionId"] - b["questionId"]
  // );
  // console.log(tempQuestionBarArray);
  return tempQuestionBarArray;
}
