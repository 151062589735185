import React from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calanderIcon from '../../assets/images/calender-icon.png';
import nextArrow from '../../assets/images/next-arrow.png';
import prevArrow from '../../assets/images/prev-arrow.png';
import PerformanceTableDropdown from '../PerformanceTableDropdown/PerformanceTableDropdown';

const PerformanceCalender = (props) => {
  const { handleChange, handleClick, startdate, states, stylesss, styles, academicPeriodsList, academicPeriodId, changeMode } =
    props;

  return (
    <div className="calanderContainer">
      <div className="calander">
        <div className="calCont">
          <div className="dateDate">
            {!states?.isYear ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <img src={prevArrow} alt="prev" onClick={(e) => handleChange(startdate, 'prev')} style={{ marginLeft: '40px' }} />
                <ReactDatePicker
                  id={'fromdate'}
                  selected={startdate}
                  onChange={(e) => handleChange(e, 'calander')}
                  dateFormat={states.isDay ? 'd MMM yyyy' : states.isMonth ? 'MMM yyyy' : states.isYear ? 'yyyy' : null}
                  className={styles.input}
                  readOnly={false}
                  showMonthYearPicker={states.isMonth ? true : null}
                  showYearDropdown={states.isYear ? true : null}
                  // show={()=>test()}
                />
                <img src={nextArrow} alt="next" onClick={(e) => handleChange(startdate, 'next')} />
                <label htmlFor="fromdate">
                  <img
                    // htmlFor="fromdate"
                    src={calanderIcon}
                    alt="cal"
                    // onChange={(e) => handleChange(e, "calander")}
                    style={{ margin: '0px 30px', cursor: 'pointer' }}
                  />
                </label>
              </div>
            ) : (
              <PerformanceTableDropdown
                styles={styles}
                options={academicPeriodsList}
                value={academicPeriodId}
                changeMode={changeMode}
                name={'academic_period'}
                id_name={'academic_period_name'}
                modeslabel={'Academic Period'}
                defaultOption={'Academic Period'}
                selected_id={'academic_period_id'}
                width={'245px'}
              />
            )}
          </div>
          <div className="d_w_m_y">
            <div className="ddd" style={states.isDay ? stylesss.dayAct : null} onClick={() => handleClick('d')}>
              <p>D</p>
            </div>
            <div className="mmm" style={states.isMonth ? stylesss.monthAct : null} onClick={() => handleClick('m')}>
              <p>M</p>
            </div>
            <div className="yyy" style={states.isYear ? stylesss.yearAct : null} onClick={() => handleClick('y')}>
              <p>Y</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceCalender;
