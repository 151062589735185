import React from 'react';

const StudentTableDropdown = (props) => {
  const { styled, defaultName, name, changeMode, selected_id, displayValue, value, modeslabel, options, disabled } = props;
  return (
    <span className={styled.selectStudent}>
      <div className={styled.modeslabel}>{modeslabel}</div>
      <select name={name} id={name} onChange={(e) => changeMode(e, name)} value={value} disabled={disabled}>
        <option value="-1">{defaultName}</option>
        {options?.map((el, key) => (
          <option value={el[selected_id]} key={key}>
            {el[displayValue]}
          </option>
        ))}
      </select>
    </span>
  );
};

export default StudentTableDropdown;
