import React from 'react';

const AssessmentTableDropdown = (props) => {
  const { styles, changeMode, options, modeslabel, name, selectedId, displayName, value, defaultName } = props;

  return (
    <span className="sessionselect1">
      <div className={styles.modeslabel}>{modeslabel}</div>
      <select name={name} id={name} onChange={(e) => changeMode(e, name)} value={value}>
        <option value="-1">{defaultName}</option>
        {options?.map((el, key) => (
          <option value={el[selectedId]} key={key}>
            {el[displayName]}
          </option>
        ))}
      </select>
    </span>
  );
};

export default AssessmentTableDropdown;
