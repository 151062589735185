/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { GridContainer } from '../../components/grid/index';
import './students.css';
import styled from './StudentStyle.module.css';
import { fetchStudentData } from '../../api/cube/studentAPI';
import {
  getAssessmentType,
  getModes,
  getOrganiser,
  getStudyCircle,
  // getSubject,
  getSubSubject,
  getTopic,
  getSubTopic,
  getObjective,
  getSubjectStudy,
} from '../../api/masterReadAnalytics/dropdownAPI';
import moment from 'moment';
import { toast } from 'react-toastify';
import { filterBuildStudent } from './filterBuildStudents';
import StudentTable from './StudentTable/StudentTable';
import LoadingBars from '../../components/LoadingBars/LoadingBars';
import promiseAllCall from '../../utils/promise';
import StudentTableDropdown from '../../components/StudentTableDropdown/StudentTableDropdown';

const Students = (props) => {
  // const { updateDisplayText } = props;

  let filter = [];
  // let individualStudentDetail = false;
  const [dropdownVals, setDropdownVals] = useState({
    selectedMode: '-1',
    selectedAssessmentType: '-1',
    selectedOrganiser: '-1',
    selectedStudy: '-1',
    selectedTopic: '-1',
    selectedSubTopic: '-1',
    selectedSubject: '-1',
    selectedSubSubject: '-1',
    selectedObjectives: '-1',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [states, setStates] = useState({
    isMonth: true,
    isDay: false,
    isYear: false,
    t_head: [
      'RA#',
      'Name',
      'Study Circle',
      'Questions',
      'Score',
      'Read Velocity',
      'Study Circle Rank',
      'Group percentile',
      'Trend',
      'Hand Raise',
    ],
    modeOptions: [],
    assessmentOptions: [],
    organiserOptions: [],
    studyOptions: [],
    topicOptions: [],
    subTopicOptions: [],
    subjectOptions: [],
    subSubjectOptions: [],
    objectiveOptions: [],
  });
  const [data, setData] = useState([]);
  const initialRender = useRef(false);
  const institutionId = window.localStorage.getItem('cmn_school_id');

  const academicPeriod = JSON.parse(localStorage.getItem('academic_period'));
  // console.log("academicPeriod in Students", academicPeriod)
  const academicStartDate =
    academicPeriod && Object.keys(academicPeriod)?.length > 0 ? moment(academicPeriod.start_date).format('YYYY-MM-DD') : null;
  const academicEndDate =
    academicPeriod && Object.keys(academicPeriod)?.length > 0 ? moment(academicPeriod.end_date).format('YYYY-MM-DD') : null;

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const callAll = await promiseAllCall([
          getModes(),
          getOrganiser(),
          getAssessmentType(),
          getStudyCircle(institutionId),
          getObjective(),
        ]);
        // console.log("callAll", callAll);
        setStates((prevState) => {
          return {
            ...prevState,
            modeOptions: callAll[0]?.data?.data,
            organiserOptions: callAll[1]?.data?.data,
            assessmentOptions: callAll[2]?.data?.data,
            studyOptions: callAll[3]?.data?.data,
            objectiveOptions: callAll[4]?.data?.data,
          };
        });
      } catch (err) {
        console.log('err in fetchInitialData for Students table', err);
      }
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    const fetchDropdowndependencies = async () => {
      try {
        const subjects = await getSubjectStudy(institutionId, dropdownVals.selectedStudy);
        const subSubjects = await getSubSubject(institutionId, dropdownVals.selectedSubject);
        const topics = await getTopic(institutionId, dropdownVals.selectedSubject, dropdownVals.selectedSubSubject);
        const subTopics = await getSubTopic(
          institutionId,
          dropdownVals.selectedSubject,
          dropdownVals.selectedSubSubject,
          dropdownVals.selectedTopic,
        );
        setStates((prevState) => {
          return {
            ...prevState,
            subjectOptions: subjects?.data?.data,
            subSubjectOptions: subSubjects?.data?.data,
            topicOptions: topics?.data?.data,
            subTopicOptions: subTopics?.data?.data,
          };
        });
      } catch (err) {
        console.log('err in fetchDropdowndependencies', err);
        handleError(err);
      }
    };
    if (initialRender.current) {
      fetchDropdowndependencies();
    } else {
      initialRender.current = true;
    }
  }, [
    dropdownVals.selectedStudy,
    dropdownVals.selectedSubject,
    dropdownVals.selectedSubSubject,
    dropdownVals.selectedTopic,
    dropdownVals.selectedSubTopic,
  ]);

  useEffect(() => {
    const fetchStudentTable = async () => {
      filter = filterBuildStudent(
        institutionId,
        dropdownVals.selectedMode,
        dropdownVals.selectedAssessmentType,
        dropdownVals.selectedOrganiser,
        dropdownVals.selectedSubSubject,
        dropdownVals.selectedTopic,
        dropdownVals.selectedStudy,
        dropdownVals.selectedObjectives,
      );
      const callTableAPI = await promiseAllCall([
        fetchStudentData(academicPeriod.academic_period_id, institutionId, dropdownVals.selectedStudy),
      ]);
      let tableData = await callTableAPI[0];
      let data = tableData?.loadResponses[0]?.data;
      console.log('tableData from fetchStudentData ', data);
      setData(data);
      setIsLoading(false);
      return;
    };
    try {
      setIsLoading(true);
      fetchStudentTable();
    } catch (err) {
      console.log('error in fetching student table data', err);
      toast.error('Error in fetching student table data');
      setIsLoading(false);
    }
  }, [
    dropdownVals.selectedMode,
    dropdownVals.selectedAssessmentType,
    dropdownVals.selectedOrganiser,
    dropdownVals.selectedTopic,
    dropdownVals.selectedStudy,
    dropdownVals.selectedObjectives,
    dropdownVals.selectedSubSubject,
  ]);

  // Error handler
  function handleError(error) {
    // console.log("error", error);
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    }
  }

  // Handles dropdown change
  const changeMode = async (e, name) => {
    console.log('e', e.target.value, name);
    if (name === 'mode') {
      setDropdownVals((prevState) => {
        return { ...prevState, selectedMode: e.target.value };
      });
    } else if (name === 'organiser') {
      setDropdownVals((prevState) => {
        return { ...prevState, selectedOrganiser: e.target.value };
      });
    } else if (name === 'assessmentType') {
      setDropdownVals((prevState) => {
        return { ...prevState, selectedAssessmentType: e.target.value };
      });
    } else if (name === 'studyCircle') {
      setDropdownVals((prevState) => {
        return { ...prevState, selectedStudy: e.target.value };
      });
    } else if (name === 'subject') {
      setDropdownVals((prevState) => {
        return { ...prevState, selectedSubject: e.target.value };
      });
    } else if (name === 'subSubject') {
      setDropdownVals((prevState) => {
        return { ...prevState, selectedSubSubject: e.target.value };
      });
    } else if (name === 'topic') {
      setDropdownVals((prevState) => {
        return { ...prevState, selectedTopic: e.target.value };
      });
    } else if (name === 'subTopic') {
      setDropdownVals((prevState) => {
        return { ...prevState, selectedSubTopic: e.target.value };
      });
    } else if (name === 'objective') {
      setDropdownVals((prevState) => {
        return { ...prevState, selectedObjectives: e.target.value };
      });
    }
  };

  // Onclick of student table row
  const tableClick = (rowData) => {
    console.log('rowData', rowData);
    window.localStorage.setItem(
      'studentData',
      JSON.stringify({
        studyCircleId: +rowData['StudentSummary_YEAR.studyCircleId'],
        studyCircleName: rowData['StudentSummary_YEAR.studyCircleName'],
        rollNumber: rowData['StudentSummary_YEAR.studentId'],
        studentName: rowData['StudentSummary_YEAR.studentName'],
        assessmentModeId: dropdownVals.selectedMode,
        assessmentTypeId: dropdownVals.selectedAssessmentType,
        organiserId: dropdownVals.selectedOrganiser,
        subjectId: dropdownVals.selectedSubject,
        subSubjectId: dropdownVals.selectedSubSubject,
        topicId: dropdownVals.selectedTopic,
        subTopicId: dropdownVals.selectedSubTopic,
        objectiveId: dropdownVals.selectedObjectives,
        studentProfilePic: rowData['StudentSummary_YEAR.profilePicUrl'],
      }),
    );
    return (window.location.href = '/admin/students/' + rowData['StudentSummary_YEAR.studentId']);
  };

  return (
    <>
      {isLoading ? <LoadingBars /> : null}
      <GridContainer>
        <div className={styled.main_box}>
          <div className={styled.top_box}>
            <div className={styled.select_box}>
              <div className={styled.selectBox}>
                {/* <div className={styled.searchIcon}><img src={search_icon} alt="search" /></div> */}
                <StudentTableDropdown
                  modeslabel={'Mode'}
                  name={'mode'}
                  changeMode={changeMode}
                  value={dropdownVals.selectedMode}
                  selected_id={'assessment_mode_id'}
                  displayValue={'name'}
                  defaultName={'All Mode'}
                  options={states.modeOptions}
                  styled={styled}
                  disabled={true}
                />
                <StudentTableDropdown
                  modeslabel={'Organiser'}
                  name={'organiser'}
                  changeMode={changeMode}
                  value={dropdownVals.selectedOrganiser}
                  selected_id={'organiser_type_id'}
                  displayValue={'name'}
                  defaultName={'All Organiser'}
                  options={states.organiserOptions}
                  styled={styled}
                  disabled={true}
                />
                <StudentTableDropdown
                  modeslabel={'Assessment Type'}
                  name={'assessmentType'}
                  changeMode={changeMode}
                  value={dropdownVals.selectedAssessmentType}
                  selected_id={'assessment_type_id'}
                  displayValue={'name'}
                  defaultName={'All Assessment'}
                  options={states.assessmentOptions}
                  styled={styled}
                  disabled={true}
                />
                <StudentTableDropdown
                  modeslabel={'Study Circle'}
                  name={'studyCircle'}
                  changeMode={changeMode}
                  value={dropdownVals.selectedStudy}
                  selected_id={'study_circle_id'}
                  displayValue={'study_circle_name'}
                  defaultName={'All Study Circle'}
                  options={states.studyOptions}
                  styled={styled}
                />
                <StudentTableDropdown
                  modeslabel={'Subject'}
                  name={'subject'}
                  changeMode={changeMode}
                  value={dropdownVals.selectedSubject}
                  selected_id={'subject_id'}
                  displayValue={'subject'}
                  defaultName={'All Subjects'}
                  options={states.subjectOptions}
                  styled={styled}
                  disabled={true}
                />
                <StudentTableDropdown
                  modeslabel={'Sub Subject'}
                  name={'subSubject'}
                  changeMode={changeMode}
                  value={dropdownVals.selectedSubSubject}
                  selected_id={'sub_subject_id'}
                  displayValue={'sub_subject_name'}
                  defaultName={'All Sub-Subjects'}
                  options={states.subSubjectOptions}
                  styled={styled}
                  disabled={true}
                />
                <StudentTableDropdown
                  modeslabel={'Topic'}
                  name={'topic'}
                  changeMode={changeMode}
                  value={dropdownVals.selectedTopic}
                  selected_id={'topic_id'}
                  displayValue={'topic_name'}
                  defaultName={'All Topic'}
                  options={states.topicOptions}
                  styled={styled}
                  disabled={true}
                />
                <StudentTableDropdown
                  modeslabel={'Sub Topic'}
                  name={'subTopic'}
                  changeMode={changeMode}
                  value={dropdownVals.selectedSubTopic}
                  selected_id={'sub_topic_id'}
                  displayValue={'sub_topic_name'}
                  defaultName={'All Sub Topic'}
                  options={states.subTopicOptions}
                  styled={styled}
                  disabled={true}
                />
                <StudentTableDropdown
                  modeslabel={'Objective'}
                  name={'objective'}
                  changeMode={changeMode}
                  value={dropdownVals.selectedObjectives}
                  selected_id={'objective_id'}
                  displayValue={'objective'}
                  defaultName={'All Objectives'}
                  options={states.objectiveOptions}
                  styled={styled}
                  disabled={true}
                />
              </div>
            </div>
            {/* <div className={styled.calSec}>{calandarSection()}</div> */}
          </div>
          <div>
            <div className={styled.student_length}>Total Records - {data?.length ?? 0}</div>
            <StudentTable states={states} studentData={data} tableClick={tableClick} setData={setData} />
          </div>
        </div>
      </GridContainer>

      {/* {tableClick ? <StudentDetails /> : false } */}
    </>
  );
};

export default Students;
