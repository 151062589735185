import { CUBEJS_API } from '../../config/Api';
import cubejs from '@cubejs-client/core';
import { toast } from 'react-toastify';

function cubeFunction() {
  var cubeJsToken = window.localStorage.getItem('cmn_cubeJs_token');
  var cubejsAPIFunction = cubejs(cubeJsToken, {
    apiUrl: CUBEJS_API,
  });
  return cubejsAPIFunction;
}

function handleError(error) {
  console.log(error);
  if (error.response.error) {
    console.log(error.response.error);
    toast.error(error.response.error);
  }
  return error;
}

// RA-STUD-01-YEAR -- Replaces
// RA-ASSESS-08-02  Studets page - table data
// RA-ASSESS-08-02-01 - Studets page - table data (RV support query)
export async function fetchStudentData(academicPeriod, institutionId, studyCircleId) {
  const cubejsApi = cubeFunction();

  const finalFilters =
    studyCircleId && studyCircleId != -1
      ? [
          {
            member: 'StudentSummary_YEAR.academicPeriod',
            operator: 'equals',
            values: [`${academicPeriod}`],
          },
          {
            member: 'StudentSummary_YEAR.institutionId',
            operator: 'equals',
            values: [`${institutionId}`],
          },
          {
            member: 'StudentSummary_YEAR.studyCircleId',
            operator: 'equals',
            values: [`${studyCircleId}`],
          },
        ]
      : [
          {
            member: 'StudentSummary_YEAR.academicPeriod',
            operator: 'equals',
            values: [`${academicPeriod}`],
          },
          {
            member: 'StudentSummary_YEAR.institutionId',
            operator: 'equals',
            values: [`${institutionId}`],
          },
        ];

  const qStudentData = {
    filters: finalFilters,
    dimensions: [
      'StudentSummary_YEAR.academicPeriod',
      'StudentSummary_YEAR.institutionId',
      'StudentSummary_YEAR.studentId',
      'StudentSummary_YEAR.studentName',
      'StudentSummary_YEAR.studyCircleId',
      'StudentSummary_YEAR.studyCircleName',
      'StudentSummary_YEAR.questionsAttemptedCount',
      'StudentSummary_YEAR.questionsCount',
      'StudentSummary_YEAR.avgStudentScore',
      'StudentSummary_YEAR.avgStudentVelocity',
      'StudentSummary_YEAR.studyCircleRankNo',
      'StudentSummary_YEAR.groupPercentile',
      'StudentSummary_YEAR.studentVelocityTrend',
      'StudentSummary_YEAR.handRaiseCount',
      'StudentSummary_YEAR.profilePicUrl',
    ],
    order: {
      'StudentSummary_YEAR.studentId': 'asc',
    },
  };

  try {
    let startTime = Date.now();
    const result = await cubejsApi.load(qStudentData);
    let endTime = Date.now();
    let timeTaken = endTime - startTime;
    // console.log('Total Time taken for fetchStudentData', timeTaken);
    // console.log("RA-ASSESS-08-02", result);
    return result;
  } catch (error) {
    console.log('error RA-ASSESS-08-02', error);
    handleError(error);
  }
}

//RA-ASSESS-09-01 - student details page - subjects read velocity --> new name: RA-STUD-03-YEAR -new used in 2.0 QA
export async function fetchAllSubjectReadVelocity(academicPeriodId, studentId, studyCircleId) {
  const cubejsApi = cubeFunction();
  const qAllSubjectReadVelocity = {
    order: {},
    filters: [
      {
        member: 'StudentSummary_YEAR.academicPeriod',
        operator: 'equals',
        values: [`${academicPeriodId}`],
      },
      {
        member: 'StudentSummary_YEAR.studentId',
        operator: 'equals',
        values: [`${studentId}`],
      },
      {
        member: 'StudentSummary_YEAR.studyCircleId',
        operator: 'equals',
        values: [`${studyCircleId}`],
      },
    ],
    dimensions: ['StudentSummary_YEAR.avgStudentVelocity', 'StudentSummary_YEAR.studyCircleId'],
  };
  try {
    let startTime = Date.now();
    const result = await cubejsApi.load(qAllSubjectReadVelocity);
    let endTime = Date.now();
    let timeTaken = endTime - startTime;
    // console.log('Time taken for RA_ASSESS_09-01 fetchAllSubjectReadVelocity', timeTaken);
    // console.log('RA-ASSESS-09-01', result);
    return result;
  } catch (error) {
    console.log('RA-ASSESS-09-01', error);
    handleError(error);
  }
}

//RA-ASSESS-09-02 - student details page - each subject read velocity --> new name: RA-STUD-05
export async function fetchEachSubjectReadVelocity(filters, studyCircleId, academicStartDate, academicEndDate) {
  // console.log("filters", filters)
  const cubejsApi = cubeFunction();
  const qEachSubjectReadVelocity = {
    filters: [
      {
        member: 'AssessmentResponse.studyCircleId',
        operator: 'equals',
        values: [studyCircleId],
      },
      ...filters,
    ],
    measures: ['AssessmentResponse.avgVelocity'],
    dimensions: ['Subject.name', 'Assessment.subjectId'],
    order: [['Subject.name', 'asc']],
    timeDimensions: [
      {
        dimension: 'AssessmentResponse.assessmentDate',
        dateRange: [`${academicStartDate}`, `${academicEndDate}`],
      },
    ],
  };

  try {
    let startTime = Date.now();
    const result = await cubejsApi.load(qEachSubjectReadVelocity);
    let endTime = Date.now();
    let timeTaken = endTime - startTime;
    // console.log('Time taken for fetchEachSubjectReadVelocity', timeTaken);
    // console.log("RA-ASSESS-09-02", result);
    return result;
  } catch (error) {
    console.log('RA-ASSESS-09-02', error);
    handleError(error);
  }
}

// RA-ASSESS-09-03: Individual subject graph plot --> new name: RA-STUD-06
export async function fetchIndividualSubjectsGraph(studentId, studyCircleId, subjectId, academicStartDate, academicEndDate) {
  const cubejsApi = cubeFunction();
  const qFetchAllSubjectsGraph = {
    measures: ['AssessmentResponse.avgVelocity'],
    // timeDimensions: [
    //   {
    //     dimension: 'AssessmentResponse.assessmentDate',
    //   },
    // ],
    // order: {
    //   "AssessmentResponse.assessmentDate": "desc",
    // },
    filters: [
      {
        member: 'AssessmentResponse.studentId',
        operator: 'equals',
        values: [`${studentId}`],
      },
      {
        member: 'AssessmentResponse.studyCircleId',
        operator: 'equals',
        values: [`${studyCircleId}`],
      },
      {
        member: 'Assessment.subjectId',
        operator: 'equals',
        values: [`${subjectId}`],
      },
    ],
    dimensions: ['AssessmentResponse.assessmentDate', 'Assessment.subjectId'],
    limit: 4,
    timeDimensions: [
      {
        dimension: 'AssessmentResponse.assessmentDate',
        dateRange: [`${academicStartDate}`, `${academicEndDate}`],
      },
    ],
  };
  try {
    let startTime = Date.now();
    const result = await cubejsApi.load(qFetchAllSubjectsGraph);
    let endTime = Date.now();
    let timeTaken = endTime - startTime;
    // console.log('Time taken for fetchIndividualSubjectsGraph', timeTaken);
    // console.log("RA-ASSESS-09-03", result);
    return result;
  } catch (error) {
    console.log('RA-ASSESS-09-03', error);
    handleError(error);
  }
}

// RA-ASSESS-09-04: All subject graph plot --> new name: RA-STUD-04
export async function fetchAllSubjectsGraph(filters, studyCircleId, academicStartDate, academicEndDate) {
  // console.log("filters inside RA-ASSESS-09-04", filters)
  const cubejsApi = cubeFunction();
  const qFetchAllSubjectsGraph = {
    filters: [
      {
        member: 'AssessmentResponse.studyCircleId',
        operator: 'equals',
        values: [studyCircleId],
      },
      ...filters,
    ],
    measures: ['AssessmentResponse.avgVelocity'],
    // timeDimensions: [
    //   {
    //     dimension: 'AssessmentResponse.assessmentDate',
    //   },
    // ],
    order: {
      'AssessmentResponse.assessmentDate': 'desc',
    },
    dimensions: ['AssessmentResponse.assessmentDate'],
    limit: 4,
    timeDimensions: [
      {
        dimension: 'AssessmentResponse.assessmentDate',
        dateRange: [`${academicStartDate}`, `${academicEndDate}`],
      },
    ],
  };
  try {
    let startTime = Date.now();
    const result = await cubejsApi.load(qFetchAllSubjectsGraph);
    let endTime = Date.now();
    let timeTaken = endTime - startTime;
    // console.log('Time taken for fetchAllSubjectsGraph', timeTaken);
    // console.log("RA-ASSESS-09-04", result);
    return result;
  } catch (error) {
    console.log('RA-ASSESS-09-04', error);
    handleError(error);
  }
}

//RA-ASSESS-11-01 Difficulty Analytics Summary --> new name: RA-STUD-12
export async function fetchDifficultySum(filters, academicStartDate, academicEndDate) {
  const cubeJsApi = cubeFunction();
  const qDifficultySum = {
    // timeDimensions: [
    //   {
    //     dimension: 'AssessmentResponse.assessmentDate',
    //   },
    // ],
    order: {
      'AssessmentResponse.totalCorrects': 'desc',
    },
    dimensions: ['Question.difficulty'],
    filters: [
      ...filters,
      {
        member: 'AssessmentResponse.result',
        operator: 'notEquals',
        values: ['absent'],
      },
    ],
    measures: [
      'AssessmentResponse.totalCorrects',
      'AssessmentResponse.totalNearCorrects',
      'AssessmentResponse.totalIncorrects',
      'AssessmentResponse.totalNotAnswereds',
      'AssessmentResponse.count',
    ],
    timeDimensions: [
      {
        dimension: 'AssessmentResponse.assessmentDate',
        dateRange: [`${academicStartDate}`, `${academicEndDate}`],
      },
    ],
  };
  try {
    let startTime = Date.now();
    const result = await cubeJsApi.load(qDifficultySum);
    let endTime = Date.now();
    let timeTaken = endTime - startTime;
    // console.log('Time taken for fetchDifficultySum', timeTaken);
    // const result = await cubeJsApi.load(qDifficultySum);
    // console.log("RA-ASSESS-11-01", result);
    return result;
  } catch (error) {
    console.log('RA-ASSESS-11-01', error);
    handleError(error);
  }
}

// RA-STUD-07-YEAR -- Combined query for fetch all values in student details screen This single query replaces all the queries below
// RA-ASSESS-10-01;
// RA-ASSESS-10-03;
// RA-ASSESS-10-02;
// RA-ASSESS-10-04;
// RA-ASSESS-10-04-01;
// RA-ASSESS-10-05;
// RA-ASSESS-10-05-01;
// RA-ASSESS-11-02;
// RA-ASSESS-11-02-02;
// RA-ASSESS-11-03;
// RA-ASSESS-11-04;
// RA-ASSESS-11-04-02;
// RA-ASSESS-11-05;
// RA-ASSESS-11-05-02;
// RA-ASSESS-12;
// RA-ASSESS-13;
// RA-ASSESS-14;
export async function fetchAllValuesForStudent(academicPeriodId, studentId, studyCircleId) {
  const cubejsApi = cubeFunction();
  const qReadVelocityStu = {
    filters: [
      {
        member: 'StudentSummary_YEAR.academicPeriod',
        operator: 'equals',
        values: [`${academicPeriodId}`],
      },
      {
        member: 'StudentSummary_YEAR.studentId',
        operator: 'equals',
        values: [`${studentId}`],
      },
      {
        member: 'StudentSummary_YEAR.studyCircleId',
        operator: 'equals',
        values: [`${studyCircleId}`],
      },
    ],
    dimensions: [
      'StudentSummary_YEAR.avgGroupVelocity',
      'StudentSummary_YEAR.avgGroupScore',
      'StudentSummary_YEAR.avgGroupAccuracy',
      'StudentSummary_YEAR.avgGroupTimeTaken',
      'StudentSummary_YEAR.avgStudentVelocity',
      'StudentSummary_YEAR.groupPercentile',
      'StudentSummary_YEAR.studentMarksScored',
      'StudentSummary_YEAR.avgStudyCircleVelocity',
      'StudentSummary_YEAR.studyCirclePercentile',
      'StudentSummary_YEAR.totalCorrects',
      'StudentSummary_YEAR.totalIncorrects',
      'StudentSummary_YEAR.totalNearCorrects',
      'StudentSummary_YEAR.totalNotAnswered',
      'StudentSummary_YEAR.questionsCount',
      'StudentSummary_YEAR.questionsAttemptedCount',
      'StudentSummary_YEAR.handRaiseCount',
      'StudentSummary_YEAR.avgStudentAccuracy',
      'StudentSummary_YEAR.avgStudentTimeTaken',
      'StudentSummary_YEAR.studentAccuracyTrend',
      'StudentSummary_YEAR.studentScoreTrend',
      'StudentSummary_YEAR.studentTimeTakenTrend',
      'StudentSummary_YEAR.studentVelocityTrend',
    ],
  };
  try {
    const result = await cubejsApi.load(qReadVelocityStu);
    // console.log("RA-ASSESS-10-01", result);
    return result?.loadResponses[0]?.data[0];
  } catch (error) {
    console.log('RA-ASSESS-10-01', error);
    handleError(error);
  }
}

// RA-STUD-07-DAY -- Replacement for RA-ASSESS-15
export async function fetchStudentTrendData(startDate, endDate, studentId, studyCircleId) {
  const cubejsApi = cubeFunction();
  const qReadVelocityStu = {
    timeDimensions: [
      {
        dimension: 'StudentSummary_DAY.assessmentDay',
        dateRange: [`${startDate}`, `${endDate}`],
      },
    ],
    dimensions: [
      'StudentSummary_DAY.avgStudentAccuracy',
      'StudentSummary_DAY.avgStudentScore',
      'StudentSummary_DAY.avgStudentVelocity',
      'StudentSummary_DAY.groupPercentile',
      'StudentSummary_DAY.performanceGroupRankNo',
      'StudentSummary_DAY.assessmentDay',
    ],
    filters: [
      {
        member: 'StudentSummary_DAY.studentId',
        operator: 'equals',
        values: [`${studentId}`],
      },
      {
        member: 'StudentSummary_DAY.studyCircleId',
        operator: 'equals',
        values: [`${studyCircleId}`],
      },
    ],
  };
  try {
    const result = await cubejsApi.load(qReadVelocityStu);
    // console.log('RA-STUD-07-DAY ', result);
    return result?.loadResponses[0]?.data;
  } catch (error) {
    console.log('RA-STUD-07-DAY ', error);
    handleError(error);
  }
}
