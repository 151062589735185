import React from 'react';
import { greenArrow, orangeArrow } from '../../pages/Performance/Images/Images';
import { BsArrowRight } from 'react-icons/all';

const PerformanceScoreBar = (props) => {
  const { styles, cubeData, scores } = props;

  return (
    <div className={styles.score}>
      <div className={styles.score_text}>
        <div className={styles.score_text_top}>
          <div className={styles.score_text_score}>score</div>
          <div className={styles.score_text_btn}>
            <span className={styles.score_text_num}>{`${cubeData.score}`}</span>
            <span>
              {cubeData.scorePerformanceTrend == '1' ? (
                <img src={greenArrow} alt="green_arrow" />
              ) : cubeData.scorePerformanceTrend == '0' ? (
                <BsArrowRight className={styles.trendZero} />
              ) : (
                <img src={orangeArrow} alt="orange arrow" />
              )}
            </span>
          </div>
        </div>
        <div className={styles.score_text_bottom}>
          <div className={styles.score_text_bottom_avg}>group avg.</div>
          <div className={styles.score_text_bottom_num}>{`${(+cubeData.grpScore || 0).toFixed(2)}`}</div>
        </div>
      </div>
      <div className={styles.score_right_div}>
        <div className={styles.score_right_div_bar}>
          <div className={styles.green} style={{ width: [cubeData.scoreGraph.correctPer] }}></div>
          <div className={styles.blue} style={{ width: [cubeData.scoreGraph.nearCorrectPer] }}></div>
          <div className={styles.red} style={{ width: [cubeData.scoreGraph.incorrectPer] }}></div>
          <div className={styles.grey} style={{ width: [cubeData.scoreGraph.notAnsweredPer] }}></div>
        </div>
        <div className={styles.score_table}>
          <div className={styles.score_table_num}>
            {/* {data.questions.map((el) => ( */}
            <div
              className={styles.score_val_total}
              // el === 96 ? styles.score_val_total : styles.score_val
            >
              {cubeData.scoreGraph.total}
            </div>
            <div className={styles.score_val}>{cubeData.scoreGraph.correctPer}</div>
            <div className={styles.score_val}>{cubeData.scoreGraph.nearCorrectPer}</div>
            <div className={styles.score_val}>{cubeData.scoreGraph.incorrectPer}</div>
            <div className={styles.score_val}>{cubeData.scoreGraph.notAnsweredPer}</div>
            {/*  ))} */}
          </div>
          <div className={styles.score_table_test}>
            {scores[1].test.map((el, index) => (
              <div
                key={index}
                className={
                  el === 'questions'
                    ? styles.question
                    : el === 'correct'
                      ? styles.correct
                      : el === 'near correct'
                        ? styles.nCorrect
                        : el === 'incorrect'
                          ? styles.iCorrect
                          : el === 'not answered'
                            ? styles.nAnswer
                            : false
                }
              >
                {el}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceScoreBar;
