import React from 'react';
import { Pie } from 'react-chartjs-2';
import 'chartjs-plugin-labels';

const TrendGraph = (props) => {
  const data = {
    datasets: [
      {
        data: props.data,
        backgroundColor: ['#11C055', '#A5A5A5', '#E40F33'],
        weight: 2,
      },
    ],

    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: props.labels,
  };
  const options = {
    elements: {
      arc: {
        borderWidth: 1,
      },
    },
    // segmentShowStroke: false,
    legend: {
      display: false,
    },
    plugins: {
      labels: [
        {
          render: 'label',
          arc: true,
          position: 'border',
          fontColor: '#fff',
          fontSize: 15,
        },
        {
          render: 'percentage',
          fontColor: '#fff',
          fontSize: 15,
        },
      ],
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };
  return <Pie data={data} options={options} width={270} height={270} />;
};

export default TrendGraph;
