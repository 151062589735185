import React from 'react';
import '../../pages/Attendance/index.css';

const AttendenceDropdown = (props) => {
  const { styles, modeslabel, changeMode, value, options, selectedId, initialLabel, displayName, name } = props;

  return (
    <span className="attendanceSelect1">
      <div className={styles.modeslabel}>{modeslabel}</div>
      <select name={name} id={name} onChange={(e) => changeMode(e, name)} value={value}>
        <option value="-1">{initialLabel}</option>
        {options?.map((el, key) => (
          <option value={el[selectedId]} key={key}>
            {el[displayName]}
          </option>
        ))}
      </select>
    </span>
  );
};

export default AttendenceDropdown;
