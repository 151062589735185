import { getAnswerDetailsAPI, getGrpAnswerDetailsAPI } from '../../../../api/cube/sessionAPI';
import roundTwoDecimal from '../../../../components/roundTwoDecimal/roundTwoDecimal';

export async function getAnswerDetails(assessmentId, questionId, institutionId, groupInstitutionID, startDate, endDate) {
  // console.log(assessmentId);
  const result = await getAnswerDetailsAPI(assessmentId, questionId, institutionId, startDate, endDate);
  const grpResult = await getGrpAnswerDetailsAPI(assessmentId, questionId, groupInstitutionID, startDate, endDate);
  // console.log(result.loadResponse.results);
  // console.log(grpResult.loadResponse.results[0].data);

  let resultArray = [];
  let grpResultArray = [];

  if (result.loadResponse.results[0].data.length !== 0) {
    // console.log(result.loadResponse.results[0].data[0]);
    const resultObject = result.loadResponse.results[0].data[0];
    var totalCount =
      +resultObject['AssessmentResponse.totalCorrects'] +
      +resultObject['AssessmentResponse.totalNearCorrects'] +
      +resultObject['AssessmentResponse.totalIncorrects'] +
      +resultObject['AssessmentResponse.totalNotAnswereds'];
    resultArray = [
      roundTwoDecimal((resultObject['AssessmentResponse.totalCorrects'] * 100) / totalCount),
      roundTwoDecimal((resultObject['AssessmentResponse.totalNearCorrects'] * 100) / totalCount),
      roundTwoDecimal((resultObject['AssessmentResponse.totalIncorrects'] * 100) / totalCount),
      roundTwoDecimal((resultObject['AssessmentResponse.totalNotAnswereds'] * 100) / totalCount),
    ];
  } else {
    resultArray = [0, 0, 0, 0];
  }
  if (grpResult.loadResponse.results[0].data.length !== 0) {
    // console.log(grpResult.loadResponse.results[0].data[0]);
    const grpResultObject = grpResult.loadResponse.results[0].data[0];
    var grpTotalCount =
      +grpResultObject['AssessmentResponse.totalCorrects'] +
      +grpResultObject['AssessmentResponse.totalNearCorrects'] +
      +grpResultObject['AssessmentResponse.totalIncorrects'] +
      +grpResultObject['AssessmentResponse.totalNotAnswereds'];
    grpResultArray = [
      roundTwoDecimal((grpResultObject['AssessmentResponse.totalCorrects'] * 100) / grpTotalCount),
      roundTwoDecimal((grpResultObject['AssessmentResponse.totalNearCorrects'] * 100) / grpTotalCount),
      roundTwoDecimal((grpResultObject['AssessmentResponse.totalIncorrects'] * 100) / grpTotalCount),
      roundTwoDecimal((grpResultObject['AssessmentResponse.totalNotAnswereds'] * 100) / grpTotalCount),
    ];
  } else {
    grpResultArray = [0, 0, 0, 0];
  }
  return { each: resultArray, group: grpResultArray };
  // else {
  //   return { each: [0, 0, 0, 0], group: [0, 0, 0, 0] };
  // }
}
