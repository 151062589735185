import React from 'react';
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import { orangeArrow, greenArrow } from '../../pages/Students/Images/Images';

const StudentTimeBar = (props) => {
  const { styles, studentTime, groupTime, timeMark, studentTimeTakenTrend } = props;

  let student_time = Number(studentTime);
  // console.log('timeMark', timeMark, 'student_time', student_time, typeof student_time);

  const CustomSlider = withStyles({
    rail: {
      backgroundImage: 'linear-gradient(45deg, #00A051, #D4B90E,#FFBE00,#FF9E00,#FF0000)',
      height: 13,
      borderRadius: '10px',
      opacity: 1,
    },
    track: { color: 'none', height: 10 },
  })(Slider);

  function valuetext(value) {
    return `${value}`;
  }

  return (
    <div className={styles.time}>
      <div className={styles.time_text}>
        <div className={styles.time_text_top}>
          <div className={styles.time_text_time}>TIME</div>
          <div className={styles.time_text_btn}>
            <span className={styles.time_text_num} style={{ paddingRight: '20px' }}>
              {studentTime}
              <span className={styles.sec}>sec.</span>{' '}
            </span>
            <span style={{ marginLeft: '-20px' }}>
              {+studentTimeTakenTrend ? <img src={greenArrow} alt="green_arrow" /> : <img src={orangeArrow} alt="orange arrow" />}
            </span>
          </div>
        </div>
        <div className={styles.time_text_bottom}>
          <div className={styles.time_text_bottom_avg}>group avg.</div>
          <div>
            <span className={styles.time_text_num}>
              {groupTime}
              <span className={styles.sec}>sec.</span>{' '}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.custom_slider}>
        <CustomSlider
          min={0}
          max={60}
          step={1}
          track={false}
          aria-labelledby="range-slider"
          valueLabelDisplay="auto"
          getAriaValueText={valuetext}
          mark={timeMark}
          value={student_time}
          marks={[
            { value: 0, label: '0' },
            { value: 60, label: '60' },
          ]}
        />
      </div>
    </div>
  );
};

export default StudentTimeBar;
