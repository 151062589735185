// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DataBoard_container__QHOjb {\n  width: 100%;\n  height: 200px;\n  display: flex;\n  /* justify-content: center;\n  align-items: center;\n  align-content: center; */\n  flex-direction: column;\n  background: #fff;\n}\n.DataBoard_header__29vqG {\n  height: 15%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: 15px;\n}\n.DataBoard_header_body__2Y9n7 {\n  margin-top: 10px;\n}\n.DataBoard_footer__19RdP {\n  color: #a6a6a6;\n  height: 52.7px;\n  background: #f5f7f6;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-weight: 500;\n  font-size: 14px;\n  margin-top: 35px;\n}\n.DataBoard_body__1nMLX {\n  height: 75%;\n  width: 100%;\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  align-content: center;\n  margin-top: 60px;\n}\n.DataBoard_left__3Ro1R {\n  height: 100%;\n  width: 100%;\n  padding-left: 100px;\n  padding-right: 100px;\n}\n.DataBoard_right__3lGI8 {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-content: center;\n  align-items: center;\n  background-position: center;\n  background-size: contain;\n}\n\n.DataBoard_reactLoadingBars__2sY_a {\n  margin: 0 auto;\n  position: fixed;\n  top: 45%;\n  left: 49%;\n  z-index: 100;\n}\n\n.DataBoard_loading_animation__2g8al {\n  position: fixed; /* Positioning and size */\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  background-color: rgba(128, 128, 128, 0.5);\n  z-index: 99;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "DataBoard_container__QHOjb",
	"header": "DataBoard_header__29vqG",
	"header_body": "DataBoard_header_body__2Y9n7",
	"footer": "DataBoard_footer__19RdP",
	"body": "DataBoard_body__1nMLX",
	"left": "DataBoard_left__3Ro1R",
	"right": "DataBoard_right__3lGI8",
	"reactLoadingBars": "DataBoard_reactLoadingBars__2sY_a",
	"loading_animation": "DataBoard_loading_animation__2g8al"
};
module.exports = exports;
