import React from 'react';
import Datepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../pages/Attendance/index.css';
import PerformanceTableDropdown from '../PerformanceTableDropdown/PerformanceTableDropdown';

const AttendenceCalendarSection = (props) => {
  const { styles, handleChange, startdate, handleClick, states, academicPeriodsList, academicPeriodId, changeMode } = props;

  const stylesss = {
    monthAct: {
      backgroundColor: '#FD8500',
      color: '#fff',
      fontWeight: 500,
    },
    dayAct: {
      backgroundColor: '#FD8500',
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
      color: '#fff',
      fontWeight: 500,
    },
    yearAct: {
      backgroundColor: '#FD8500',
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
      color: '#fff',
      fontWeight: 500,
    },
  };

  return (
    <div className={styles.sessionCal}>
      <div className={styles.cal_div}>
        <div className={styles.calanderContainer}>
          <div className="calander">
            <div className={styles.calCont}>
              <div className="dateDate">
                {!states?.isYear ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}>
                    <img
                      src={require('../../assets/images/prev-arrow.png')}
                      alt="prev"
                      onClick={(e) => handleChange(startdate, 'prev')}
                      style={{ marginLeft: '40px' }}
                    />
                    <Datepicker
                      id={'fromdate'}
                      selected={startdate}
                      onChange={(e) => handleChange(e, 'calander')}
                      dateFormat={states.isDay ? 'd MMM yyyy' : states.isMonth ? 'MMM yyyy' : states.isYear ? 'yyyy' : null}
                      className={styles.input}
                      readOnly={false}
                      showMonthYearPicker={states.isMonth ? true : null}
                      showYearDropdown={states.isYear ? true : null}
                      // show={()=>test()}
                    />
                    <img
                      src={require('../../assets/images/next-arrow.png')}
                      alt="next"
                      onClick={(e) => handleChange(startdate, 'next')}
                    />
                    <label htmlFor="fromdate">
                      <img
                        src={require('../../assets/images/calender-icon.png')}
                        alt="cal"
                        // onChange={(e) => handleChange(e, "calander")}
                        style={{ margin: '0px 30px', cursor: 'pointer' }}
                      />
                    </label>
                  </div>
                ) : (
                  <PerformanceTableDropdown
                    styles={styles}
                    options={JSON.parse(academicPeriodsList)}
                    value={academicPeriodId}
                    changeMode={changeMode}
                    name={'academic_period'}
                    id_name={'academic_period_name'}
                    modeslabel={'Academic Period'}
                    defaultOption={'Academic Period'}
                    selected_id={'academic_period_id'}
                    width={'245px'}
                  />
                )}
              </div>
              <div className="d_w_m_y">
                <div className="ddd" style={states.isDay ? stylesss.dayAct : null} onClick={() => handleClick('d')}>
                  <p>D</p>
                </div>
                <div className="mmm" style={states.isMonth ? stylesss.monthAct : null} onClick={() => handleClick('m')}>
                  <p>M</p>
                </div>
                <div className="yyy" style={states.isYear ? stylesss.yearAct : null} onClick={() => handleClick('y')}>
                  <p>Y</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendenceCalendarSection;
