import React from 'react';
// import GaugeChart from 'react-gauge-chart';
import styles from './AccuracyChartStyle.module.css';
import ReactSpeedometer from 'react-d3-speedometer';

const AccuracyChart = ({ value }) => {
  return (
    <div className={styles.container}>
      <ReactSpeedometer
        maxValue={5}
        value={value}
        ringWidth={30}
        needleColor="black"
        needleTransitionDuration={4000}
        needleHeightRatio={0.7}
        maxSegmentLabels={5}
        needleTransition="easeElastic"
        segmentColors={['#FF0000', '#FF9E00', '#FFBE00', '#D4B90E', '#00A051']}
      />
    </div>
  );
};

export default AccuracyChart;
