import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Divider, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import * as Yup from 'yup';
import styles from './NewPasswordStyle.module.css';
import TextError from '../login/TextError';
import { BASE_URL } from '../../config/Api';
import ReactLoading from 'react-loading';

// debugger;
const initialValues = {
  NewPassword: '',
  passwordConfirm: '',
};

const validationSchema = Yup.object({
  NewPassword: Yup.string().required('New password is required').min(5, 'password too short'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('NewPassword'), null], 'Password must match')
    .required('Password confirm is required'),
});

const NewPassword = (props) => {
  const [loading, setLoading] = useState(false);
  const [pass, setPass] = useState(true);

  let user_id = props.match.params.user_id;

  const onSubmit = async (values) => {
    setLoading(true);
    const formData = {
      user_id: user_id,
      password: values.NewPassword,
    };
    console.log(formData);
    await Axios.post(`${BASE_URL}/login/reset_password`, formData)
      .then((response) => {
        toast.success('New Password Created Successfully');
        setLoading(false);
        if (response.data.status === true) {
          setTimeout(() => {
            setPass(false);
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  function showPassword() {
    var temp = document.getElementById('passwordConfirm');
    if (temp.type === 'password') {
      temp.type = 'text';
    } else {
      temp.type = 'password';
    }
  }

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loading_animation}>
          <ReactLoading type="bars" color="#FC8802" height="10%" width="10%" className={styles.reactLoadingBars} />
        </div>
      ) : null}
      <Box className={styles.header}>
        <div className={styles.header_body}>
          <img src={require('../../assets/images/read-analytics-logo.png')} alt="header-logo" />
        </div>
      </Box>
      <Divider style={{ background: '#A6C8A6', height: '1px', width: '100%' }} />
      <main className={styles.body}>
        <div className={styles.left}>
          <Box component="div" className={styles.reset_box}>
            <Box component="p" className={styles.reset} mb={0}>
              New Password
            </Box>
            <p className={styles.txt}>To change your current Password, type the new password, and then retype it. Click Save.</p>
            <p className={styles.txt1}>
              We recommend you to have long password with a mix of capital and lowercase letters, numbers, and symbols.
            </p>
            {pass ? (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
                className={styles.formik}
              >
                {(formik) => {
                  return (
                    <div className={styles.form_div}>
                      <Form className={styles.form}>
                        <Box className={styles.password} component="div">
                          <Field
                            type="password"
                            id="NewPassword"
                            name="NewPassword"
                            placeholder="Password"
                            className={styles.password_field}
                          />
                        </Box>
                        <ErrorMessage name="NewPassword" component={TextError}>
                          {(error) => <div className="error">{error}</div>}
                        </ErrorMessage>
                        <Box className={styles.password} component="div">
                          <Field
                            type="password"
                            id="passwordConfirm"
                            name="passwordConfirm"
                            placeholder="Re-enter Password"
                            className={styles.password_field}
                          />
                          <Box component="span" className={styles.show_password} onClick={() => showPassword()}>
                            show password
                          </Box>
                        </Box>
                        <ErrorMessage name="passwordConfirm" component={TextError}>
                          {(error) => <div className="error">{error}</div>}
                        </ErrorMessage>
                        <button type="submit" className={styles.Button} disabled={!formik.isValid || formik.isSubmitting}>
                          <div>Save</div>
                        </button>
                      </Form>
                    </div>
                  );
                }}
              </Formik>
            ) : (
              <div className={styles.form_div}>
                <div className={styles.txt2}>Your new password has been saved.</div>
                <Link to="/">
                  <div className={styles.login_redirect}>
                    Login
                    <img src={require('../../assets/images/rightArrow.png')} alt="right-arrow" />
                  </div>
                </Link>
              </div>
            )}
          </Box>
        </div>
        <div className={styles.right}>
          <img src={require('../../assets/images/newPassword-logo.png')} alt="newPassword-logo" />
        </div>
      </main>
      <footer className={styles.footer}>
        &copy; {new Date().getFullYear()} Apps'n'Devices Technologies Pvt Ltd. All rights reserved.
      </footer>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />
    </div>
  );
};

export default NewPassword;
