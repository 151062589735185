export function createPerformanceFilter(
  studyCircleId,
  selectedMode,
  selectedAssessmentType,
  selectedTopic,
  selectedSubTopic,
  selectedSubject,
  selectedSubSubject,
  selectedObjectives,
  prevDate,
  nextDate,
  // selectedGroupId
) {
  const filter = [];

  filter.push({
    member: 'AssessmentResponse.studyCircleId',
    operator: 'equals',
    values: [`${studyCircleId}`],
  });
  if (+selectedMode !== -1) {
    filter.push({
      member: 'Assessment.assessmentModeId',
      operator: 'equals',
      values: [`${selectedMode}`],
    });
  }
  if (+selectedAssessmentType !== -1) {
    filter.push({
      member: 'Assessment.assessmentTypeId',
      operator: 'equals',
      values: [`${selectedAssessmentType}`],
    });
  }
  if (+selectedSubject !== -1) {
    filter.push({
      member: 'Assessment.subjectId',
      operator: 'equals',
      values: [`${selectedSubject}`],
    });
  }
  // if (+selectedSubSubject !== -1) {
  //   filter.push({
  //     member: "Assessment.subSubjectId",
  //     operator: "equals",
  //     values: [selectedSubSubject],
  //   });
  // }
  if (+selectedTopic !== -1) {
    filter.push({
      member: 'Assessment.topicId',
      operator: 'equals',
      values: [`${selectedTopic}`],
    });
  }
  // if (+selectedSubTopic !== -1) {
  //   filter.push({
  //     member: "Assessment.subTopicId",
  //     operator: "equals",
  //     values: [selectedSubTopic],
  //   });
  // }
  if (+selectedObjectives !== -1) {
    filter.push({
      member: 'Assessment.objectiveId',
      operator: 'equals',
      values: [`${selectedObjectives}`],
    });
  }
  // if (selectedGroupId !== -1) {
  //   filter.push({
  //     member: "Assessment.groupSessionId",
  //     operator: "equals",
  //     values: [selectedGroupId],
  //   });
  // }
  return filter;
}

export const createDiffucltySummaryFilter = (
  studyCircleId,
  selectedMode,
  selectedType,
  selectedSubject,
  organiserId,
  institutionId,
) => {
  const filter = [];
  filter.push({
    member: 'AssessmentResponse.studyCircleId',
    operator: 'equals',
    values: [`${studyCircleId}`],
  });
  filter.push({
    member: 'AssessmentResponse.institutionId',
    operator: 'equals',
    values: [`${institutionId}`],
  });
  if (+selectedMode !== -1) {
    filter.push({
      member: 'Assessment.assessmentModeId',
      operator: 'equals',
      values: [`${selectedMode}`],
    });
  }
  if (+selectedType !== -1) {
    filter.push({
      member: 'Assessment.assessmentTypeId',
      operator: 'equals',
      values: [`${selectedType}`],
    });
  }
  if (+selectedSubject !== -1) {
    filter.push({
      member: 'Assessment.subjectId',
      operator: 'equals',
      values: [`${selectedSubject}`],
    });
  }
  if (+organiserId && organiserId !== -1) {
    filter.push({
      member: 'Assessment.organizerTypeId',
      operator: 'equals',
      values: [`${organiserId}`],
    });
  }
  return filter;
};

export const createGrpRankPercentileFilter = (
  studyCircleId,
  selectedMode,
  selectedType,
  selectedSubject,
  organiserId,
  institutionId,
  states,
  academicPeriodId,
) => {
  const filter = [];
  filter.push({
    member: states?.isDay
      ? 'GroupPerformanceRank_Day.studyCircleId'
      : states?.isMonth
        ? 'GroupPerformanceRank_Month.studyCircleId'
        : states?.isYear
          ? 'GroupPerformanceRank_Year.studyCircleId'
          : null,
    operator: 'equals',
    values: [`${studyCircleId}`],
  });
  filter.push({
    member: states?.isDay
      ? 'GroupPerformanceRank_Day.institutionId'
      : states?.isMonth
        ? 'GroupPerformanceRank_Month.institutionId'
        : states?.isYear
          ? 'GroupPerformanceRank_Year.institutionId'
          : null,
    operator: 'equals',
    values: [`${institutionId}`],
  });
  if (+selectedMode !== -1) {
    filter.push({
      member: states?.isDay
        ? 'GroupPerformanceRank_Day.assessmentModeId'
        : states?.isMonth
          ? 'GroupPerformanceRank_Month.assessmentModeId'
          : states?.isYear
            ? 'GroupPerformanceRank_Year.assessmentModeId'
            : null,
      operator: 'equals',
      values: [`${selectedMode}`],
    });
  }
  if (+selectedType !== -1) {
    filter.push({
      member: states?.isDay
        ? 'GroupPerformanceRank_Day.assessmentTypeId'
        : states?.isMonth
          ? 'GroupPerformanceRank_Month.assessmentTypeId'
          : states?.isYear
            ? 'GroupPerformanceRank_Year.assessmentTypeId'
            : null,
      operator: 'equals',
      values: [`${selectedType}`],
    });
  }
  if (+selectedSubject !== -1) {
    filter.push({
      member: states?.isDay
        ? 'GroupPerformanceRank_Day.subjectId'
        : states?.isMonth
          ? 'GroupPerformanceRank_Month.subjectId'
          : states?.isYear
            ? 'GroupPerformanceRank_Year.subjectId'
            : null,
      operator: 'equals',
      values: [`${selectedSubject}`],
    });
  }
  if (+organiserId && organiserId !== -1) {
    filter.push({
      member: states?.isDay
        ? 'GroupPerformanceRank_Day.assessmentOrganizerTypeId'
        : states?.isMonth
          ? 'GroupPerformanceRank_Month.assessmentOrganizerTypeId'
          : states?.isYear
            ? 'GroupPerformanceRank_Year.assessmentOrganizerTypeId'
            : null,
      operator: 'equals',
      values: [`${organiserId}`],
    });
  }
  if (states?.isYear) {
    filter.push({
      member: 'GroupPerformanceRank_Year.academicPeriod',
      operator: 'equals',
      values: [+academicPeriodId],
    });
  }
  return filter;
};

export const createPerformanceDetailFetchFilter = (
  studyCircleId,
  selectedMode,
  selectedType,
  selectedSubject,
  organiserId,
  institutionId,
  states,
  academicPeriodId,
) => {
  const filter = [];
  filter.push({
    member: states?.isDay
      ? 'GroupPerformanceRank_Day.studyCircleId'
      : states?.isMonth
        ? 'GroupPerformanceRank_Month.studyCircleId'
        : states?.isYear
          ? 'GroupPerformanceRank_Year.studyCircleId'
          : null,
    operator: 'equals',
    values: [`${studyCircleId}`],
  });
  filter.push({
    member: states?.isDay
      ? 'GroupPerformanceRank_Day.institutionId'
      : states?.isMonth
        ? 'GroupPerformanceRank_Month.institutionId'
        : states?.isYear
          ? 'GroupPerformanceRank_Year.institutionId'
          : null,
    operator: 'equals',
    values: [`${institutionId}`],
  });
  if (+selectedMode !== -1) {
    filter.push({
      member: states?.isDay
        ? 'GroupPerformanceRank_Day.assessmentModeId'
        : states?.isMonth
          ? 'GroupPerformanceRank_Month.assessmentModeId'
          : states?.isYear
            ? 'GroupPerformanceRank_Year.assessmentModeId'
            : null,
      operator: 'equals',
      values: [`${selectedMode}`],
    });
  }
  if (+selectedType !== -1) {
    filter.push({
      member: states?.isDay
        ? 'GroupPerformanceRank_Day.assessmentTypeId'
        : states?.isMonth
          ? 'GroupPerformanceRank_Month.assessmentTypeId'
          : states?.isYear
            ? 'GroupPerformanceRank_Year.assessmentTypeId'
            : null,
      operator: 'equals',
      values: [`${selectedType}`],
    });
  }
  if (+selectedSubject !== -1) {
    filter.push({
      member: states?.isDay
        ? 'GroupPerformanceRank_Day.subjectId'
        : states?.isMonth
          ? 'GroupPerformanceRank_Month.subjectId'
          : states?.isYear
            ? 'GroupPerformanceRank_Year.subjectId'
            : null,
      operator: 'equals',
      values: [`${selectedSubject}`],
    });
  }
  if (+organiserId && organiserId !== -1) {
    filter.push({
      member: states?.isDay
        ? 'GroupPerformanceRank_Day.assessmentOrganizerTypeId'
        : states?.isMonth
          ? 'GroupPerformanceRank_Month.assessmentOrganizerTypeId'
          : states?.isYear
            ? 'GroupPerformanceRank_Year.assessmentOrganizerTypeId'
            : null,
      operator: 'equals',
      values: [`${organiserId}`],
    });
  }
  if (states?.isYear) {
    filter.push({
      member: 'GroupPerformanceRank_Year.academicPeriod',
      operator: 'equals',
      values: [+academicPeriodId],
    });
  }
  return filter;
};
