export const sortAttendance = (value, key, cubeData, setCubeData) => {
  switch (key) {
    case 'Subject':
      if (value) {
        return setCubeData((prevState) => {
          return {
            ...prevState,
            tableData: [
              ...cubeData.tableData.sort((a, b) => (a['Subject.name'].toUpperCase() > b['Subject.name'].toUpperCase() ? 1 : -1)),
            ],
          };
        });
      } else {
        return setCubeData((prevState) => {
          return {
            ...prevState,
            tableData: [
              ...cubeData.tableData
                .sort((a, b) => (a['Subject.name'].toUpperCase() > b['Subject.name'].toUpperCase() ? 1 : -1))
                .reverse(),
            ],
          };
        });
      }
    case 'Organizer':
      if (value) {
        return setCubeData((prevState) => {
          return {
            ...prevState,
            tableData: [
              ...cubeData.tableData.sort((a, b) =>
                a['AssessmentOrganizer.name'].toUpperCase() > b['AssessmentOrganizer.name'].toUpperCase() ? 1 : -1,
              ),
            ],
          };
        });
      } else {
        return setCubeData((prevState) => {
          return {
            ...prevState,
            tableData: [
              ...cubeData.tableData
                .sort((a, b) =>
                  a['AssessmentOrganizer.name'].toUpperCase() > b['AssessmentOrganizer.name'].toUpperCase() ? 1 : -1,
                )
                .reverse(),
            ],
          };
        });
      }
    case 'Type':
      if (value) {
        return setCubeData((prevState) => {
          return {
            ...prevState,
            tableData: [
              ...cubeData.tableData.sort((a, b) =>
                a['AssessmentType.name'].toUpperCase() > b['AssessmentType.name'].toUpperCase() ? 1 : -1,
              ),
            ],
          };
        });
      } else {
        return setCubeData((prevState) => {
          return {
            ...prevState,
            tableData: [
              ...cubeData.tableData
                .sort((a, b) => (a['AssessmentType.name'].toUpperCase() > b['AssessmentType.name'].toUpperCase() ? 1 : -1))
                .reverse(),
            ],
          };
        });
      }
    case 'Conducted':
      if (value) {
        return setCubeData((prevState) => {
          return {
            ...prevState,
            tableData: [...cubeData.tableData.sort((a, b) => (a['Assessment.count'] > b['Assessment.count'] ? 1 : -1))],
          };
        });
      } else {
        return setCubeData((prevState) => {
          return {
            ...prevState,
            tableData: [...cubeData.tableData.sort((a, b) => (a['Assessment.count'] > b['Assessment.count'] ? 1 : -1)).reverse()],
          };
        });
      }
    case 'Questions':
      if (value) {
        return setCubeData((prevState) => {
          return {
            ...prevState,
            tableData: [
              ...cubeData.tableData.sort((a, b) =>
                a['AssessmentResponse.questionsCount'] > b['AssessmentResponse.questionsCount'] ? 1 : -1,
              ),
            ],
          };
        });
      } else {
        return setCubeData((prevState) => {
          return {
            ...prevState,
            tableData: [
              ...cubeData.tableData
                .sort((a, b) => (a['AssessmentResponse.questionsCount'] > b['AssessmentResponse.questionsCount'] ? 1 : -1))
                .reverse(),
            ],
          };
        });
      }
    case 'Read Velocity':
      if (value) {
        return setCubeData((prevState) => {
          return {
            ...prevState,
            tableData: [
              ...cubeData.tableData.sort((a, b) =>
                a['AssessmentResponse.avgVelocity'] > b['AssessmentResponse.avgVelocity'] ? 1 : -1,
              ),
            ],
          };
        });
      } else {
        return setCubeData((prevState) => {
          return {
            ...prevState,
            tableData: [
              ...cubeData.tableData
                .sort((a, b) => (a['AssessmentResponse.avgVelocity'] > b['AssessmentResponse.avgVelocity'] ? 1 : -1))
                .reverse(),
            ],
          };
        });
      }
    default:
      return setCubeData((prevState) => {
        return { ...prevState, tableData: [...cubeData.tableData] };
      });
  }
};
