// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HandRaiseChart_main__2fkGE {\n  display: flex;\n  justify-content: space-between;\n  flex: 1 1;\n  align-items: center;\n  align-content: center;\n  flex-direction: row;\n  margin: 20px 10px;\n}\n.HandRaiseChart_left__1ufKg,\n.HandRaiseChart_right__1QJqz {\n  display: inherit;\n  justify-content: center;\n  align-content: center;\n  line-height: 20px;\n  flex-direction: column;\n}\n.HandRaiseChart_top__2xvpt {\n  color: #464646;\n  font-size: 25px;\n  font-weight: 500;\n  line-height: 34px;\n}\n.HandRaiseChart_bottom__2rE60 {\n  color: #000000;\n  font-size: 12px;\n  font-weight: 500;\n  line-height: 14px;\n  opacity: 0.300000011920929;\n  text-transform: capitalize;\n  font-weight: bold;\n}\n", ""]);
// Exports
exports.locals = {
	"main": "HandRaiseChart_main__2fkGE",
	"left": "HandRaiseChart_left__1ufKg",
	"right": "HandRaiseChart_right__1QJqz",
	"top": "HandRaiseChart_top__2xvpt",
	"bottom": "HandRaiseChart_bottom__2rE60"
};
module.exports = exports;
