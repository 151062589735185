// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TrendChart_left_div_flex__1B1-8 {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  flex-direction: row;\n  flex-shrink: initial;\n  flex: 1 1;\n  flex-grow: 1;\n  width: 100%;\n  margin-top: 25px;\n}\n.TrendChart_left_div_col__22a0D {\n  width: 20%;\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  flex-direction: column;\n  border-collapse: collapse;\n  padding: 10px;\n}\n.TrendChart_trend_div_active__BDS4F {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  background-color: #fc8802;\n  border-radius: 4px;\n  width: 110px;\n  height: 30px;\n  color: #ffffff;\n  padding: 5px;\n  margin: 5px 0px;\n}\n.TrendChart_trend_div__3ylaL {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  background-color: #d8d8d8;\n  border-radius: 4px;\n  width: 110px;\n  height: 30px;\n  padding: 5px;\n  margin: 5px 0px;\n  opacity: 0.6;\n  cursor: pointer;\n}\n.TrendChart_trend_div_name__fyyDm {\n  padding-left: 5px;\n  padding-right: 5px;\n  font-size: 14px;\n  font-weight: 600;\n  line-height: 17px;\n}\n.TrendChart_right_div_col__3OFro {\n  width: 80%;\n}\n@media only screen and (max-width: 490px) {\n  .TrendChart_left_div_flex__1B1-8 {\n    flex-direction: column;\n    margin-top: 0px;\n  }\n  .TrendChart_right_div_col__3OFro,\n  .TrendChart_left_div_col__22a0D {\n    width: 100%;\n  }\n  .TrendChart_left_div_col__22a0D {\n    flex-direction: row;\n    align-items: center;\n    display: flex;\n    justify-content: space-evenly;\n  }\n  .TrendChart_velocity_div__7K8OH {\n    margin-bottom: 0px;\n  }\n  .TrendChart_groupRank_div__3NCiS,\n  .TrendChart_score_div__23G_7,\n  .TrendChart_accuracy_div__3cUYu {\n    margin: 5px;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"left_div_flex": "TrendChart_left_div_flex__1B1-8",
	"left_div_col": "TrendChart_left_div_col__22a0D",
	"trend_div_active": "TrendChart_trend_div_active__BDS4F",
	"trend_div": "TrendChart_trend_div__3ylaL",
	"trend_div_name": "TrendChart_trend_div_name__fyyDm",
	"right_div_col": "TrendChart_right_div_col__3OFro",
	"velocity_div": "TrendChart_velocity_div__7K8OH",
	"groupRank_div": "TrendChart_groupRank_div__3NCiS",
	"score_div": "TrendChart_score_div__23G_7",
	"accuracy_div": "TrendChart_accuracy_div__3cUYu"
};
module.exports = exports;
