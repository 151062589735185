import React from 'react';
import { BsArrowUpRight, BsArrowRight, BsArrowDownRight } from 'react-icons/all';
import up from '../../../assets/images/top-arrow.png';
import down from '../../../assets/images/down-arrow.png';
import roundTwoDecimal from '../../../components/roundTwoDecimal/roundTwoDecimal';
import { sortStudents } from '../StudentsDetailscreen/sortStudents';
import styled from '../StudentStyle.module.css';

const StudentTable = ({ states, studentData, tableClick, setData }) => {
  return (
    <div className={styled.tableResponsive} style={{ marginTop: 0, paddingTop: 0, maxHeight: '72vh' }}>
      <table id="students" className={studentData?.length === 0 ? styled.studentTable : ''} style={{ width: '100%' }}>
        <thead className={styled.stuTableHeader}>
          <tr className={styled.thead_row}>
            {states.t_head.map((el, index) => {
              return (
                <th key={index} className={studentData?.length === 0 ? styled.studentTable_th : ''}>
                  <div
                    className={
                      el === 'RA#'
                        ? styled.th_RA
                        : el === 'Name'
                          ? styled.th_name
                          : el === 'Read Velocity'
                            ? styled.th_rv
                            : styled.th
                    }>
                    <span>{el}</span>
                    {el === 'Attendance' ? null : (
                      <div className="arrow">
                        <span className="arrUp" onClick={() => sortStudents(true, el, studentData, setData)}>
                          <img src={up} alt="up" />
                        </span>
                        <span className="arrDown" onClick={() => sortStudents(false, el, studentData, setData)}>
                          <img src={down} alt="down" />
                        </span>
                      </div>
                    )}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {studentData?.length === 0 ? (
            <tr
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 'bold',
              }}>
              <td role="img" aria-label="jsx-a11y/accessible-emoji">
                No Data Found
              </td>
            </tr>
          ) : (
            studentData?.map((el, index) => {
              const avgScore = +el['StudentSummary_YEAR.avgStudentScore'];
              const avgVelocity = +el['StudentSummary_YEAR.avgStudentVelocity'];
              return (
                <tr key={index} className={styled.students_row} onClick={() => tableClick(el)}>
                  <td className={styled.col_ra}>RA# {el['StudentSummary_YEAR.studentId']}</td>
                  <td className={styled.col_name}>{el['StudentSummary_YEAR.studentName']}</td>
                  {/* <td className={styled.col_mode}>{el["Responses.sessionMode"]}</td> */}
                  <td className={styled.col_study}>{el['StudentSummary_YEAR.studyCircleName']}</td>
                  <td className={styled.col_attend}>
                    {el['StudentSummary_YEAR.questionsAttemptedCount']}/{el['StudentSummary_YEAR.questionsCount']}
                  </td>
                  <td
                    className={
                      avgScore >= 1 && avgScore <= 2
                        ? 'a'
                        : avgScore >= 2 && avgScore <= 3
                          ? 'b'
                          : avgScore >= 3 && avgScore <= 4
                            ? 'c'
                            : avgScore >= 4 && avgScore <= 5
                              ? 'dM'
                              : 'a'
                    }>
                    <span
                      className={
                        avgScore >= 1 && avgScore <= 2
                          ? 'readVelocity_red'
                          : avgScore >= 2 && avgScore <= 3
                            ? 'readVelocity_yellow'
                            : avgScore >= 3 && avgScore <= 4
                              ? 'readVelocity_blue'
                              : avgScore >= 4 && avgScore <= 5
                                ? 'readVelocity_green'
                                : 'readVelocity_red'
                      }>
                      {roundTwoDecimal(avgScore)}
                    </span>
                  </td>
                  <td
                    className={
                      avgVelocity >= 1 && avgVelocity <= 2
                        ? 'a'
                        : avgVelocity >= 2 && avgVelocity <= 3
                          ? 'b'
                          : avgVelocity >= 3 && avgVelocity <= 4
                            ? 'c'
                            : avgVelocity >= 4 && avgVelocity <= 5
                              ? 'dM'
                              : 'a'
                    }>
                    <span
                      className={
                        avgVelocity >= 1 && avgVelocity <= 2
                          ? 'readVelocity_red'
                          : avgVelocity >= 2 && avgVelocity <= 3
                            ? 'readVelocity_yellow'
                            : avgVelocity >= 3 && avgVelocity <= 4
                              ? 'readVelocity_blue'
                              : avgVelocity >= 4 && avgVelocity <= 5
                                ? 'readVelocity_green'
                                : 'readVelocity_red'
                      }>
                      {roundTwoDecimal(avgVelocity)}
                    </span>
                  </td>
                  <td className={styled.col_rank}>{el['StudentSummary_YEAR.studyCircleRankNo']}</td>
                  <td className={styled.col_rank}>{roundTwoDecimal(+el['StudentSummary_YEAR.groupPercentile'])}</td>
                  <td>
                    {+el['StudentSummary_YEAR.studentVelocityTrend'] === 0 ? (
                      <BsArrowRight className={styled.trendZero} />
                    ) : +el['StudentSummary_YEAR.studentVelocityTrend'] === 1 ? (
                      <BsArrowUpRight className={styled.trendPlus} />
                    ) : (
                      <BsArrowDownRight className={styled.trendMinus} />
                    )}
                  </td>
                  <td className={styled.col_attend}>{el['StudentSummary_YEAR.handRaiseCount'] || 0}</td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default StudentTable;
