import React, { useState, /* useEffect ,*/ forwardRef } from 'react';
import styles from './DataBoard.module.css';
import './DataBoardMoreStyles.css';
// import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calanderIcon from '../../assets/images/calender-icon.png';
import { UploadPDFFile, DownloadPDFFile } from '../../utils/s3utils';

const DataBoard = () => {
  const [loading, setLoading] = useState(false);
  // const [curDate, setCurDate] = useState(new Date());
  const curDate = new Date();
  const [uploadFile, setUploadFile] = useState({
    filepath: 'ra-pdf-reports/2023-09-01-ABCD-report.pdf',
  });

  const CalenderInput = forwardRef(({ value, onClick }, ref) => (
    <div className="calender">
      <img src={calanderIcon} alt="calender-icon" />
      <button className="calender-input" onClick={onClick} ref={ref}>
        {value}
      </button>
    </div>
  ));

  const handleFileSelected = (e) => {
    var file = e.target.files[0];
    setUploadFile({
      filepath: 'ra-pdf-reports/2023-09-01-ABCD-report.pdf',
      file: file,
    });
  };

  const uploadSelectedFile = (e) => {
    setLoading(true);
    console.log('uploading file:', uploadFile);
    UploadPDFFile(uploadFile.filepath, uploadFile.file);
    setLoading(false);
  };

  const downloadSelectedFile = async (e) => {
    setLoading(true);
    console.log('downloading file:', uploadFile);
    let paths = uploadFile.filepath.split('/');
    await DownloadPDFFile(paths[0], paths[1]);
    setLoading(false);
  };

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loading_animation}>
          <ReactLoading type="bars" color="#FC8802" height="10%" width="10%" className={styles.reactLoadingBars} />
        </div>
      ) : null}

      <div>
        <div className="upload_title">Upload PDF report</div>
        <div className="margined">
          <label className="uploadFileLabel">Select Date: </label>
          <DatePicker
            showIcon={true}
            closeOnScroll={true}
            dateFormat="dd/MM/yyyy"
            selected={curDate}
            onChange={(date) => console.log(`selected date: ${date}`)}
            showYearDropdown
            scrollableMonthYearDropdown
            customInput={<CalenderInput />}
            wrapperClassName="datePicker"
          />
        </div>
        <div className="margined">
          <label className="uploadFileLabel">Select File: </label>
          <input className="fileChooseResponse" type="file" accept=".pdf" onChange={(e) => handleFileSelected(e)}></input>
        </div>
        {/* {uploadFile.file && (
          <label>File to be uploaded: {uploadFile.filepath}</label>
        )} */}
        <div className="margined">
          <button className="uploadSelectedFile" onClick={(e) => uploadSelectedFile(e)}>
            Upload
          </button>
        </div>
        <div className="margined">
          <button className="downloadSelectedFile" onClick={(e) => downloadSelectedFile(e)}>
            Download
          </button>
        </div>
      </div>
    </div>
  );
};

export default DataBoard;
