import { CUBEJS_API } from '../../config/Api';
import cubejs from '@cubejs-client/core';
import { toast } from 'react-toastify';

function cubeFunction() {
  var cubeJsToken = window.localStorage.getItem('cmn_cubeJs_token');
  var cubejsAPIFunction = cubejs(cubeJsToken, {
    apiUrl: CUBEJS_API,
  });
  return cubejsAPIFunction;
}

function handleError(error) {
  console.log(error);
  if (error.response.error) {
    console.log(error.response.error);
    toast.error(error.response.error);
  }
  return error;
}

//RA-ATTEN-01 - Attendance page - Box data
export async function fetchAttendanceBox(filters) {
  const cubejsApi = cubeFunction();
  const qAttandanceBox = {
    measures: ['Assessment.count', 'AssessmentResponse.questionsCount'],
    timeDimensions: [
      {
        dimension: 'AssessmentResponse.assessmentDate',
      },
    ],
    dimensions: ['AssessmentOrganizer.name'],
    order: {
      'Assessment.count': 'desc',
    },
    filters: filters,
  };

  try {
    const result = await cubejsApi.load(qAttandanceBox);
    return result;
  } catch (error) {
    handleError(error);
  }
}

//RA-ATTEN-02 - Attendance page - table data
export async function fetchAttendanceData(filters, filterPrevDate, filterNextDate) {
  const cubejsApi = cubeFunction();
  const qAttandanceData = {
    measures: [
      'AssessmentResponse.questionsCount',
      'AssessmentResponse.attendancePresent',
      'AssessmentResponse.attendanceTotal',
      'AssessmentResponse.assessmentCount',
      'AssessmentResponse.avgVelocity',
    ],
    dimensions: ['StudyCircle.name', 'Subject.name', 'AssessmentOrganizer.name', 'AssessmentType.name'],
    order: {
      'Assessment.count': 'desc',
    },
    timeDimensions: [
      {
        dateRange: [`${filterPrevDate}`, `${filterNextDate}`],
        dimension: 'AssessmentResponse.assessmentDate',
      },
    ],
    filters: filters,
  };

  try {
    const startTime = Date.now();
    const result = await cubejsApi.load(qAttandanceData);
    const endTime = Date.now();
    const timeTaken = endTime - startTime;
    console.log('Time taken for fetchAttendanceData', timeTaken);
    // console.log("result inside RA-ATTEN-02 - Attendance page - table data", result)
    return result?.loadResponse?.results[0]?.data;
  } catch (error) {
    handleError(error);
  }
}
