// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AccuracyChartStyle_container__2rM78 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  align-content: center;\n  width: 100%;\n}\n@media only screen and (max-width: 1090px) {\n  .AccuracyChartStyle_container__2rM78 {\n    margin-right: -30px;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"container": "AccuracyChartStyle_container__2rM78"
};
module.exports = exports;
