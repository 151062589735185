import { getStudentWiseResultAPI } from '../../../../api/cube/sessionAPI';

export async function getStudentWiseResult(assessmentId, questionId, institutionId, startDate, endDate) {
  const result = await getStudentWiseResultAPI(assessmentId, questionId, institutionId, startDate, endDate);
  // console.log("result for getStudentWiseResultAPI inside getStudentWiseResult", result);
  const resultArray = result.loadResponse.results?.[0].data;
  const studentWiseCount = [0, 0, 0, 0];
  resultArray.forEach(function (obj) {
    if (obj['AssessmentResponse.result'] === 'correct') {
      studentWiseCount[0] = studentWiseCount[0] + 1;
    }
    if (obj['AssessmentResponse.result'] === 'near correct') {
      studentWiseCount[1] = studentWiseCount[1] + 1;
    }
    if (obj['AssessmentResponse.result'] === 'incorrect') {
      studentWiseCount[2] = studentWiseCount[2] + 1;
    }
    if (
      obj['AssessmentResponse.result'] === 'absent' ||
      obj['AssessmentResponse.result'] === 'not answered' ||
      obj['AssessmentResponse.result'] === 'not_answered'
    ) {
      studentWiseCount[3] = studentWiseCount[3] + 1;
    }
  });
  const resultObject = {
    studentWiseResultArray: resultArray,
    count: studentWiseCount,
  };
  return resultObject;
}
