/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styles from './PerformanceSubjectDetails.module.css';
import { GridContainer } from '../../../components/grid/index';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  getAssessmentType,
  getModes,
  getSubjectStudy,
  getSubSubject,
  getTopic,
  getSubTopic,
  getObjective,
} from '../../../api/masterReadAnalytics/dropdownAPI';
import { fetchPerfReadVelocityHeaderData, sessionHeaderFetch } from '../../../api/cube/performanceAPI';
import { fetchAllPerformanceDetails } from '../../../api/cube/combineAPI';
import { fetchPerformanceTrendChart } from '../../../api/cube/performanceTrendChart';
import {
  createPerformanceFilter,
  createDiffucltySummaryFilter,
  createGrpRankPercentileFilter,
  createPerformanceDetailFetchFilter,
} from '../helperFunctions/createPerformanceFilter';
import { performanceScoreGraph } from '../helperFunctions/performanceScoreGraph';
// import { performanceScoreGraph } from "./PerformanceDetailsComponents/scoreGraphPerf";
import { difficultySummary } from '../helperFunctions/difficultySummary';
// import { performanceSpeed } from '../helperFunctions/performanceSpeed';
import { whichTimeFrame } from '../../../components/whichTImeFrame/whichTimeFrame';
import { fetchAcademicPeriod } from '../../../utils/fetchAcademicPeriod';
import LoadingBars from '../../../components/LoadingBars/LoadingBars';
// import HandRisePopup from '../../../components/HandRisePopup/HandRisePopup';
import PerformanceCalendarSection from '../../../components/PerformanceCalendarSection/PerformanceCalendarSection';
import PerformanceDetailsDropdown from '../../../components/PerformanceDetailsDropdown/PerformanceDetailsDropdown';
import PerformanceReadVelocityHeader from '../../../components/PerformanceReadVelocityHeader/PerformanceReadVelocityHeader';
import PerformanceDifficultyChart from '../../../components/PerformanceDifficultyChart/PerformanceDifficultyChart';
import PerformanceAccuracySpeedometer from '../../../components/PerformanceAccuracySpeedometer/PerformanceAccuracySpeedometer';
import PerformanceScoreBar from '../../../components/PerformanceScoreBar/PerformanceScoreBar';
import PerformanceTimeBar from '../../../components/PerformanceTimeBar/PerformanceTimeBar';
import PerformanceTrendSection from '../../../components/PerformanceTrendSection/PerformanceTrendSection';
import PerformanceHandRiseSection from '../../../components/PerformanceHandriseSection/PerformanceHandRiseSection';
import promiseAllCall from '../../../utils/promise';

const PerformanceSubjectDetail = () => {
  const calendarDate =
    localStorage.getItem('oncalendardate') === null ? new Date() : new Date(localStorage.getItem('oncalendardate'));
  let month = calendarDate.getMonth();
  let today = calendarDate.getDate();
  let year = calendarDate.getFullYear();
  const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };
  var totaldaysInMonth = getDaysInMonth(month, year);
  const timeFrame = localStorage.getItem('timeFrame');
  const academicPeriodArr = window.localStorage.getItem('instAcademicPeriods');
  const sortedAcademicPerArr =
    academicPeriodArr?.length > 0 &&
    [...JSON.parse(academicPeriodArr)].sort(
      (a, b) => new Date(moment(a.start_date).format('YYYY-MM-DD')) - new Date(moment(b.start_date).format('YYYY-MM-DD')),
    );

  const academicStartDate = sortedAcademicPerArr?.length > 0 && new Date(sortedAcademicPerArr[0].start_date);
  const academicEndDate =
    sortedAcademicPerArr?.length > 0 && new Date(sortedAcademicPerArr[sortedAcademicPerArr.length - 1].end_date);
  const academic_period = JSON.parse(localStorage.getItem('academic_period'));
  const filterData = JSON.parse(localStorage.getItem('performanceTableFilterObj'));
  const performanceData = JSON.parse(window.localStorage.getItem('performanceData'));
  var prevDate = performanceData.prevDate;
  var nextDate = performanceData.nextDate;
  let filter = [],
    score_graph_values = {};

  const [filterPrevDate, setFilterPrevDate] = useState(moment(prevDate).format('YYYY-MM-DD'));
  const [filterNextDate, setFilterNextDate] = useState(moment(nextDate).format('YYYY-MM-DD'));
  const [isLoading, setIsLoading] = useState(false);
  const [states, setStates] = useState({
    isMonth: timeFrame ? (timeFrame == 'month' ? true : false) : true,
    isDay: timeFrame == 'day' ? true : false,
    isYear: timeFrame == 'year' ? true : false,
    t_body: [],
    modeOptions: [],
    typeOptions: [],
    topicOptions: [],
    subTopicOptions: [],
    subjectOptions: [],
    subSubjectOptions: [],
    objectiveOptions: [],
  });
  const [startdate, setStartDate] = useState(calendarDate);
  const [selectedValues, setSelectedValues] = useState({
    selectedMode: performanceData?.assessmentModeId ? performanceData?.assessmentModeId : -1,
    selectedType: performanceData?.assessmentTypeId ? performanceData?.assessmentTypeId : -1,
    selectedTopic: -1,
    selectedSubTopic: -1,
    selectedSubject: performanceData?.subjectId ? performanceData?.subjectId : -1,
    selectedSubSubject: -1,
    selectedObjectives: -1,
  });
  const [sessionData, setSessionData] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState('-1');
  const [cubeData, setCubeData] = useState({
    readVelocity: 0,
    grpReadVelocity: 0,
    groupPercentile: 0,
    trend: -1,
    difficultySum: {
      difficulty: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      correct: [30, 40, 80, 60, 80, 50, 40, 20, 30, 60],
      nearCorrect: [30, 10, 7, 0, 12, 50, 45, 40, 10, 15],
      incorrect: [10, 30, 7, 40, 8, 0, 10, 0, 40, 20],
      notAnswered: [30, 20, 6, 0, 0, 0, 5, 40, 20, 5],
      totalValue: [10, 7, 6, 5, 10, 9, 5, 4, 2, 8],
    },
    accuracy: 0,
    grpAccuracy: 0,
    score: 0,
    grpScore: 0,
    scoreGraph: {
      correct: 25,
      incorrect: 25,
      nearCorrect: 25,
      notAnswered: 25,
      total: 100,
      correctPer: '25%',
      incorrectPer: '25%',
      nearCorrectPer: '25%',
      notAnsweredPer: '25%',
    },
    timeMark: [
      { value: 0, label: '0' },
      { value: 60, label: '60' },
    ],
    time: 0,
    grpTime: 0,
    trendData: [],
    accuracyPerformanceTrend: '1',
    scorePerformanceTrend: '1',
    timeTakenPerformanceTrend: '1',
  });
  const [trendStartDate, setTrendStartDate] = useState(new Date(moment().subtract(7, 'days').format('YYYY-MM-DD')));
  const [trendEndDate, setTrendEndDate] = useState(new Date(moment().format('YYYY-MM-DD')));
  const [performanceHandriseValues, setPerformanceHandriseValues] = useState({
    totalHandrise: 0,
    uniqueStudent: 0,
    avgHandRaisePerMin: 0,
  });
  const [academicPeriodId, setAcademicPeriodId] = useState(-1);
  const [subjectName, setSubjectName] = useState(performanceData?.subjectName);
  const studyCircleName = performanceData.studyCircleName;
  // const calendarSel = performanceData.calendarSel;
  const institutionId = window.localStorage.getItem('cmn_school_id');
  const studyCircleId = window.location.pathname.slice(19);
  const academicPeriodsList = JSON.parse(localStorage.getItem('instAcademicPeriods'));
  const stylesss = {
    monthAct: {
      backgroundColor: '#FD8500',
      color: '#fff',
      fontWeight: 500,
    },
    dayAct: {
      backgroundColor: '#FD8500',
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
      color: '#fff',
      fontWeight: 500,
    },
    yearAct: {
      backgroundColor: '#FD8500',
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
      color: '#fff',
      fontWeight: 500,
    },
  };
  const scores = [
    {
      ranks: [96, 33.4, 11.1, 36.3, 19.2],
    },
    {
      test: ['questions', 'correct', 'near correct', 'incorrect', 'not answered'],
    },
  ];

  useEffect(() => {
    const fetchInitialValues = async () => {
      try {
        const callAll = await promiseAllCall([
          getModes(),
          getAssessmentType(),
          getSubjectStudy(institutionId, studyCircleId),
          getObjective(),
        ]);
        setStates((prevState) => {
          return {
            ...prevState,
            modeOptions: callAll[0]?.data?.data,
            typeOptions: callAll[1]?.data?.data,
            subjectOptions: callAll[2]?.data?.data,
            objectiveOptions: callAll[3]?.data?.data,
          };
        });
        setIsLoading(false);
      } catch (err) {
        console.log('err in PerformanceSubjectDetail', err);
        toast.error('Something went wrong, please try again!');
        setIsLoading(false);
      }
    };
    setIsLoading(true);
    fetchInitialValues();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    states.isYear && fetchAcademicInfo();
  }, [states.isYear]);

  useEffect(() => {
    const fetchDependencies = async () => {
      try {
        const subSubject = await getSubSubject(institutionId, selectedValues.selectedSubject);

        const topic = await getTopic(institutionId, selectedValues.selectedSubject, selectedValues.selectedSubSubject);

        const subTopic = await getSubTopic(
          institutionId,
          selectedValues.selectedSubject,
          selectedValues.selectedSubSubject,
          selectedValues.selectedTopic,
        );
        setStates((prevState) => {
          return {
            ...prevState,
            subSubjectOptions: subSubject?.data?.data,
            topicOptions: topic?.data?.data,
            subTopicOptions: subTopic?.data?.data,
          };
        });
        setIsLoading(false);
      } catch (err) {
        console.log('err in fetchDependencies PerformanceSubjectDetails', err);
        setIsLoading(false);
      }
    };
    setIsLoading(true);
    fetchDependencies();
  }, [selectedValues.selectedSubject, selectedValues.selectedSubSubject, selectedValues.selectedTopic]);

  useEffect(() => {
    const getPerformanceTrendData = async () => {
      try {
        const trendData = await fetchPerformanceTrendChart(
          moment(trendStartDate).format('YYYY-MM-DD'),
          moment(trendEndDate).format('YYYY-MM-DD'),
          institutionId,
          studyCircleId,
        );
        // console.log('trendData fetchPerformanceTrendChart', trendData);
        let data = trendData?.loadResponses?.[0]?.data;
        if (!data || data.length === 0) {
          data = [];
        }
        setCubeData((prevState) => {
          return {
            ...prevState,
            trendData: data,
          };
        });
        setIsLoading(false);
      } catch (err) {
        console.log('err in getPerformanceTrendData', err);
        setIsLoading(false);
      }
    };
    setIsLoading(true);
    getPerformanceTrendData();
    // trendStartDate and trendEndDates are concerned with Performance Details Line Graph
  }, [trendStartDate, trendEndDate]);

  useEffect(() => {
    const fetchDropdownCubeDependencies = async () => {
      filter = createPerformanceFilter(
        studyCircleId,
        selectedValues.selectedMode,
        selectedValues.selectedType,
        selectedValues.selectedTopic,
        selectedValues.selectedSubTopic,
        selectedValues.selectedSubject,
        selectedValues.selectedSubSubject,
        selectedValues.selectedObjectives,
        filterPrevDate, //not used in actual function - createPerformanceFilter
        filterNextDate, //not used in actual function - createPerformanceFilter
      );
      // console.log('filter', filter);
      // console.log('dates sessionHeaderFetch', { filterPrevDate, filterNextDate });
      try {
        const session = await sessionHeaderFetch(filter, filterPrevDate, filterNextDate);
        // console.log('session', session);
        setSessionData(session);
        if (session[0]) {
          const sessGrpId = session[0]['Assessment.groupSessionId'];
          setSelectedGroupId(sessGrpId);
        } else {
          toast.error('No data for the date selected in fetching session data');
        }
        setIsLoading(false);
      } catch (err) {
        console.log('error in fetchDropdownCubeDependencies', err);
        setIsLoading(false);
      }
    };
    setIsLoading(true);
    fetchDropdownCubeDependencies();
    getPerfReadVelocityHeader();
  }, [
    selectedValues.selectedMode,
    selectedValues.selectedType,
    selectedValues.selectedTopic,
    selectedValues.selectedSubTopic,
    selectedValues.selectedSubject,
    selectedValues.selectedSubSubject,
    selectedValues.selectedObjectives,
    filterPrevDate,
    filterNextDate,
  ]);

  useEffect(() => {
    const fetchPerfDetails = async () => {
      const fetchPerformanceFilter = createPerformanceDetailFetchFilter(
        studyCircleId,
        selectedValues.selectedMode,
        selectedValues.selectedType,
        selectedValues.selectedSubject,
        filterData?.organiserId,
        institutionId,
        states,
        academicPeriodId,
      );
      console.log('dates callCubeDependencies', { filterPrevDate, filterNextDate });
      try {
        const callCubeDependencies = await fetchAllPerformanceDetails(
          fetchPerformanceFilter,
          filterPrevDate,
          filterNextDate,
          states,
        );
        console.log('callCubeDependencies', callCubeDependencies);

        if (callCubeDependencies && Object.keys(callCubeDependencies).length) {
          const time = states.isDay
            ? callCubeDependencies['GroupPerformanceRank_Day.avgTimeTaken']
            : states.isMonth
              ? callCubeDependencies['GroupPerformanceRank_Month.avgTimeTaken']
              : states.isYear
                ? callCubeDependencies['GroupPerformanceRank_Year.avgTimeTaken']
                : null;

          const time_mark = [
            { value: 0, label: '0' },
            {
              value: Number(time ? (+time).toFixed(2) : 0),
              label: String(time ? (+time).toFixed(2) : 0),
            },
            { value: 60, label: 60 },
          ];
          score_graph_values['AssessmentResponse.questionsCount'] = states.isDay
            ? Number(callCubeDependencies['GroupPerformanceRank_Day.questionsCount'])
            : states.isMonth
              ? Number(callCubeDependencies['GroupPerformanceRank_Month.questionsCount'])
              : states.isYear
                ? Number(callCubeDependencies['GroupPerformanceRank_Year.questionsCount'])
                : 0;

          score_graph_values['AssessmentResponse.totalCorrects'] = states.isDay
            ? Number(callCubeDependencies['GroupPerformanceRank_Day.totalCorrects'])
            : states.isMonth
              ? Number(callCubeDependencies['GroupPerformanceRank_Month.totalCorrects'])
              : states.isYear
                ? Number(callCubeDependencies['GroupPerformanceRank_Year.totalCorrects'])
                : 0;

          score_graph_values['AssessmentResponse.totalIncorrects'] = states.isDay
            ? Number(callCubeDependencies['GroupPerformanceRank_Day.totalIncorrects'])
            : states.isMonth
              ? Number(callCubeDependencies['GroupPerformanceRank_Month.totalIncorrects'])
              : states.isYear
                ? Number(callCubeDependencies['GroupPerformanceRank_Year.totalIncorrects'])
                : 0;

          score_graph_values['AssessmentResponse.totalNearCorrects'] = states.isDay
            ? Number(callCubeDependencies['GroupPerformanceRank_Day.totalNearCorrects'])
            : states.isMonth
              ? Number(callCubeDependencies['GroupPerformanceRank_Month.totalNearCorrects'])
              : states.isYear
                ? Number(callCubeDependencies['GroupPerformanceRank_Year.totalNearCorrects'])
                : 0;

          score_graph_values['AssessmentResponse.totalNotAnswereds'] = states.isDay
            ? Number(callCubeDependencies['GroupPerformanceRank_Day.totalNotAnswered'])
            : states.isMonth
              ? Number(callCubeDependencies['GroupPerformanceRank_Month.totalNotAnswered'])
              : states.isYear
                ? Number(callCubeDependencies['GroupPerformanceRank_Year.totalNotAnswered'])
                : 0;

          const scoreGraph = await performanceScoreGraph(score_graph_values);

          const marksScored = states.isDay
            ? callCubeDependencies['GroupPerformanceRank_Day.marksScored']
            : states.isMonth
              ? callCubeDependencies['GroupPerformanceRank_Month.marksScored']
              : states.isYear
                ? callCubeDependencies['GroupPerformanceRank_Year.marksScored']
                : null;

          const avgAccuracy = states.isDay
            ? callCubeDependencies['GroupPerformanceRank_Day.avgAccuracy']
            : states.isMonth
              ? callCubeDependencies['GroupPerformanceRank_Month.avgAccuracy']
              : states.isYear
                ? callCubeDependencies['GroupPerformanceRank_Year.avgAccuracy']
                : null;

          const grpAccuracy = states.isDay
            ? callCubeDependencies['GroupPerformanceRank_Day.groupAvgAccuracy']
            : states.isMonth
              ? callCubeDependencies['GroupPerformanceRank_Month.groupAvgAccuracy']
              : states.isYear
                ? callCubeDependencies['GroupPerformanceRank_Year.groupAvgAccuracy']
                : null;

          const groupAvgScore = states.isDay
            ? callCubeDependencies['GroupPerformanceRank_Day.groupAvgScore']
            : states.isMonth
              ? callCubeDependencies['GroupPerformanceRank_Month.groupAvgScore']
              : states.isYear
                ? callCubeDependencies['GroupPerformanceRank_Year.groupAvgScore']
                : null;

          const groupAvgTimeTaken = states.isDay
            ? callCubeDependencies['GroupPerformanceRank_Day.groupAvgTimeTaken']
            : states.isMonth
              ? callCubeDependencies['GroupPerformanceRank_Month.groupAvgTimeTaken']
              : states.isYear
                ? callCubeDependencies['GroupPerformanceRank_Year.groupAvgTimeTaken']
                : null;

          const localaccuracyPerformanceTrend = states.isDay
            ? callCubeDependencies['GroupPerformanceRank_Day.accuracyPerformanceTrend']
            : states.isMonth
              ? callCubeDependencies['GroupPerformanceRank_Month.accuracyPerformanceTrend']
              : states.isYear
                ? callCubeDependencies['GroupPerformanceRank_Year.accuracyPerformanceTrend']
                : null;

          const localscorePerformanceTrend = states.isDay
            ? callCubeDependencies['GroupPerformanceRank_Day.scorePerformanceTrend']
            : states.isMonth
              ? callCubeDependencies['GroupPerformanceRank_Month.scorePerformanceTrend']
              : states.isYear
                ? callCubeDependencies['GroupPerformanceRank_Year.scorePerformanceTrend']
                : null;

          const localtimeTakenPerformanceTrend = states.isDay
            ? callCubeDependencies['GroupPerformanceRank_Day.timeTakenPerformanceTrend']
            : states.isMonth
              ? callCubeDependencies['GroupPerformanceRank_Month.timeTakenPerformanceTrend']
              : states.isYear
                ? callCubeDependencies['GroupPerformanceRank_Year.timeTakenPerformanceTrend']
                : null;

          const trendArrow = states?.isDay
            ? callCubeDependencies['GroupPerformanceRank_Day.performanceTrend']
            : states?.isMonth
              ? callCubeDependencies['GroupPerformanceRank_Month.performanceTrend']
              : states?.isYear
                ? callCubeDependencies['GroupPerformanceRank_Year.performanceTrend']
                : '-1';

          setCubeData((prevState) => {
            return {
              ...prevState,
              accuracy: avgAccuracy ? (+avgAccuracy).toFixed(2) : 0,
              score: marksScored ? (+marksScored).toFixed(2) : 0,
              scoreGraph: scoreGraph,
              time: time ? (+time).toFixed(2) : 0,
              timeMark: time_mark,
              grpAccuracy: grpAccuracy ? (+grpAccuracy).toFixed(2) : 0,
              grpScore: groupAvgScore ? (+groupAvgScore).toFixed(2) : 0,
              grpTime: groupAvgTimeTaken ? (+groupAvgTimeTaken).toFixed(2) : 0,
              accuracyPerformanceTrend: localaccuracyPerformanceTrend,
              timeTakenPerformanceTrend: localtimeTakenPerformanceTrend,
              scorePerformanceTrend: localscorePerformanceTrend,
              trend: trendArrow,
            };
          });

          const handRiseCount = states?.isDay
            ? callCubeDependencies['GroupPerformanceRank_Day.sumHandRaiseCount']
            : states?.isMonth
              ? callCubeDependencies['GroupPerformanceRank_Month.sumHandRaiseCount']
              : states?.isYear
                ? callCubeDependencies['GroupPerformanceRank_Year.sumHandRaiseCount']
                : '0';

          const uniqueStudents = states?.isDay
            ? callCubeDependencies['GroupPerformanceRank_Day.uniqueStudents']
            : states?.isMonth
              ? callCubeDependencies['GroupPerformanceRank_Month.uniqueStudents']
              : states?.isYear
                ? callCubeDependencies['GroupPerformanceRank_Year.uniqueStudents']
                : '0';

          const avgHandRaisePerMin = states?.isDay
            ? callCubeDependencies['GroupPerformanceRank_Day.avgHandRaise']
            : states?.isMonth
              ? callCubeDependencies['GroupPerformanceRank_Month.avgHandRaise']
              : states?.isYear
                ? callCubeDependencies['GroupPerformanceRank_Year.avgHandRaise']
                : '0';

          setPerformanceHandriseValues((prevState) => {
            return {
              ...prevState,
              totalHandrise: handRiseCount,
              uniqueStudent: uniqueStudents,
              avgHandRaisePerMin: avgHandRaisePerMin,
            };
          });
        } else {
          toast.info('No graph data found for selected options.');
        }
        setIsLoading(false);
      } catch (err) {
        console.log('err in PerformanceSubjectDetails,selectedGroupId useEffect', err);
        toast.error('Something went wrong in fetching performance data');
        setIsLoading(false);
      }
    };
    setIsLoading(true);
    fetchPerfDetails();
  }, [
    studyCircleId,
    selectedValues.selectedMode,
    selectedValues.selectedType,
    selectedValues.selectedSubject,
    filterData?.organiserId,
    states.isDay,
    states.isMonth,
    states.isYear,
    academicPeriodId,
    filterPrevDate,
    filterNextDate,
  ]);

  useEffect(() => {
    const fetchDifficultySummary = async () => {
      const difficultySummaryFilter = createDiffucltySummaryFilter(
        studyCircleId,
        selectedValues.selectedMode,
        selectedValues.selectedType,
        selectedValues.selectedSubject,
        filterData?.organiserId,
        institutionId,
      );

      try {
        const summary = await difficultySummary(difficultySummaryFilter, filterPrevDate, filterNextDate);
        setCubeData((prevState) => {
          return { ...prevState, difficultySum: summary };
        });
        setIsLoading(false);
      } catch (err) {
        console.log('Error in fetching difficulty chart data', err);
        setIsLoading(false);
      }
    };
    setIsLoading(true);
    fetchDifficultySummary();
  }, [
    selectedValues.selectedMode,
    selectedValues.selectedType,
    selectedValues.selectedSubject,
    selectedValues.selectedSubSubject,
    selectedValues.selectedTopic,
    selectedValues.selectedSubTopic,
    selectedValues.selectedObjectives,
    filterPrevDate,
    filterNextDate,
  ]);

  const fetchAcademicInfo = async () => {
    try {
      const boardId = JSON.parse(localStorage.getItem('board_info'))?.board_id;
      const result = await fetchAcademicPeriod(moment(startdate).format('YYYY-MM-DD'), boardId);
      localStorage.setItem('academic_period', JSON.stringify(result));
      setAcademicPeriodId(result?.academic_period_id);
      setFilterPrevDate(moment(result?.start_date).format('YYYY-MM-DD'));
      setFilterNextDate(moment(result?.end_date).format('YYYY-MM-DD'));
      setIsLoading(false);
      return result;
    } catch (err) {
      console.log('err in fetchAcademicInfo', err);
      toast.error('Error in fetching Academic Info');
      setIsLoading(false);
    }
  };

  const getPerfReadVelocityHeader = async () => {
    const filter = createGrpRankPercentileFilter(
      studyCircleId,
      selectedValues.selectedMode,
      selectedValues.selectedType,
      selectedValues.selectedSubject,
      filterData?.organiserId,
      institutionId,
      states,
      academicPeriodId,
    );
    try {
      const headerData = await fetchPerfReadVelocityHeaderData(filterPrevDate, filterNextDate, states, filter);
      // console.log('headerData', headerData);

      if (headerData && Object.keys(headerData).length) {
        const grpPercentile = states?.isDay
          ? headerData['GroupPerformanceRank_Day.groupPercentile']
          : states?.isMonth
            ? headerData['GroupPerformanceRank_Month.groupPercentile']
            : states?.isYear
              ? headerData['GroupPerformanceRank_Year.groupPercentile']
              : 0;
        const readVel = states?.isDay
          ? headerData['GroupPerformanceRank_Day.avgVelocity']
          : states?.isMonth
            ? headerData['GroupPerformanceRank_Month.avgVelocity']
            : states?.isYear
              ? headerData['GroupPerformanceRank_Year.avgVelocity']
              : 0;
        const grpReadVel = states?.isDay
          ? headerData['GroupPerformanceRank_Day.groupAvgVelocity']
          : states?.isMonth
            ? headerData['GroupPerformanceRank_Month.groupAvgVelocity']
            : states?.isYear
              ? headerData['GroupPerformanceRank_Year.groupAvgVelocity']
              : 0;
        if (Object.keys(headerData).length) {
          setCubeData((prevState) => {
            return {
              ...prevState,
              groupPercentile: Number(grpPercentile)?.toFixed(2),
              readVelocity: Number(readVel)?.toFixed(2),
              grpReadVelocity: Number(grpReadVel)?.toFixed(2),
            };
          });
        }
      }
      setIsLoading(false);
    } catch (err) {
      console.log('error in getPerfReadVelocityHeader', err);
      setIsLoading(false);
    }
  };

  const handleChange = async (date, arrowdate) => {
    if (arrowdate === 'prev') {
      let currentdate = new Date(date);
      let previousday = new Date(currentdate.getTime() - 24 * 60 * 60 * 1000);
      let today = states.isDay ? previousday.getDate() : currentdate.getDate();
      let month = states.isMonth ? (currentdate.getMonth() === 0 ? 11 : currentdate.getMonth() - 1) : previousday.getMonth();
      let year = states.isYear
        ? currentdate.getFullYear() - 1
        : currentdate.getMonth() === 0
          ? currentdate.getFullYear() - 1
          : previousday.getFullYear();

      const checkMinDate =
        new Date(moment(academicStartDate).format('YYYY-MM-DD')) <=
        new Date(moment(new Date(year, month, today)).format('YYYY-MM-DD'));
      const checkMaxDate =
        new Date(moment(academicEndDate).format('YYYY-MM-DD')) >=
        new Date(moment(new Date(year, month, today)).format('YYYY-MM-DD'));

      if (checkMinDate && checkMaxDate) {
        setStartDate(new Date(year, month, today));
        whichTimeFrame(year, month, today, states);
        prevDate = states.isDay
          ? `${year}-${month + 1}-${today}`
          : states.isMonth
            ? `${year}-${month + 1}-01`
            : states.isYear
              ? `${moment(academic_period.start_date).format('YYYY-MM-DD')}`
              : null;

        nextDate = states.isDay
          ? `${year}-${month + 1}-${today}`
          : states.isMonth
            ? year + '-' + (month + 1) + '-' + totaldaysInMonth
            : states.isYear
              ? `${moment(academic_period.end_date).format('YYYY-MM-DD')}`
              : null;
        console.log('prev btn dates', { prevDate, nextDate });
        setFilterPrevDate(moment(prevDate).format('YYYY-MM-DD'));
        setFilterNextDate(moment(nextDate).format('YYYY-MM-DD'));
      } else {
        toast.info(
          `Please select the dates between ${moment(academicStartDate).format('MMMM Do YYYY')} TO ${moment(academicEndDate).format('MMMM Do YYYY')} for accessing the data`,
        );
      }
    } else if (arrowdate === 'next') {
      let currentdate = new Date(date);
      let previousday = new Date(currentdate.getTime() + 24 * 60 * 60 * 1000);
      let today = states.isDay ? previousday.getDate() : currentdate.getDate();
      let month = states.isMonth ? (currentdate.getMonth() === 11 ? 0 : currentdate.getMonth() + 1) : previousday.getMonth();
      let year = states.isYear
        ? currentdate.getFullYear() + 1
        : currentdate.getMonth() === 11
          ? currentdate.getFullYear() + 1
          : previousday.getFullYear();

      const checkMinDate =
        new Date(moment(academicStartDate).format('YYYY-MM-DD')) <=
        new Date(moment(new Date(year, month, today)).format('YYYY-MM-DD'));
      const checkMaxDate =
        new Date(moment(academicEndDate).format('YYYY-MM-DD')) >=
        new Date(moment(new Date(year, month, today)).format('YYYY-MM-DD'));

      if (checkMinDate && checkMaxDate) {
        setStartDate(new Date(year, month, today));
        whichTimeFrame(year, month, today, states);

        prevDate = states.isDay
          ? `${year}-${month + 1}-${today}`
          : states.isMonth
            ? `${year}-${month + 1}-01`
            : states.isYear
              ? `${moment(academic_period.start_date).format('YYYY-MM-DD')}`
              : null;

        nextDate = states.isDay
          ? `${year}-${month + 1}-${today}`
          : states.isMonth
            ? year + '-' + (month + 1) + '-' + totaldaysInMonth
            : states.isYear
              ? `${moment(academic_period.end_date).format('YYYY-MM-DD')}`
              : null;

        // console.log('next btn dates', { prevDate, nextDate });
        setFilterPrevDate(moment(prevDate).format('YYYY-MM-DD'));
        setFilterNextDate(moment(nextDate).format('YYYY-MM-DD'));
      } else {
        toast.info(
          `Please select the dates between ${moment(academicStartDate).format('MMMM Do YYYY')} TO ${moment(academicEndDate).format('MMMM Do YYYY')} for accessing the data`,
        );
      }
    } else if (arrowdate === 'calander') {
      const checkMinDate = new Date(moment(academicStartDate).format('YYYY-MM-DD')) <= new Date(moment(date).format('YYYY-MM-DD'));
      const checkMaxDate = new Date(moment(academicEndDate).format('YYYY-MM-DD')) >= new Date(moment(date).format('YYYY-MM-DD'));

      if (checkMinDate && checkMaxDate) {
        setStartDate(date);
        let date_val = new Date(date);
        localStorage.setItem('oncalendardate', date_val);
        month = date_val.getMonth();
        today = date_val.getDate();
        year = date_val.getFullYear();

        prevDate = states.isDay
          ? `${year}-${month + 1}-${today}`
          : states.isMonth
            ? `${year}-${month + 1}-01`
            : states.isYear
              ? // ? `${year}-12-31`
                `${moment(academic_period.start_date).format('YYYY-MM-DD')}`
              : null;

        nextDate = states.isDay
          ? `${year}-${month + 1}-${today}`
          : states.isMonth
            ? year + '-' + (month + 1) + '-' + totaldaysInMonth
            : states.isYear
              ? // ? `${year}-12-31`
                `${moment(academic_period.end_date).format('YYYY-MM-DD')}`
              : null;

        setFilterPrevDate(moment(prevDate).format('YYYY-MM-DD'));
        setFilterNextDate(moment(nextDate).format('YYYY-MM-DD'));
      } else {
        toast.info(
          `Please select the dates between ${moment(academicStartDate).format('MMMM Do YYYY')} TO ${moment(academicEndDate).format('MMMM Do YYYY')} for accessing the data`,
        );
      }
    }
  };

  const handleClick = async (values) => {
    // setIsLoading(true);
    if (values === 'd') {
      prevDate = `${year}-${month + 1}-${today}`;
      nextDate = year + '-' + (month + 1) + '-' + today;
      setFilterPrevDate(moment(prevDate).format('YYYY-MM-DD'));
      setFilterNextDate(moment(nextDate).format('YYYY-MM-DD'));
    } else if (values === 'm') {
      prevDate = `${year}-${month + 1}-01`;
      nextDate = year + '-' + (month + 1) + '-' + totaldaysInMonth;
      setFilterPrevDate(moment(prevDate).format('YYYY-MM-DD'));
      setFilterNextDate(moment(nextDate).format('YYYY-MM-DD'));
    } else if (values === 'y') {
      // prevDate = `${year}-01-01`;
      // nextDate = `${year}-12-31`;

      prevDate = moment(academic_period.start_date).format('YYYY-MM-DD');
      nextDate = moment(academic_period.end_date).format('YYYY-MM-DD');
    }
    setStates({
      ...states,
      isDay: values === 'd' ? true : false,
      isMonth: values === 'm' ? true : false,
      isYear: values === 'y' ? true : false,
    });
    whichTimeFrame(year, month, today, {
      isDay: values === 'd' ? true : false,
      isMonth: values === 'm' ? true : false,
      isYear: values === 'y' ? true : false,
    });
  };

  const changeMode = (e, name) => {
    const selectedVal = e.target.value;
    if (name === 'mode') {
      setSelectedValues((prevState) => {
        return { ...prevState, selectedMode: selectedVal };
      });
    } else if (name === 'type') {
      setSelectedValues((prevState) => {
        return { ...prevState, selectedType: selectedVal };
      });
    } else if (name === 'subject') {
      setSelectedValues((prevState) => {
        return { ...prevState, selectedSubject: selectedVal };
      });
      const subObj = states?.subjectOptions?.find((ele) => ele?.subject_id == selectedVal);
      subObj?.subject && setSubjectName(subObj.subject);
    } else if (name === 'subSubject') {
      setSelectedValues((prevState) => {
        return { ...prevState, selectedSubSubject: selectedVal };
      });
    } else if (name === 'topic') {
      setSelectedValues((prevState) => {
        return { ...prevState, selectedTopic: selectedVal };
      });
    } else if (name === 'subTopic') {
      setSelectedValues((prevState) => {
        return { ...prevState, selectedSubTopic: selectedVal };
      });
    } else if (name === 'objectives') {
      setSelectedValues((prevState) => {
        return { ...prevState, selectedObjectives: selectedVal };
      });
    } else if (name === 'academic_period') {
      setAcademicPeriodId(e.target.value);
    } else return null;
  };

  const handleDateChange = async (date) => {
    // console.log("date", moment(date).format("YYYY-MM-DD"));
    setTrendStartDate(date);
  };

  const handleEndDateChange = async (date) => {
    // console.log("date", moment(date).format("YYYY-MM-DD"));
    setTrendEndDate(date);
  };

  const handleStartDateChange = async (n) => {
    let date = moment(trendStartDate).add(n, 'days');
    setTrendStartDate(new Date(date.format('YYYY-MM-DD')));
    let endDate = moment(trendEndDate).add(n, 'days');
    setTrendEndDate(new Date(endDate.format('YYYY-MM-DD')));
  };

  return (
    <>
      {isLoading ? <LoadingBars /> : null}
      <PerformanceCalendarSection
        studyCircleName={studyCircleName}
        handleChange={handleChange}
        handleClick={handleClick}
        startdate={startdate}
        states={states}
        stylesss={stylesss}
        styles={styles}
        changeMode={changeMode}
        academicPeriodsList={academicPeriodsList}
        academicPeriodId={academicPeriodId}
      />
      <div style={{ marginTop: '20px' }}>
        <GridContainer>
          <div className={styles.select_div}>
            <div className={styles.select_div_sel}>
              <PerformanceDetailsDropdown
                styles={styles}
                displayText={'All Mode'}
                modeslabel={'mode'}
                name={'mode'}
                changeMode={changeMode}
                options={states.modeOptions}
                value={selectedValues.selectedMode}
                selectedId={'assessment_mode_id'}
              />
              <PerformanceDetailsDropdown
                styles={styles}
                displayText={'All Type'}
                modeslabel={'Type'}
                name={'type'}
                changeMode={changeMode}
                options={states.typeOptions}
                value={selectedValues.selectedType}
                selectedId={'assessment_type_id'}
              />
              <PerformanceDetailsDropdown
                styles={styles}
                displayText={'All Subjects'}
                modeslabel={'Subject'}
                name={'subject'}
                changeMode={changeMode}
                options={states.subjectOptions}
                value={selectedValues.selectedSubject}
                selectedId={'subject_id'}
                displayName={'subject'}
              />
              <PerformanceDetailsDropdown
                styles={styles}
                displayText={'All Sub-Subject'}
                modeslabel={'Sub Subject'}
                name={'subSubject'}
                changeMode={changeMode}
                options={states.subSubjectOptions}
                value={selectedValues.selectedSubSubject}
                selectedId={'sub_subject_id'}
                displayName={'sub_subject_name'}
                disable={true}
              />
              <PerformanceDetailsDropdown
                styles={styles}
                displayText={'All Topic'}
                modeslabel={'Topic'}
                name={'topic'}
                changeMode={changeMode}
                options={states.topicOptions}
                value={selectedValues.selectedTopic}
                selectedId={'topic_id'}
                displayName={'topic_name'}
                disable={true}
              />
              <PerformanceDetailsDropdown
                styles={styles}
                displayText={'All Sub Topic'}
                modeslabel={'Sub Topic'}
                name={'subTopic'}
                changeMode={changeMode}
                options={states.subTopicOptions}
                value={selectedValues.selectedSubTopic}
                selectedId={'sub_topic_id'}
                displayName={'sub_topic_name'}
                disable={true}
              />
              <PerformanceDetailsDropdown
                styles={styles}
                displayText={'All Objectives'}
                modeslabel={'Objective'}
                name={'objectives'}
                changeMode={changeMode}
                options={states.objectiveOptions}
                value={selectedValues.selectedObjectives}
                selectedId={'objective_id'}
                displayName={'objective'}
                disable={true}
              />
            </div>
          </div>
        </GridContainer>
      </div>
      <div style={{ marginTop: '10px', paddingTop: '10px' }}>
        <GridContainer
          style={{
            backgroundColor: '#ffffff',
            boxShadow: '5px 5px 10px 0 rgba(0, 0, 0, 0.1)',
          }}>
          <PerformanceReadVelocityHeader
            styles={styles}
            setSelectedGroupId={setSelectedGroupId}
            studyCircleId={studyCircleId}
            sessionData={sessionData}
            cubeData={cubeData}
            subjectName={subjectName}
          />
          <div className={styles.Acc}>
            <PerformanceDifficultyChart styles={styles} cubeData={cubeData} />
            <PerformanceAccuracySpeedometer styles={styles} cubeData={cubeData} />
          </div>
          <div className={styles.Acc}>
            <PerformanceScoreBar styles={styles} cubeData={cubeData} scores={scores} />
            <PerformanceTimeBar
              styles={styles}
              time={cubeData.time}
              groupTime={cubeData.grpTime}
              timeMark={cubeData.timeMark}
              trend={cubeData.timeTakenPerformanceTrend}
            />
          </div>
        </GridContainer>
      </div>
      <div style={{ marginTop: '20px' }}>
        <GridContainer>
          <div className={styles.last_div}>
            <PerformanceTrendSection
              cubeData={cubeData}
              trendStartDate={trendStartDate}
              trendEndDate={trendEndDate}
              handleDateChange={handleDateChange}
              handleEndDateChange={handleEndDateChange}
              handleStartDateChange={handleStartDateChange}
              styles={styles}
            />
            <PerformanceHandRiseSection styles={styles} performanceHandriseValues={performanceHandriseValues} />
          </div>
        </GridContainer>
      </div>
    </>
  );
};

export default PerformanceSubjectDetail;
