/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styles from './Attendance.module.css';
import { GridContainer, GridItem } from '../../components/grid/index';
import { filterBuildAttendance } from './filterBuildAttendance';
import {
  getModes,
  getAssessmentType,
  getOrganiser,
  getStudyCircle,
  getSubjectStudy,
} from '../../api/masterReadAnalytics/dropdownAPI';
import { fetchAttendanceData } from '../../api/cube/attendanceAPI';
import { fetchAcademicPeriod } from '../../utils/fetchAcademicPeriod';
import { whichTimeFrame } from '../../components/whichTImeFrame/whichTimeFrame';
import { toast } from 'react-toastify';
import promiseAllCall from '../../utils/promise';
import AttendenceDropdown from '../../components/AttendenceDropdown/AttendenceDropdown';
import AttendenceCalendarSection from '../../components/AttendenceCalendarSection/AttendenceCalendarSection';
import AttendenceTable from '../../components/AttendenceTable/AttendenceTable';
import moment from 'moment';

const Attendance = () => {
  const calendarDate =
    localStorage.getItem('oncalendardate') === null ? new Date() : new Date(localStorage.getItem('oncalendardate'));

  let month = calendarDate.getMonth();
  let today = calendarDate.getDate();
  let year = calendarDate.getFullYear();
  const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };
  let totaldaysInMonth = getDaysInMonth(month + 1, year);
  let timeFrame = window.localStorage.getItem('timeFrame');
  let prevDate,
    nextDate,
    filters = [];
  if (timeFrame) {
    if (timeFrame === 'month') {
      prevDate = `${year}-${month + 1}-01`;
      nextDate = year + '-' + (month + 1) + '-' + totaldaysInMonth;
    }
    if (timeFrame === 'day') {
      prevDate = year + '-' + (month + 1) + '-' + today;
      nextDate = year + '-' + (month + 1) + '-' + today;
    }
    if (timeFrame === 'year') {
      prevDate = year + '-' + 1 + '-' + 1;
      nextDate = year + '-' + 12 + '-' + 31;
    }
  } else {
    prevDate = `${year}-${month + 1}-01`;
    nextDate = year + '-' + (month + 1) + '-' + totaldaysInMonth;
  }

  const institutionId = window.localStorage.getItem('cmn_school_id');
  const academicPeriodArr = window.localStorage.getItem('instAcademicPeriods');
  const sortedAcademicPerArr =
    academicPeriodArr?.length > 0 &&
    [...JSON.parse(academicPeriodArr)].sort(
      (a, b) => new Date(moment(a.start_date).format('YYYY-MM-DD')) - new Date(moment(b.start_date).format('YYYY-MM-DD')),
    );
  const academicStartDate = sortedAcademicPerArr?.length > 0 && new Date(sortedAcademicPerArr[0].start_date);
  const academicEndDate =
    sortedAcademicPerArr?.length > 0 && new Date(sortedAcademicPerArr[sortedAcademicPerArr.length - 1].end_date);
  const academic_period = JSON.parse(localStorage.getItem('academic_period'));

  const [academicPeriodId, setAcademicPeriodId] = useState(-1);
  const [options, setOptions] = useState({
    modes: [],
    organizers: [],
    assessmentType: [],
    studyCircleList: [],
    subjects: [],
  });
  const [selectedValues, setSelectedValues] = useState({
    modeId: -1,
    organizerId: -1,
    assessmentTypeId: -1,
    studyCircleId: -1,
    subjectId: -1,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [startdate, setStartDate] = useState(calendarDate);
  const [date, setDate] = useState('');
  const [states, setStates] = useState({
    isMonth: timeFrame ? (timeFrame == 'month' ? true : false) : true,
    isDay: timeFrame == 'day' ? true : false,
    isYear: timeFrame == 'year' ? true : false,
    message: '',
    t_head: ['Study Circle', 'Subject', 'Organizer', 'Type', 'Conducted', 'Questions', 'Attendance', 'Read Velocity'],
  });
  const [cubeData, setCubeData] = useState({
    tableData: [],
  });
  const [filterPrevDate, setFilterPrevDate] = useState(moment(prevDate).format('YYYY-MM-DD'));
  const [filterNextDate, setFilterNextDate] = useState(moment(nextDate).format('YYYY-MM-DD'));
  // const [filterPrevDate, setFilterPrevDate] = useState(moment(academic_period.start_date).format('YYYY-MM-DD'));
  // const [filterNextDate, setFilterNextDate] = useState(moment(academic_period.end_date).format('YYYY-MM-DD'));

  useEffect(() => {
    const fetchDropdowns = async () => {
      try {
        const callAllDropdowns = await promiseAllCall([
          getModes(),
          getOrganiser(),
          getAssessmentType(),
          getStudyCircle(institutionId),
        ]);
        // console.log("callAllDropdowns", callAllDropdowns);
        setOptions((prevState) => {
          return {
            ...prevState,
            modes: callAllDropdowns[0]?.data?.data,
            organizers: callAllDropdowns[1]?.data?.data,
            assessmentType: callAllDropdowns[2]?.data?.data,
            studyCircleList: callAllDropdowns[3]?.data?.data,
          };
        });
        // setIsLoading(false);
        // console.log("isLoading after try fetchDropdowns", isLoading);
      } catch (error) {
        console.log('error in fetching initial dropdowns at Attendence page', error);
        if (error?.response && error?.response?.data) {
          toast.error(error.response.data.message);
        }
        setIsLoading(false);
        // console.log("isLoading after catch fetchDropdowns", isLoading);
      }
    };
    fetchDropdowns();
  }, []);

  useEffect(() => {
    const fetchDependencies = async () => {
      try {
        const subjects = await getSubjectStudy(institutionId, selectedValues.studyCircleId);
        // console.log("subjects", subjects);
        setOptions((prevState) => {
          return { ...prevState, subjects: subjects?.data?.data };
        });
        // setIsLoading(false);
        // console.log("isLoading after try fetchDependencies", isLoading);
      } catch (error) {
        console.log('error in fetching initial dropdowns at Attendence page', error);
        if (error?.response && error?.response?.data) {
          toast.error(error.response.data.message);
        }
        setIsLoading(false);
        // console.log("isLoading after catch fetchDependencies", isLoading);
      }
    };
    fetchDependencies();
  }, [selectedValues.studyCircleId]);

  useEffect(() => {
    states?.isYear && fetchAcademicInfo();
  }, [states?.isYear]);

  useEffect(() => {
    const fetchTableData = async () => {
      console.log('useEffect val', {
        filterPrevDate,
        filterNextDate,
      });
      filters = filterBuildAttendance(
        institutionId,
        selectedValues.modeId,
        selectedValues.organizerId,
        selectedValues.assessmentTypeId,
        selectedValues.studyCircleId,
        selectedValues.subjectId,
      );
      try {
        const tableData = await fetchAttendanceData(filters, filterPrevDate, filterNextDate);
        // console.log("tableData", tableData);
        setCubeData((prevState) => {
          return { ...prevState, tableData: tableData };
        });
        setIsLoading(false);
        // console.log("isLoading after try fetchTableData", isLoading);
      } catch (error) {
        console.log('error inside fetching attendence tableData', error);
        if (error?.response && error?.response?.data) {
          toast.error(error.response.data.message);
        }
        setIsLoading(false);
        // console.log("isLoading after catch fetchTableData", isLoading);
      }
    };
    // console.log("isLoading before fetchTableData", isLoading);
    setIsLoading(true);
    fetchTableData();
  }, [
    selectedValues.modeId,
    selectedValues.organizerId,
    selectedValues.assessmentTypeId,
    selectedValues.subjectId,
    selectedValues.studyCircleId,
    filterPrevDate,
    filterNextDate,
    date,
  ]);

  const fetchAcademicInfo = async () => {
    const boardId = JSON.parse(localStorage.getItem('board_info'))?.board_id;
    const result = await fetchAcademicPeriod(moment(startdate).format('YYYY-MM-DD'), boardId);
    // console.log('result', result, {
    //   prevDate: moment(result?.start_date).format('YYYY-MM-DD'),
    //   nextDate: moment(result?.end_date).format('YYYY-MM-DD'),
    // });
    setAcademicPeriodId(result?.academic_period_id);
    localStorage.setItem('academic_period', JSON.stringify(result));
    setFilterPrevDate(moment(result?.start_date).format('YYYY-MM-DD'));
    setFilterNextDate(moment(result?.end_date).format('YYYY-MM-DD'));
    return result;
  };

  const changeMode = (e, name) => {
    // console.log(e.target.value, name);
    if (name === 'mode') {
      setSelectedValues((prevState) => {
        return { ...prevState, modeId: e.target.value };
      });
    } else if (name === 'organizer') {
      setSelectedValues((prevState) => {
        return { ...prevState, organizerId: e.target.value };
      });
    } else if (name === 'type') {
      setSelectedValues((prevState) => {
        return { ...prevState, assessmentTypeId: e.target.value };
      });
    } else if (name === 'studyCircle') {
      setSelectedValues((prevState) => {
        return { ...prevState, studyCircleId: e.target.value };
      });
    } else if (name === 'subject') {
      setSelectedValues((prevState) => {
        return { ...prevState, subjectId: e.target.value };
      });
    } else if (name === 'academic_period') {
      setAcademicPeriodId(e.target.value);
      console.log('academicPeriodArr', JSON.parse(academicPeriodArr));
      const findAcademicPeriod = JSON.parse(academicPeriodArr)?.filter((ele) => ele.academic_period_id == e.target.value);
      setFilterPrevDate(moment(findAcademicPeriod[0]?.start_date).format('YYYY-MM-DD'));
      setFilterNextDate(moment(findAcademicPeriod[0]?.end_date).format('YYYY-MM-DD'));
    } else return null;
  };

  // if (timeFrame) {
  //   if (timeFrame === 'month') {
  //     prevDate = `${year}-${month + 1}-01`;
  //     nextDate = year + '-' + (month + 1) + '-' + totaldaysInMonth;
  //   }
  //   if (timeFrame === 'day') {
  //     prevDate = year + '-' + (month + 1) + '-' + today;
  //     nextDate = year + '-' + (month + 1) + '-' + today;
  //   }
  //   if (timeFrame === 'year') {
  //     prevDate = year + '-' + 1 + '-' + 1;
  //     nextDate = year + '-' + 12 + '-' + 31;
  //   }
  // } else {
  //   prevDate = `${year}-${month + 1}-01`;
  //   nextDate = year + '-' + (month + 1) + '-' + totaldaysInMonth;
  // }

  const handleClick = async (values) => {
    if (values === 'd') {
      prevDate = `${year}-${month + 1}-${today}`;
      nextDate = year + '-' + (month + 1) + '-' + today;
      setFilterPrevDate(moment(prevDate).format('YYYY-MM-DD'));
      setFilterNextDate(moment(nextDate).format('YYYY-MM-DD'));
    } else if (values === 'm') {
      prevDate = `${year}-${month + 1}-01`;
      nextDate = year + '-' + (month + 1) + '-' + totaldaysInMonth;
      setFilterPrevDate(moment(prevDate).format('YYYY-MM-DD'));
      setFilterNextDate(moment(nextDate).format('YYYY-MM-DD'));
    } else if (values === 'y') {
      // prevDate = `${year}-01-01`;
      // nextDate = `${year}-12-31`;
      prevDate = moment(academic_period.start_date).format('YYYY-MM-DD');
      nextDate = moment(academic_period.end_date).format('YYYY-MM-DD');
      // console.log("prevDate, nextDate inside handleClick y", { prevDate, nextDate })
    }
    setStates({
      ...states,
      isDay: values === 'd' ? true : false,
      isMonth: values === 'm' ? true : false,
      isYear: values === 'y' ? true : false,
    });
    whichTimeFrame(year, month, today, {
      isDay: values === 'd' ? true : false,
      isMonth: values === 'm' ? true : false,
      isYear: values === 'y' ? true : false,
    });
    setDate(Math.random());
    // changeMode("e", "date");
  };

  const handleChange = async (date, arrowdate) => {
    if (arrowdate === 'prev') {
      let currentdate = new Date(date);
      let previousday = new Date(currentdate.getTime() - 24 * 60 * 60 * 1000);
      let today = states.isDay ? previousday.getDate() : currentdate.getDate();
      let month = states.isMonth ? (currentdate.getMonth() === 0 ? 11 : currentdate.getMonth() - 1) : previousday.getMonth();
      let year = states.isYear
        ? currentdate.getFullYear() - 1
        : currentdate.getMonth() === 0
          ? currentdate.getFullYear() - 1
          : previousday.getFullYear();

      const checkMinDate =
        new Date(moment(academicStartDate).format('YYYY-MM-DD')) <=
        new Date(moment(new Date(year, month, today)).format('YYYY-MM-DD'));
      const checkMaxDate =
        new Date(moment(academicEndDate).format('YYYY-MM-DD')) >=
        new Date(moment(new Date(year, month, today)).format('YYYY-MM-DD'));
      // console.log("PREV date onChange comparisions", { checkMinDate, checkMaxDate })

      if (checkMinDate && checkMaxDate) {
        setStartDate(new Date(year, month, today));
        whichTimeFrame(year, month, today, states);
        // eslint-disable-next-line
        {
          /* prevDate = states.isDay
          ? `${year}-${month + 1}-${today}`
          : states.isMonth
            ? `${year}-${month + 1}-01`
            : states.isYear
              ? `${year}-01-01`
              : null;

        nextDate = states.isDay
          ? `${year}-${month + 1}-${today}`
          : states.isMonth
            ? year + "-" + (month + 1) + "-" + totaldaysInMonth
            : states.isYear
              ? `${year}-12-31`
              : null; */
        }

        prevDate = states.isDay
          ? `${year}-${month + 1}-${today}`
          : states.isMonth
            ? `${year}-${month + 1}-01`
            : states.isYear
              ? `${moment(academic_period.start_date).format('YYYY-MM-DD')}`
              : null;

        nextDate = states.isDay
          ? `${year}-${month + 1}-${today}`
          : states.isMonth
            ? year + '-' + (month + 1) + '-' + totaldaysInMonth
            : states.isYear
              ? `${moment(academic_period.end_date).format('YYYY-MM-DD')}`
              : null;
        setFilterPrevDate(moment(prevDate).format('YYYY-MM-DD'));
        setFilterNextDate(moment(nextDate).format('YYYY-MM-DD'));
      } else {
        toast.info(
          `Please select the dates between ${moment(academicStartDate).format('MMMM Do YYYY')} TO ${moment(academicEndDate).format('MMMM Do YYYY')} for accessing the data`,
        );
      }
    } else if (arrowdate === 'next') {
      let currentdate = new Date(date);
      let previousday = new Date(currentdate.getTime() + 24 * 60 * 60 * 1000);
      let today = states.isDay ? previousday.getDate() : currentdate.getDate();
      let month = states.isMonth ? (currentdate.getMonth() === 11 ? 0 : currentdate.getMonth() + 1) : previousday.getMonth();
      let year = states.isYear
        ? currentdate.getFullYear() + 1
        : currentdate.getMonth() === 11
          ? currentdate.getFullYear() + 1
          : previousday.getFullYear();

      const checkMinDate =
        new Date(moment(academicStartDate).format('YYYY-MM-DD')) <=
        new Date(moment(new Date(year, month, today)).format('YYYY-MM-DD'));
      const checkMaxDate =
        new Date(moment(academicEndDate).format('YYYY-MM-DD')) >=
        new Date(moment(new Date(year, month, today)).format('YYYY-MM-DD'));
      // console.log("NEXT date onChange comparisions", { checkMinDate, checkMaxDate })

      if (checkMinDate && checkMaxDate) {
        setStartDate(new Date(year, month, today));
        whichTimeFrame(year, month, today, states);

        prevDate = states.isDay
          ? `${year}-${month + 1}-${today}`
          : states.isMonth
            ? `${year}-${month + 1}-01`
            : states.isYear
              ? // ? `${year}-12-31`
                `${moment(academic_period.start_date).format('YYYY-MM-DD')}`
              : null;

        nextDate = states.isDay
          ? `${year}-${month + 1}-${today}`
          : states.isMonth
            ? year + '-' + (month + 1) + '-' + totaldaysInMonth
            : states.isYear
              ? // ? `${year}-12-31`
                `${moment(academic_period.end_date).format('YYYY-MM-DD')}`
              : null;
        setFilterPrevDate(moment(prevDate).format('YYYY-MM-DD'));
        setFilterNextDate(moment(nextDate).format('YYYY-MM-DD'));
      } else {
        toast.info(
          `Please select the dates between ${moment(academicStartDate).format('MMMM Do YYYY')} TO ${moment(academicEndDate).format('MMMM Do YYYY')} for accessing the data`,
        );
      }
    } else if (arrowdate === 'calander') {
      const checkMinDate = new Date(moment(academicStartDate).format('YYYY-MM-DD')) <= new Date(moment(date).format('YYYY-MM-DD'));
      const checkMaxDate = new Date(moment(academicEndDate).format('YYYY-MM-DD')) >= new Date(moment(date).format('YYYY-MM-DD'));
      // console.log("calander date onChange comparisions", { checkMinDate, checkMaxDate })

      if (checkMinDate && checkMaxDate) {
        setStartDate(date);
        let date_val = new Date(date);
        localStorage.setItem('oncalendardate', date_val);
        month = date_val.getMonth();
        today = date_val.getDate();
        year = date_val.getFullYear();

        prevDate = states.isDay
          ? `${year}-${month + 1}-${today}`
          : states.isMonth
            ? `${year}-${month + 1}-01`
            : states.isYear
              ? // ? `${year}-12-31`
                `${moment(academic_period.start_date).format('YYYY-MM-DD')}`
              : null;

        nextDate = states.isDay
          ? `${year}-${month + 1}-${today}`
          : states.isMonth
            ? year + '-' + (month + 1) + '-' + totaldaysInMonth
            : states.isYear
              ? // ? `${year}-12-31`
                `${moment(academic_period.end_date).format('YYYY-MM-DD')}`
              : null;

        setFilterPrevDate(moment(prevDate).format('YYYY-MM-DD'));
        setFilterNextDate(moment(nextDate).format('YYYY-MM-DD'));
      } else {
        toast.info(
          `Please select the dates between ${moment(academicStartDate).format('MMMM Do YYYY')} TO ${moment(academicEndDate).format('MMMM Do YYYY')} for accessing the data`,
        );
      }
    }
    setDate(Math.random());
  };

  // console.log("cubeData inside Attendance index", cubeData);

  return (
    <>
      <div className={styles.top}>
        <GridContainer>
          <div className={styles.calSec}>
            <div>
              <GridItem>
                <div className={styles.cal}>
                  <div className={styles.left}>
                    <div className={styles.options}>
                      <AttendenceDropdown
                        styles={styles}
                        options={options?.modes}
                        modeslabel={'mode'}
                        name={'mode'}
                        changeMode={changeMode}
                        value={selectedValues.modeId}
                        initialLabel={'All Mode'}
                        selectedId={'assessment_mode_id'}
                        displayName={'name'}
                      />
                      <AttendenceDropdown
                        styles={styles}
                        options={options?.organizers}
                        modeslabel={'Organizer'}
                        name={'organizer'}
                        changeMode={changeMode}
                        value={selectedValues.organizerId}
                        initialLabel={'All Organizer'}
                        selectedId={'organiser_type_id'}
                        displayName={'name'}
                      />
                      <AttendenceDropdown
                        styles={styles}
                        options={options?.assessmentType}
                        modeslabel={'Type'}
                        name={'type'}
                        changeMode={changeMode}
                        value={selectedValues.assessmentTypeId}
                        initialLabel={'All Type'}
                        selectedId={'assessment_type_id'}
                        displayName={'name'}
                      />
                      <AttendenceDropdown
                        styles={styles}
                        options={options?.studyCircleList}
                        modeslabel={'Study Circle'}
                        name={'studyCircle'}
                        changeMode={changeMode}
                        value={selectedValues.studyCircleId}
                        initialLabel={'All Type'}
                        selectedId={'study_circle_id'}
                        displayName={'study_circle_name'}
                      />
                      <AttendenceDropdown
                        styles={styles}
                        options={options?.subjects}
                        modeslabel={'Subject'}
                        name={'subject'}
                        changeMode={changeMode}
                        value={selectedValues.subjectId}
                        initialLabel={'All Subjects'}
                        selectedId={'subject_id'}
                        displayName={'subject'}
                      />
                    </div>
                  </div>
                  <div className={styles.right}>
                    <AttendenceCalendarSection
                      styles={styles}
                      startdate={startdate}
                      handleChange={handleChange}
                      handleClick={handleClick}
                      states={states}
                      academicPeriodsList={academicPeriodArr}
                      changeMode={changeMode}
                      academicPeriodId={academicPeriodId}
                    />
                  </div>
                </div>
              </GridItem>
            </div>
          </div>
          <div className={styles.attendance_top}>
            <AttendenceTable styles={styles} isLoading={isLoading} cubeData={cubeData} states={states} setCubeData={setCubeData} />
          </div>
        </GridContainer>
      </div>
    </>
  );
};
export default Attendance;
