// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".chartStyle_main_item__1uFSK {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  flex-direction: row;\n  flex-wrap: wrap;\n  /* padding-bottom: 20px; */\n  margin: 10px 10px 30px 10px;\n}\n.chartStyle_left_item__2LEDY,\n.chartStyle_right_item__1VHv- {\n  display: inherit;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  /* margin: 0px 10px; */\n}\n.chartStyle_left_item_img__1ljOW,\n.chartStyle_right_item_div1_img__1wwLD {\n  display: inherit;\n  margin-right: 10px;\n}\n.chartStyle_right_item_div2_img__2rtIf {\n  display: inherit;\n  margin-right: 10px;\n  opacity: 0.4;\n}\n.chartStyle_left_item_txt__3MmaG {\n  color: #6e6e6e;\n  /* font-family: Roboto; */\n  font-size: 13px;\n  font-weight: 600;\n  letter-spacing: 0.43px;\n  line-height: 14px;\n  text-transform: capitalize;\n}\n.chartStyle_right_item_div1__3JPtV,\n.chartStyle_right_item_div2__1YfkY {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  /* margin: 0px 10px; */\n}\n.chartStyle_right_item_div1__3JPtV {\n  margin-right: 40px;\n}\n.chartStyle_right_item_div1_txt__1XR6M,\n.chartStyle_right_item_div2_txt__humYr {\n  color: #313131;\n  /* font-family: Roboto; */\n  font-size: 13px;\n  font-weight: 600;\n  line-height: 14px;\n}\n.chartStyle_handRaise__1gpk7 {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  flex-direction: row;\n  margin-left: 10px;\n  margin: 10px 10px 30px 10px;\n}\n.chartStyle_handRaise_img__2belF {\n  margin-right: 10px;\n}\n.chartStyle_handRaise_text__3YzzG {\n  color: #6e6e6e;\n  /* font-family: Roboto; */\n  font-size: 14px;\n  font-weight: 600;\n  letter-spacing: 0.43px;\n  line-height: 14px;\n}\n", ""]);
// Exports
exports.locals = {
	"main_item": "chartStyle_main_item__1uFSK",
	"left_item": "chartStyle_left_item__2LEDY",
	"right_item": "chartStyle_right_item__1VHv-",
	"left_item_img": "chartStyle_left_item_img__1ljOW",
	"right_item_div1_img": "chartStyle_right_item_div1_img__1wwLD",
	"right_item_div2_img": "chartStyle_right_item_div2_img__2rtIf",
	"left_item_txt": "chartStyle_left_item_txt__3MmaG",
	"right_item_div1": "chartStyle_right_item_div1__3JPtV",
	"right_item_div2": "chartStyle_right_item_div2__1YfkY",
	"right_item_div1_txt": "chartStyle_right_item_div1_txt__1XR6M",
	"right_item_div2_txt": "chartStyle_right_item_div2_txt__humYr",
	"handRaise": "chartStyle_handRaise__1gpk7",
	"handRaise_img": "chartStyle_handRaise_img__2belF",
	"handRaise_text": "chartStyle_handRaise_text__3YzzG"
};
module.exports = exports;
