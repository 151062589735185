import {
  getStudentWiseSummaryOneAPI,
  getStudentWiseSummaryTwoAPI,
  getStudentWiseSummaryThreeAPI,
} from '../../../../api/cube/sessionAPI';
import promiseAllCall from '../../../../utils/promise';

export async function getStudentWiseSummary(
  assessmentId,
  topicId,
  institutionId,
  studyCircleId,
  startDate,
  endDate,
  academicStartDate,
  academicEndDate,
) {
  let studentIdArray = [];

  try {
    const callAll = await promiseAllCall([
      getStudentWiseSummaryOneAPI(assessmentId, institutionId, studyCircleId, startDate, endDate),
      getStudentWiseSummaryTwoAPI(assessmentId, institutionId, studyCircleId, startDate, endDate),
    ]);
    // console.log('callAll getStudentWiseSummary', callAll);
    const resultOne = callAll[0];
    const resultTwo = callAll[1];
    // const resultThree = await cubejsApi.load(qSummaryThree);
    console.log('callAll 1', resultOne.loadResponse.results[0].data);
    console.log('callAll 2', resultTwo.loadResponse.results[0].data);
    const summaryResultOne = resultOne?.loadResponse?.results[0]?.data;
    const summaryResultTwo = resultTwo?.loadResponse?.results[0]?.data;
    if (summaryResultOne?.length !== 0 && summaryResultTwo?.length !== 0) {
      studentIdArray = summaryResultOne.map((obj) => {
        return obj['AssessmentResponse.studentId'].toString();
      });
      console.log('callAll studentIdArray', studentIdArray);
      const resultThree = await getStudentWiseSummaryThreeAPI(topicId, studentIdArray, academicStartDate, academicEndDate);
      console.log('callAll 3', resultThree.loadResponse.results[0].data);
      const summaryResultThree = resultThree?.loadResponse?.results[0]?.data;
      if (summaryResultThree.length !== 0) {
        const tempQuestions = [];
        summaryResultOne.forEach((objOne, summaryIndex) => {
          tempQuestions.push([]);
          summaryResultTwo.forEach((objTwo) => {
            if (objOne['AssessmentResponse.studentId'] === objTwo['AssessmentResponse.studentId']) {
              tempQuestions[summaryIndex].push({
                result: objTwo['AssessmentResponse.result'],
                questionId: objTwo['AssessmentResponse.questionId'],
              });
            }
          });
        });
        console.log('tempQuestions', tempQuestions);
        let tempSummary = summaryResultOne.map((obj, summayTempIndex) => {
          return { ...obj, questions: tempQuestions[summayTempIndex] };
        });
        console.log('tempSummary', tempSummary);
        let tempNewSummary = [];
        tempSummary.forEach((obj) => {
          summaryResultThree.forEach((objThree) => {
            if (+obj['AssessmentResponse.studentId'] === +objThree['AssessmentResponse.studentId']) {
              tempNewSummary.push({
                ...obj,
                topicAvgVelocity: objThree['AssessmentResponse.avgVelocity'],
              });
            }
          });
        });
        console.log('tempNewSummary', tempNewSummary);
        return tempNewSummary;
      } else {
        return [];
      }
    } else {
      return [];
    }
  } catch (error) {
    console.log('error in getStudentWiseSummary', error);
  }
}
