import { CUBEJS_API } from '../../config/Api';
import cubejs from '@cubejs-client/core';
import { toast } from 'react-toastify';

function cubeFunction() {
  var cubeJsToken = window.localStorage.getItem('cmn_cubeJs_token');
  var cubejsAPIFunction = cubejs(cubeJsToken, {
    apiUrl: CUBEJS_API,
  });
  return cubejsAPIFunction;
}

function handleError(error) {
  console.log(error);
  if (error?.response?.error) {
    console.log(error.response.error);
    toast.error(error.response.error);
  }
  return error;
}

//RA-PERF-10  Combined query for performance details: new name --> RA-PERF-11-DAY, RA-PERF-11-MONTH, RA-PERF-11-YEAR
export async function fetchAllPerformanceDetails(fetchPerformanceFilter, filterPrevDate, filterNextDate, states) {
  // console.log("filters inside fetchAllPerformanceDetails", studyCircleId, selectedGroupId)
  // console.log('fetchPerformanceFilter', fetchPerformanceFilter);
  const cubeJsApi = cubeFunction();

  const timeDimensions = [
    {
      dimension: states?.isDay
        ? 'GroupPerformanceRank_Day.assessmentDay'
        : states?.isMonth
          ? 'GroupPerformanceRank_Month.assessmentMonth'
          : null,
      dateRange: [`${filterPrevDate}`, `${filterNextDate}`],
    },
  ];

  const dayDimensions = [
    'GroupPerformanceRank_Day.marksScored',
    'GroupPerformanceRank_Day.groupAvgScore',
    'GroupPerformanceRank_Day.avgAccuracy',
    'GroupPerformanceRank_Day.avgTimeTaken',
    'GroupPerformanceRank_Day.totalCorrects',
    'GroupPerformanceRank_Day.totalIncorrects',
    'GroupPerformanceRank_Day.totalNearCorrects',
    'GroupPerformanceRank_Day.totalNotAnswered',
    'GroupPerformanceRank_Day.timeTakenPerformanceTrend',
    'GroupPerformanceRank_Day.scorePerformanceTrend',
    'GroupPerformanceRank_Day.accuracyPerformanceTrend',
    'GroupPerformanceRank_Day.performanceTrend',
    'GroupPerformanceRank_Day.groupAvgAccuracy',
    'GroupPerformanceRank_Day.groupAvgTimeTaken',
    'GroupPerformanceRank_Day.questionsCount',
    'GroupPerformanceRank_Day.avgHandRaise',
    'GroupPerformanceRank_Day.sumHandRaiseCount',
    'GroupPerformanceRank_Day.uniqueStudents',
  ];
  const monthDimensions = [
    'GroupPerformanceRank_Month.avgAccuracy',
    'GroupPerformanceRank_Month.accuracyPerformanceTrend',
    'GroupPerformanceRank_Month.groupAvgAccuracy',
    'GroupPerformanceRank_Month.avgTimeTaken',
    'GroupPerformanceRank_Month.groupAvgTimeTaken',
    'GroupPerformanceRank_Month.timeTakenPerformanceTrend',
    'GroupPerformanceRank_Month.totalCorrects',
    'GroupPerformanceRank_Month.totalIncorrects',
    'GroupPerformanceRank_Month.totalNearCorrects',
    'GroupPerformanceRank_Month.totalNotAnswered',
    'GroupPerformanceRank_Month.questionsCount',
    'GroupPerformanceRank_Month.marksScored',
    'GroupPerformanceRank_Month.groupAvgScore',
    'GroupPerformanceRank_Month.scorePerformanceTrend',
    'GroupPerformanceRank_Month.avgHandRaise',
    'GroupPerformanceRank_Month.sumHandRaiseCount',
    'GroupPerformanceRank_Month.uniqueStudents',
    'GroupPerformanceRank_Month.performanceTrend',
  ];
  const yearDimensions = [
    'GroupPerformanceRank_Year.avgAccuracy',
    'GroupPerformanceRank_Year.groupAvgAccuracy',
    'GroupPerformanceRank_Year.accuracyPerformanceTrend',
    'GroupPerformanceRank_Year.groupAvgScore',
    'GroupPerformanceRank_Year.scorePerformanceTrend',
    'GroupPerformanceRank_Year.marksScored',
    'GroupPerformanceRank_Year.avgTimeTaken',
    'GroupPerformanceRank_Year.groupAvgTimeTaken',
    'GroupPerformanceRank_Year.timeTakenPerformanceTrend',
    'GroupPerformanceRank_Year.totalCorrects',
    'GroupPerformanceRank_Year.totalIncorrects',
    'GroupPerformanceRank_Year.totalNearCorrects',
    'GroupPerformanceRank_Year.totalNotAnswered',
    'GroupPerformanceRank_Year.questionsCount',
    'GroupPerformanceRank_Year.sumHandRaiseCount',
    'GroupPerformanceRank_Year.uniqueStudents',
    'GroupPerformanceRank_Year.sessionDuration',
    'GroupPerformanceRank_Year.performanceTrend',
    'GroupPerformanceRank_Year.avgHandRaise',
  ];

  const q = {
    filters: fetchPerformanceFilter,
    dimensions: states?.isDay ? dayDimensions : states?.isMonth ? monthDimensions : states?.isYear ? yearDimensions : null,
  };
  const finalQuery = states?.isYear ? { ...q } : { ...q, timeDimensions };
  // console.log('FinalQuery', finalQuery);
  try {
    let startTime = Date.now();
    const result = await cubeJsApi.load(finalQuery);
    let endTime = Date.now();
    let timeTaken = endTime - startTime;
    // console.log('Total Time taken for RA_PERF_10 - Combine - fetchAllPerformanceDetails', timeTaken);
    // console.log('result inside cube query --fetchAllPerformanceDetails', result);
    return result?.loadResponses[0]?.data[0];
  } catch (error) {
    handleError(error);
  }
}
