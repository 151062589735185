export { default as trend } from '../../../assets/images/graph-dashboard.png';
export { default as gause } from '../../../assets/images/read-velocity.png';
export { default as greenArrow } from '../../../assets/images/green_arrow.png';
export { default as handRaise } from '../../../assets/images/raise-hand.png';
export { default as redArrow } from '../../../assets/images/red-arrow.png';
export { default as orangeArrow } from '../../../assets/images/orange-arrow.png';
export { default as Sequreblue } from '../../../assets/images/sequre-blue.png';
export { default as Sequregrey } from '../../../assets/images/sequre-gery.png';
export { default as Sequregreen } from '../../../assets/images/sequre-green.png';
export { default as Sequrered } from '../../../assets/images/sequre-red.png';
