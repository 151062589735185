// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/dropdown.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".report-dropdown-selection {\n  position: relative;\n  width: 100%;\n  height: 40px;\n  font-family: \"Roboto\";\n  appearance: none;\n  border-radius: 8px;\n  padding-left: 8px;\n  background: rgba(0, 0, 0, 0.1) url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")\n    no-repeat 97% 50%;\n  outline: none;\n  border: none;\n  cursor: pointer;\n  color: rgba(68, 68, 68, 1);\n}\n\n.report-dropdown-selection option {\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 15px;\n  color: #000000;\n  border-radius: 8px;\n}\n", ""]);
// Exports
module.exports = exports;
