// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TrendChart_left_div_flex__2bFPo {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  flex-direction: row;\n  flex-shrink: initial;\n  flex: 1 1;\n  flex-grow: 1;\n  width: 100%;\n  margin-top: 55px;\n}\n.TrendChart_left_div_col__NAiXA {\n  width: 20%;\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  flex-direction: column;\n  border-collapse: collapse;\n  padding: 10px;\n}\n.TrendChart_trend_div_active__1a9Yv {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  background-color: #fc8802;\n  border-radius: 4px;\n  width: 110px;\n  height: 30px;\n  color: #ffffff;\n  padding: 5px;\n  margin: 5px 0px;\n}\n.TrendChart_trend_div__21rXZ {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  background-color: #d8d8d8;\n  border-radius: 4px;\n  width: 110px;\n  height: 30px;\n  padding: 5px;\n  margin: 5px 0px;\n  opacity: 0.6;\n  cursor: pointer;\n}\n.TrendChart_trend_div_name__2HjE7 {\n  padding-left: 5px;\n  padding-right: 5px;\n  font-size: 14px;\n  font-weight: 600;\n  line-height: 17px;\n}\n.TrendChart_right_div_col__3RkfP {\n  width: 80%;\n}\n@media only screen and (max-width: 490px) {\n  .TrendChart_left_div_flex__2bFPo {\n    flex-direction: column;\n    margin-top: 0px;\n  }\n  .TrendChart_right_div_col__3RkfP,\n  .TrendChart_left_div_col__NAiXA {\n    width: 100%;\n  }\n  .TrendChart_left_div_col__NAiXA {\n    flex-direction: row;\n    align-items: center;\n    display: flex;\n    justify-content: space-evenly;\n  }\n  .TrendChart_velocity_div__Sm-7Q {\n    margin-bottom: 0px;\n  }\n  .TrendChart_groupRank_div__1Hx9I,\n  .TrendChart_score_div__1V8VT,\n  .TrendChart_accuracy_div__3YRj9 {\n    margin: 5px;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"left_div_flex": "TrendChart_left_div_flex__2bFPo",
	"left_div_col": "TrendChart_left_div_col__NAiXA",
	"trend_div_active": "TrendChart_trend_div_active__1a9Yv",
	"trend_div": "TrendChart_trend_div__21rXZ",
	"trend_div_name": "TrendChart_trend_div_name__2HjE7",
	"right_div_col": "TrendChart_right_div_col__3RkfP",
	"velocity_div": "TrendChart_velocity_div__Sm-7Q",
	"groupRank_div": "TrendChart_groupRank_div__1Hx9I",
	"score_div": "TrendChart_score_div__1V8VT",
	"accuracy_div": "TrendChart_accuracy_div__3YRj9"
};
module.exports = exports;
