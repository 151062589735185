// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HandRaiseChart_main__3g-U9 {\n  display: flex;\n  justify-content: flex-start;\n  flex: 1 1;\n  flex-direction: column;\n  margin: 15px 10px 0 20px;\n}\n.HandRaiseChart_left__2LwRb,\n.HandRaiseChart_right__2N66x,\n.HandRaiseChart_mid__LhCfJ {\n  display: inherit;\n  justify-content: center;\n  align-content: center;\n  line-height: 20px;\n  flex-direction: column;\n  margin-bottom: 48px;\n}\n.HandRaiseChart_top__2CqhS {\n  color: #464646;\n  font-size: 20px;\n  font-weight: 500;\n  line-height: 34px;\n}\n.HandRaiseChart_bottom__2Mzp6 {\n  color: #000000;\n  font-size: 12px;\n  font-weight: 500;\n  line-height: 14px;\n  opacity: 0.300000011920929;\n  text-transform: capitalize;\n  font-weight: bold;\n}\n.HandRaiseChart_bottom_end__1UydX {\n  color: #000000;\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 14px;\n  opacity: 0.300000011920929;\n  text-transform: capitalize;\n  font-weight: bold;\n}\n.HandRaiseChart_bottom_btn__fwGjF {\n  width: 224px;\n  height: 32px;\n  background: #fc8802;\n  border-radius: 4px;\n}\n.HandRaiseChart_bottom_btn_txt__27Lyg {\n  font-size: 12px;\n  line-height: 14px;\n  color: #f2f2f2;\n  text-align: center;\n  padding: 8px;\n}\n", ""]);
// Exports
exports.locals = {
	"main": "HandRaiseChart_main__3g-U9",
	"left": "HandRaiseChart_left__2LwRb",
	"right": "HandRaiseChart_right__2N66x",
	"mid": "HandRaiseChart_mid__LhCfJ",
	"top": "HandRaiseChart_top__2CqhS",
	"bottom": "HandRaiseChart_bottom__2Mzp6",
	"bottom_end": "HandRaiseChart_bottom_end__1UydX",
	"bottom_btn": "HandRaiseChart_bottom_btn__fwGjF",
	"bottom_btn_txt": "HandRaiseChart_bottom_btn_txt__27Lyg"
};
module.exports = exports;
