import React from 'react';
import { InputBase } from '@material-ui/core';

const searchBox = (props) => {
  const { id, name, onChange, classess, value } = props;
  return (
    <InputBase
      id={id}
      name={name}
      onChange={onChange}
      value={value}
      placeholder="Search"
      classes={{
        root: classess.inputRoot,
        input: classess.inputInput,
      }}
      inputProps={{ 'aria-label': 'search' }}
      style={{ paddingLeft: 'calc(1em + 32px)' }}
    />
  );
};

export default searchBox;
