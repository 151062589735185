import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
// import cx from 'classnames'

const styles = {
  grid: {
    margin: '0 -15px !important',
    width: 'unset',
    borderRadius: 3,
  },
};

const useStyles = makeStyles(styles);

function GridContainer(props) {
  const classes = useStyles();
  const { children, ...rest } = props;
  return (
    <Grid container {...rest} className={classes.grid} style={props.style}>
      {children}
    </Grid>
  );
}
GridContainer.propTypes = {
  children: PropTypes.node,
};

export default GridContainer;
