import https from '../../init/https';

//RA-DD-01: List the Study Circles in the Institution
export async function getStudyCircle(institutionId) {
  const getStudyCircleOpt = await https.get(`/dropdowns/${institutionId}/study_circles/list`);
  return getStudyCircleOpt;
}

//RA-DD-02: List all the Teachers for given Study Circle
export async function getTeachers(institutionId, selectedStudy) {
  const getTeacherOpt = await https.get(`/dropdowns/${institutionId}/study_circles/${selectedStudy}/educators`);
  return getTeacherOpt;
}

//GET RA-DD-03: Get the List of Subject for Given Teacher
export async function getSubject(institutionId, educator_id, email, studyCircleId) {
  const getSubjectsOpt = await https.get(
    `/dropdowns/${institutionId}/educators/${educator_id}/subjects?educator_email=${email}&study_circle_id=${studyCircleId}`,
  );
  return getSubjectsOpt;
}

//GET RA-DD-04: Get the list of Assessment Types
export async function getAssessmentType() {
  const getTypeOpt = await https.get(`/dropdowns/assessment_types`);
  return getTypeOpt;
}

//GET RA-DD-05: List all organizer types
export async function getOrganiser() {
  const getOrganizerOpt = await https.get(`/dropdowns/organizer_types`);
  return getOrganizerOpt;
}

//GET RA-DD-06: Retrieve list of all Assessment Modes
export async function getModes() {
  const getModeOpt = await https.get(`/dropdowns/assessment_modes`);
  return getModeOpt;
}

//GET RA-DD-08: Retrieve List of Sub Subjects for Subject
export async function getSubSubject(institution_id, subject_id) {
  const getSubSubjectOpt = await https.get(`/dropdowns/${institution_id}/subjects/${subject_id}/sub_subjects`);
  return getSubSubjectOpt;
}

//GET RA-DD-09: Retrieve List of topics for Sub Subjects
export async function getTopic(institution_id, subject_id, subSubjectId) {
  const getTopicOpt = await https.get(`/dropdowns/${institution_id}/subjects/${subject_id}/topics?sub_subject_id=${subSubjectId}`);
  return getTopicOpt;
}

//GET RA-DD-10: Retrieve List of Sub Topics for Topics
export async function getSubTopic(institution_id, subject_id, subSubjectId, topicId) {
  const getSubTopicOpt = await https.get(
    `/dropdowns/${institution_id}/subjects/${subject_id}/topics/${topicId}/sub_topics?sub_subject_id=${subSubjectId}`,
  );
  return getSubTopicOpt;
}

//GET RA-DD-11: Retrieve List of Objective
export async function getObjective() {
  const getObjectiveOpt = await https.get(`/dropdowns/objectives`);
  return getObjectiveOpt;
}

//GET RA-DD-12: Get the List of Subject for Given Study Circle
export async function getSubjectStudy(institution_id, study_circle_id) {
  const getSubjectOpt = await https.get(`/dropdowns/${institution_id}/study_circle/${study_circle_id}/subjects`);
  return getSubjectOpt;
}
