import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const Results_chart = ({ data }) => {
  const chartData = {
    datasets: [
      {
        data: data,
        backgroundColor: ['#5AAB00', '#022AB8', '#FF0000', '#C3C3C3'],
      },
    ],
    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: ['Correct', 'Near Correct', 'Incorrect', 'Not Answered'],
  };
  const options = {
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    plugins: {
      labels: false,
    },
    legend: {
      display: false,
    },
  };
  return <Doughnut data={chartData} options={options} width={100} height={35} />;
};

export default Results_chart;
