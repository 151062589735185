import React from 'react';
import { Avatar } from '@material-ui/core';
import grayHand from '../../assets/images/gery-hand.png';
import rdHand from '../../assets/images/gery-hand.png';
import up from '../../assets/images/top-arrow.png';
import down from '../../assets/images/down-arrow.png';
import gauseIcon from '../../assets/images/ra-org.png';
import roundTwoDecimal from '../roundTwoDecimal/roundTwoDecimal';

const AssessmentStudentResponses = (props) => {
  const { styles, sessionThead, sortArray, studentWiseAnswer, charts } = props;
  console.log('studentWiseAnswer, charts', studentWiseAnswer, charts);
  return (
    <div className={styles.list}>
      <div style={{ display: 'flex' }}>
        {sessionThead.map((el, indexHead) => (
          <div key={indexHead} className={el === 'R#' ? styles.list_head_act : styles.list_head}>
            <div className={el === 'R#' ? styles.list_elm_act : styles.list_elm}>
              {el}
              <div className={styles.arrow}>
                <span className={styles.arrUp} onClick={() => sortArray(true, el)}>
                  <img src={up} alt="up" />
                </span>
                <span className={styles.arrDown} onClick={() => sortArray(false, el)}>
                  <img src={down} alt="down" />
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        style={{
          width: '100%',
          marginTop: studentWiseAnswer.length > 0 ? '8px' : '0px',
          marginLeft: studentWiseAnswer.length > 0 ? '17px' : '0px',
          display: studentWiseAnswer.length < 0 && 'flex',
          justifyContent: studentWiseAnswer.length < 0 && 'center',
        }}>
        {studentWiseAnswer.length > 0 ? (
          studentWiseAnswer.map((el, listBodyIndex) => (
            <div key={listBodyIndex} className={styles.list_body}>
              <div className={styles.list_body_left}>
                <div>
                  <Avatar alt="avatar" src={el['Student.profilePicUrl']} />
                </div>
                <div
                  style={{ display: 'flex' }}
                  // style={{ border: "1px solid red" }}
                >
                  <img src={el['StudentHandRaise.handRaise'] ? rdHand : grayHand} alt="images" />
                </div>
              </div>
              <div className={styles.list_body_right}>
                <div className={styles.list_body_right_RA}>#{el['AssessmentResponse.studentId']}</div>
                <div className={styles.list_body_right_Name}>{el['Student.name']}</div>
                <div className={styles.boxes}>
                  {charts.map((chartsQue) => {
                    return el.questions.map((elm, colIndex) => {
                      return +elm.questionId === +chartsQue.questionId ? (
                        <div
                          key={colIndex}
                          className={
                            elm.result === 'correct'
                              ? styles.green
                              : elm.result === 'incorrect'
                                ? styles.red
                                : elm.result === 'near correct'
                                  ? styles.blue
                                  : styles.gray
                          }></div>
                      ) : null;
                    });
                  })}
                </div>
                <div
                  className={styles.list_body_right_bottom}
                  style={{
                    borderBottom: '1px solid #e2dfdf',
                  }}>
                  <div className={styles.list_body_right_Gause} style={{ paddingBottom: '8px' }}>
                    <span style={{ display: 'flex', paddingRight: '10px' }}>
                      <img src={gauseIcon} alt="gause" />
                    </span>
                    <span className={styles.list_body_right_Gause_session}>Session</span>
                    <span className={styles.list_body_right_Gause_session_val}>
                      {roundTwoDecimal(el['AssessmentResponse.avgVelocity'])}
                    </span>
                  </div>
                  <div className={styles.list_body_right_Topic}>
                    <span className={styles.list_body_right_Gause_topic}>Topic</span>
                    <span className={styles.list_body_right_Gause_topic_val}>{roundTwoDecimal(el.topicAvgVelocity)}</span>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div style={{ fontStyle: 'normal', color: 'gray' }}>{'No Data found'}</div>
        )}
      </div>
    </div>
  );
};

export default AssessmentStudentResponses;
