// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".loadingBars_loading_animation__3S1MX {\n  position: fixed; /* Positioning and size */\n  top: 0;\n  left: 160px;\n  width: 100vw;\n  height: 100vh;\n  background-color: rgba(128, 128, 128, 0.5);\n  z-index: 99;\n}\n\n.loadingBars_reactLoadingBars__3Kqzo {\n  margin: 0 auto;\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  z-index: 100;\n}\n", ""]);
// Exports
exports.locals = {
	"loading_animation": "loadingBars_loading_animation__3S1MX",
	"reactLoadingBars": "loadingBars_reactLoadingBars__3Kqzo"
};
module.exports = exports;
