import { CUBEJS_API } from '../../config/Api';
import cubejs from '@cubejs-client/core';
import { toast } from 'react-toastify';

function cubeFunction() {
  var cubeJsToken = window.localStorage.getItem('cmn_cubeJs_token');
  var cubejsAPIFunction = cubejs(cubeJsToken, {
    apiUrl: CUBEJS_API,
  });
  return cubejsAPIFunction;
}

function handleError(error) {
  console.error(error);
  if (error?.response?.error) {
    console.log(error.response.error);
    toast.error(error.response.error);
  }
  return error;
}

//Reference - RA-PERF-08 Performance Trend graph
export async function fetchPerformanceTrendChart(startDate, endDate, institutionId, studyCircleId) {
  const cubeJsApi = cubeFunction();
  const qScore = {
    limit: 5000,
    timeDimensions: [
      {
        dimension: 'GroupPerformanceRank_Day.assessmentDay',
        dateRange: [`${startDate}`, `${endDate}`],
      },
    ],
    dimensions: [
      'GroupPerformanceRank_Day.assessmentDay',
      'GroupPerformanceRank_Day.avgVelocity',
      'GroupPerformanceRank_Day.performanceGroupRankNo',
      'GroupPerformanceRank_Day.avgScore',
      'GroupPerformanceRank_Day.avgAccuracy',
      'GroupPerformanceRank_Day.groupPercentile',
    ],
    filters: [
      {
        member: 'GroupPerformanceRank_Day.institutionId',
        operator: 'equals',
        values: [`${institutionId}`],
      },
      {
        member: 'GroupPerformanceRank_Day.studyCircleId',
        operator: 'equals',
        values: [`${studyCircleId}`],
      },
    ],
  };

  try {
    const startTime = Date.now();
    const result = await cubeJsApi.load(qScore);
    const endTime = Date.now();
    const timeTaken = endTime - startTime;
    // console.log('Time taken for fetchPerformanceTrendChart', timeTaken);
    return result;
  } catch (error) {
    handleError(error);
  }
}
