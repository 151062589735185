export function filterBuildPerformance(
  institutionId,
  selectedMode,
  selectedAssessmentType,
  selectedOrganiser,
  studyCircleId,
  selectedSubject,
  states,
  academicPeriodId,
) {
  const filter = [];
  filter.push({
    member: states?.isDay
      ? 'GroupPerformanceRank_Day.institutionId'
      : states?.isMonth
        ? 'GroupPerformanceRank_Month.institutionId'
        : states?.isYear
          ? 'GroupPerformanceRank_Year.institutionId'
          : null,
    operator: 'equals',
    values: [institutionId],
  });
  if (+selectedMode !== -1) {
    filter.push({
      member: states?.isDay
        ? 'GroupPerformanceRank_Day.assessmentModeId'
        : states?.isMonth
          ? 'GroupPerformanceRank_Month.assessmentModeId'
          : states?.isYear
            ? 'GroupPerformanceRank_Year.assessmentModeId'
            : null,
      operator: 'equals',
      values: [selectedMode],
    });
  }
  if (+selectedAssessmentType !== -1) {
    filter.push({
      member: states?.isDay
        ? 'GroupPerformanceRank_Day.assessmentTypeId'
        : states?.isMonth
          ? 'GroupPerformanceRank_Month.assessmentTypeId'
          : states?.isYear
            ? 'GroupPerformanceRank_Year.assessmentTypeId'
            : null,
      operator: 'equals',
      values: [selectedAssessmentType],
    });
  }
  if (+selectedOrganiser !== -1) {
    filter.push({
      member: states?.isDay
        ? 'GroupPerformanceRank_Day.assessmentOrganizerTypeId'
        : states?.isMonth
          ? 'GroupPerformanceRank_Month.assessmentOrganizerTypeId'
          : states?.isYear
            ? 'GroupPerformanceRank_Year.assessmentOrganizerTypeId'
            : null,
      operator: 'equals',
      values: [selectedOrganiser],
    });
  }
  if (+studyCircleId !== -1) {
    filter.push({
      member: states?.isDay
        ? 'GroupPerformanceRank_Day.studyCircleId'
        : states?.isMonth
          ? 'GroupPerformanceRank_Month.studyCircleId'
          : states?.isYear
            ? 'GroupPerformanceRank_Year.studyCircleId'
            : null,
      operator: 'equals',
      values: [studyCircleId],
    });
  }
  if (+selectedSubject !== -1) {
    filter.push({
      member: states?.isDay
        ? 'GroupPerformanceRank_Day.subjectId'
        : states?.isMonth
          ? 'GroupPerformanceRank_Month.subjectId'
          : states?.isYear
            ? 'GroupPerformanceRank_Year.subjectId'
            : null,
      operator: 'equals',
      values: [selectedSubject],
    });
  }
  if (states?.isYear) {
    filter.push({
      member: 'GroupPerformanceRank_Year.academicPeriod',
      operator: 'equals',
      values: [+academicPeriodId],
    });
  }
  return filter;
}
