import { CUBEJS_API } from '../../config/Api';
import cubejs from '@cubejs-client/core';
import { toast } from 'react-toastify';

function cubeFunction() {
  const cubeJsToken = window.localStorage.getItem('cmn_cubeJs_token');
  const cubejsApi = cubejs(cubeJsToken, {
    apiUrl: CUBEJS_API,
  });
  return cubejsApi;
}
/* const startTime = Date.now()
   const result = await cubejsApi.load(qAttandanceData);
   const endTime = Date.now()
   const timeTaken = endTime - startTime
   console.log("Time taken for fetchAttendanceData", timeTaken) */

//Reference: RA-PERF-01 - Cube API to fetch Performance rows  new name: RA-PERF-01-DAY/MONTH/YEAR
export async function getPerformanceRowsAPI(filter, academicStartDate, academicEndDate, states) {
  // console.log("getPerformanceRowsAPI params", { filter, academicStartDate, academicEndDate, states });
  var cubejsApi = cubeFunction();
  const timeDimensions = [
    {
      dimension: states?.isDay
        ? 'GroupPerformanceRank_Day.assessmentDay'
        : states?.isMonth
          ? 'GroupPerformanceRank_Month.assessmentMonth'
          : null,
      dateRange: [`${academicStartDate}`, `${academicEndDate}`],
    },
  ];
  const dayDimensions = [
    'GroupPerformanceRank_Day.groupStudyCircleId', //Modified
    'GroupPerformanceRank_Day.institutionId',
    'GroupPerformanceRank_Day.subjectId',
    'GroupPerformanceRank_Day.subjectName',
    'GroupPerformanceRank_Day.assessmentModeId',
    'GroupPerformanceRank_Day.assessmentModeName',
    'GroupPerformanceRank_Day.assessmentTypeId',
    'GroupPerformanceRank_Day.assessmentTypeName',
    'GroupPerformanceRank_Day.assessmentOrganizerTypeId',
    'GroupPerformanceRank_Day.assessmentOrganizerTypeName',
    'GroupPerformanceRank_Day.assessmentDay',
    'GroupPerformanceRank_Day.questionsCount',
    'GroupPerformanceRank_Day.studyCircleName',
    'GroupPerformanceRank_Day.sumVelocity',
    'GroupPerformanceRank_Day.performanceGroupCount',
    'GroupPerformanceRank_Day.studyCircleCountPerGroup', //modified
    'GroupPerformanceRank_Day.performanceGroupRankNo',
    'GroupPerformanceRank_Day.velocityUntilLastRecord',
    'GroupPerformanceRank_Day.performanceTrend',
    'GroupPerformanceRank_Day.groupPercentile',
    'GroupPerformanceRank_Day.countUntilLastRecord',
    'GroupPerformanceRank_Day.avgVelocity',
    'GroupPerformanceRank_Day.avgVelocityUntilLastRecord',
    'GroupPerformanceRank_Day.avgScore',
    'GroupPerformanceRank_Day.academicPeriod',
    'GroupPerformanceRank_Day.studyCircleId',
  ];
  const monthDimensions = [
    'GroupPerformanceRank_Month.groupStudyCircleId',
    'GroupPerformanceRank_Month.institutionId',
    'GroupPerformanceRank_Month.studyCircleName',
    'GroupPerformanceRank_Month.assessmentModeId',
    'GroupPerformanceRank_Month.assessmentModeName',
    'GroupPerformanceRank_Month.assessmentOrganizerTypeId',
    'GroupPerformanceRank_Month.assessmentOrganizerTypeName',
    'GroupPerformanceRank_Month.assessmentTypeId',
    'GroupPerformanceRank_Month.assessmentTypeName',
    'GroupPerformanceRank_Month.subjectId',
    'GroupPerformanceRank_Month.subjectName',
    'GroupPerformanceRank_Month.assessmentMonth',
    'GroupPerformanceRank_Month.questionsCount',
    'GroupPerformanceRank_Month.avgScore',
    'GroupPerformanceRank_Month.avgVelocity',
    'GroupPerformanceRank_Month.sumVelocity',
    'GroupPerformanceRank_Month.performanceGroupCount',
    'GroupPerformanceRank_Month.studyCircleCountPerGroup',
    'GroupPerformanceRank_Month.velocityUntilLastRecord',
    'GroupPerformanceRank_Month.performanceGroupRankNo',
    'GroupPerformanceRank_Month.groupPercentile',
    'GroupPerformanceRank_Month.performanceTrend',
    'GroupPerformanceRank_Month.countUntilLastRecord',
    'GroupPerformanceRank_Month.avgVelocityUntilLastRecord',
    'GroupPerformanceRank_Month.studyCircleId',
  ];
  const yearDimensions = [
    'GroupPerformanceRank_Year.institutionId',
    'GroupPerformanceRank_Year.studyCircleName',
    'GroupPerformanceRank_Year.subjectId',
    'GroupPerformanceRank_Year.subjectName',
    'GroupPerformanceRank_Year.assessmentModeId',
    'GroupPerformanceRank_Year.assessmentModeName',
    'GroupPerformanceRank_Year.assessmentTypeId',
    'GroupPerformanceRank_Year.assessmentTypeName',
    'GroupPerformanceRank_Year.assessmentOrganizerTypeId',
    'GroupPerformanceRank_Year.assessmentOrganizerTypeName',
    'GroupPerformanceRank_Year.academicPeriod',
    'GroupPerformanceRank_Year.sumVelocity',
    'GroupPerformanceRank_Year.questionsCount',
    'GroupPerformanceRank_Year.studyCircleCountPerGroup',
    'GroupPerformanceRank_Year.performanceGroupCount',
    'GroupPerformanceRank_Year.avgVelocity',
    'GroupPerformanceRank_Year.avgScore',
    'GroupPerformanceRank_Year.performanceGroupRankNo',
    'GroupPerformanceRank_Year.groupPercentile',
    'GroupPerformanceRank_Year.countUntilLastRecord',
    'GroupPerformanceRank_Year.velocityUntilLastRecord',
    'GroupPerformanceRank_Year.avgVelocityUntilLastRecord',
    'GroupPerformanceRank_Year.performanceTrend',
    'GroupPerformanceRank_Year.groupStudyCircleId',
    'GroupPerformanceRank_Year.studyCircleId',
  ];
  const q = {
    filters: filter,
    dimensions: states?.isDay ? dayDimensions : states?.isMonth ? monthDimensions : states?.isYear ? yearDimensions : null,
  };
  const finalQuery = states?.isYear ? { ...q } : { ...q, timeDimensions };
  // console.log("finalQuery perf-01", finalQuery)
  try {
    // const result = await cubejsApi.load(q);
    // console.log("result for RA-PERF-01", result);
    const startTime = Date.now();
    const result = await cubejsApi.load(finalQuery);
    const endTime = Date.now();
    // eslint-disable-next-line
    const timeTaken = endTime - startTime;
    // console.log("Time taken for getPerformanceRowsAPI", timeTaken)
    // console.log("response from perf-01 --PerformanceTable", result)
    return result;
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-PERF-01-02 - Cube API to fetch Performance rows (read velocity values) --> new name: RA-PERF-01-01
export async function getPerformanceRowsAPIRV(filter, academicStartDate, academicEndDate) {
  var cubejsApi = cubeFunction();
  const q = {
    measures: ['AssessmentResponse.avgVelocity', 'AssessmentResponse.rowNumSessionTrend'],
    timeDimensions: [
      {
        dimension: 'AssessmentResponse.assessmentDate',
        granularity: 'month',
        dateRange: [`${academicStartDate}`, `${academicEndDate}`],
      },
    ],
    filters: filter,
    dimensions: ['AssessmentResponse.raPerfGroupKey'],
  };
  try {
    // const result = await cubejsApi.load(q);
    // console.log("result for RA-PERF-01-02", result);
    const startTime = Date.now();
    const result = await cubejsApi.load(q);
    const endTime = Date.now();
    const timeTaken = endTime - startTime;
    console.log('Time taken for getPerformanceRowsAPIRV', timeTaken);
    return result;
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-PERF-02 - Cube API to fetch session headers
export async function sessionHeaderFetch(filter, startDate, endDate) {
  // console.log('sessionHeaderFetch filter, startDate, endDate', filter, startDate, endDate);
  var cubejsApi = cubeFunction();
  const qSessionHeader = {
    dimensions: [
      // "GroupSession.title",
      'Topic.name',
      'Assessment.groupSessionId',
      'GroupSession.code',
      'AssessmentResponse.assessmentId',
    ],
    timeDimensions: [
      {
        dimension: 'AssessmentResponse.assessmentDate',
        dateRange: [`${startDate}`, `${endDate}`],
      },
    ],
    order: {
      'AssessmentResponse.assessmentId': 'asc',
    },
    filters: filter,
  };
  try {
    // const result = await cubejsApi.load(qSessionHeader);
    // console.log("result RA-PERF-02 - Cube API to fetch session headers", result)
    const startTime = Date.now();
    const result = await cubejsApi.load(qSessionHeader);
    const endTime = Date.now();
    const timeTaken = endTime - startTime;
    // console.log('Time taken for sessionHeaderFetch ,result', result, timeTaken);
    return result?.loadResponses[0].data;
  } catch (error) {
    console.log('error', error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

// RA-PERF-03-03, RA-PERF-03-02, RA-PERF-03-01: All these queries are replaced by -- new Name : RA-PERF-03-01-DAY, RA-PERF-03-01-MONTH, RA-PERF-03-01-YEAR
export async function fetchPerfReadVelocityHeaderData(filterPrevDate, filterNextDate, states, filter) {
  const cubejsApi = cubeFunction();

  const timeDimensions = [
    {
      dimension: states?.isDay
        ? 'GroupPerformanceRank_Day.assessmentDay'
        : states?.isMonth
          ? 'GroupPerformanceRank_Month.assessmentMonth'
          : null,
      dateRange: [`${filterPrevDate}`, `${filterNextDate}`],
    },
  ];
  const dayDimensions = [
    'GroupPerformanceRank_Day.avgVelocity',
    'GroupPerformanceRank_Day.performanceTrend',
    'GroupPerformanceRank_Day.avgVelocityUntilLastRecord',
    'GroupPerformanceRank_Day.groupPercentile',
    'GroupPerformanceRank_Day.groupAvgVelocity',
  ];
  const monthDimensions = [
    'GroupPerformanceRank_Month.avgVelocity',
    'GroupPerformanceRank_Month.avgVelocityUntilLastRecord', //added for trend verification
    'GroupPerformanceRank_Month.groupAvgVelocity',
    'GroupPerformanceRank_Month.groupPercentile',
    'GroupPerformanceRank_Month.performanceTrend',
  ];
  const yearDimensions = [
    'GroupPerformanceRank_Year.avgVelocity',
    'GroupPerformanceRank_Year.avgVelocityUntilLastRecord', //added for trend verification
    'GroupPerformanceRank_Year.performanceTrend',
    'GroupPerformanceRank_Year.groupPercentile',
    'GroupPerformanceRank_Year.groupAvgVelocity',
  ];
  const q = {
    filters: filter,
    dimensions: states?.isDay ? dayDimensions : states?.isMonth ? monthDimensions : states?.isYear ? yearDimensions : null,
    limit: 5000,
  };
  const finalQuery = states?.isYear ? { ...q } : { ...q, timeDimensions };

  try {
    // const result = await cubejsApi.load(qFetchSCRankForGroup);
    const startTime = Date.now();
    const result = await cubejsApi.load(finalQuery);
    const endTime = Date.now();
    const timeTaken = endTime - startTime;
    // console.log('Time taken for fetchStudyCircleRankForGroup', timeTaken);
    return result?.loadResponses[0]?.data[0];
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

// RA-PERF-03-03-01: Get total Study Circle Count
export async function fetchTotalStudyCircleCount(filterPrevDate, filterNextDate) {
  console.log('fetchTotalStudyCircleCount RA-PERF-03-03-01', filterPrevDate, filterNextDate);
  const cubejsApi = cubeFunction();
  const qFetchTotalStudyCircleCount = {
    measures: ['GroupPerformanceRank.count'],
    timeDimensions: [
      {
        dimension: 'AssessmentResponse.assessmentDate',
        dateRange: [`${filterPrevDate}`, `${filterNextDate}`],
      },
    ],
  };
  try {
    // const result = await cubejsApi.load(qFetchTotalStudyCircleCount);
    const startTime = Date.now();
    const result = await cubejsApi.load(qFetchTotalStudyCircleCount);
    const endTime = Date.now();
    const timeTaken = endTime - startTime;
    console.log('Time taken for fetchTotalStudyCircleCount', timeTaken);
    return result;
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-PERF-04 - Cube API to fetch summary of performance details page
export async function fetchDifficultySummary(filter, filterPrevDate, filterNextDate) {
  const cubejsApi = cubeFunction();
  const qSummaryPerf = {
    limit: 5000,
    measures: [
      'AssessmentResponse.totalCorrects',
      'AssessmentResponse.totalNearCorrects',
      'AssessmentResponse.totalIncorrects',
      'AssessmentResponse.totalNotAnswereds',
      'AssessmentResponse.count',
      'AssessmentResponse.questionsCount',
    ],
    timeDimensions: [
      {
        dimension: 'AssessmentResponse.assessmentDate',
        dateRange: [`${filterPrevDate}`, `${filterNextDate}`],
      },
    ],
    filters: [...filter],
    dimensions: ['Question.difficulty'],
    order: [['Question.difficulty', 'asc']],
  };
  try {
    // const result = await cubejsApi.load(qSummaryPerf);
    const startTime = Date.now();
    const result = await cubejsApi.load(qSummaryPerf);
    const endTime = Date.now();
    const timeTaken = endTime - startTime;
    // console.log('Time taken for fetchDifficultySummary', timeTaken);
    return result;
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-PERF-05-01 - Cube API to fetch accuracy of performance details page
export async function fetchPerformanceAccuracy(studyCircleId, selectedGroupId) {
  const cubejsApi = cubeFunction();
  const selGrpId = selectedGroupId.toString();
  const qAccuracy = {
    measures: ['AssessmentResponse.avgAccuracy'],
    timeDimensions: [
      {
        dimension: 'AssessmentResponse.assessmentDate',
      },
    ],
    filters: [
      {
        member: 'Assessment.groupSessionId',
        operator: 'equals',
        values: [selGrpId],
      },
      {
        member: 'AssessmentResponse.studyCircleId',
        operator: 'equals',
        values: [studyCircleId],
      },
    ],
    order: {},
  };
  try {
    const result = await cubejsApi.load(qAccuracy);
    return result?.loadResponses[0]?.data[0]['AssessmentResponse.avgAccuracy'];
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-PERF-05-02 - Cube API to fetch group accuracy of performance details page
export async function fetchPerformanceGrpAccuracy(selectedGroupId) {
  const cubejsApi = cubeFunction();
  const selGrpId = selectedGroupId.toString();
  const qGrpAccuracy = {
    measures: ['AssessmentResponse.avgAccuracy'],
    timeDimensions: [
      {
        dimension: 'AssessmentResponse.assessmentDate',
      },
    ],
    filters: [
      {
        member: 'Assessment.groupSessionId',
        operator: 'equals',
        values: [selGrpId],
      },
    ],
    order: {},
  };

  try {
    const result = await cubejsApi.load(qGrpAccuracy);
    return result?.loadResponses[0]?.data[0]['AssessmentResponse.avgAccuracy'];
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-PERF-06-01 - Cube API to fetch score of performance details page
export async function fetchPerformanceScore(studyCircleId, selectedGroupId) {
  const cubejsApi = cubeFunction();
  const selGrpId = selectedGroupId.toString();
  const qScore = {
    measures: ['AssessmentResponse.marksScored'],
    timeDimensions: [
      {
        dimension: 'AssessmentResponse.assessmentDate',
      },
    ],
    filters: [
      {
        member: 'Assessment.groupSessionId',
        operator: 'equals',
        values: [selGrpId],
      },
      {
        member: 'AssessmentResponse.studyCircleId',
        operator: 'equals',
        values: [studyCircleId],
      },
    ],
  };

  try {
    const result = await cubejsApi.load(qScore);
    console.log('result', result);
    return result?.loadResponses[0]?.data[0]['AssessmentResponse.marksScored'];
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-PERF-06-01 - Cube API to fetch group score of performance details page
export async function fetchPerformanceGrpScore(selectedGroupId) {
  const cubejsApi = cubeFunction();
  const selGrpId = selectedGroupId.toString();
  const qGrpScore = {
    measures: ['AssessmentResponse.avgMarksScored'],
    timeDimensions: [
      {
        dimension: 'AssessmentResponse.assessmentDate',
      },
    ],
    filters: [
      {
        member: 'Assessment.groupSessionId',
        operator: 'equals',
        values: [selGrpId],
      },
    ],
  };

  try {
    const result = await cubejsApi.load(qGrpScore);
    return result?.loadResponses[0]?.data[0]['AssessmentResponse.avgMarksScored'];
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-PERF-06-03 - Cube API to fetch score grap of performance details page
export async function fetchPerformanceScoreGraph(studyCircleId, selectedGroupId, filterPrevDate, filterNextDate) {
  const cubejsApi = cubeFunction();
  const selGrpId = selectedGroupId.toString();
  const qScoreGraph = {
    measures: [
      'AssessmentResponse.questionsCount',
      'AssessmentResponse.totalCorrects',
      'AssessmentResponse.totalNearCorrects',
      'AssessmentResponse.totalIncorrects',
      'AssessmentResponse.totalNotAnswereds',
    ],
    timeDimensions: [
      {
        dimension: 'AssessmentResponse.assessmentDate',
        dateRange: [`${filterPrevDate}`, `${filterNextDate}`],
      },
    ],
    filters: [
      {
        member: 'Assessment.groupSessionId',
        operator: 'equals',
        values: [selGrpId],
      },
      {
        member: 'AssessmentResponse.studyCircleId',
        operator: 'equals',
        values: [studyCircleId],
      },
    ],
  };
  try {
    const result = await cubejsApi.load(qScoreGraph);
    return result;
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-PERF-07-01 - Cube API to fetch speed of performance details page
export async function fetchPerformanceSpeed(studyCircleId, selectedGroupId) {
  const cubejsApi = cubeFunction();
  const selGrpId = selectedGroupId.toString();
  const qSpeed = {
    measures: ['AssessmentResponse.avgTimeTaken'],
    timeDimensions: [
      {
        dimension: 'AssessmentResponse.assessmentDate',
      },
    ],
    filters: [
      {
        member: 'Assessment.groupSessionId',
        operator: 'equals',
        values: [selGrpId],
      },
      {
        member: 'AssessmentResponse.studyCircleId',
        operator: 'equals',
        values: [studyCircleId],
      },
      {
        member: 'AssessmentResponse.result',
        operator: 'notEquals',
        values: ['absent'],
      },
    ],
  };

  try {
    const result = await cubejsApi.load(qSpeed);
    return result;
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}

//Reference: RA-PERF-07-02 - Cube API to fetch group speed of performance details page
export async function fetchPerformanceGrpSpeed(selectedGroupId) {
  const cubejsApi = cubeFunction();
  const selGrpId = selectedGroupId.toString();
  const qGrpSpeed = {
    measures: ['AssessmentResponse.avgTimeTaken'],
    timeDimensions: [
      {
        dimension: 'AssessmentResponse.assessmentDate',
      },
    ],
    filters: [
      {
        member: 'Assessment.groupSessionId',
        operator: 'equals',
        values: [selGrpId],
      },
    ],
  };

  try {
    const result = await cubejsApi.load(qGrpSpeed);
    return result?.loadResponses[0]?.data[0]['AssessmentResponse.avgTimeTaken'];
  } catch (error) {
    console.log(error);
    if (error.response.error) {
      console.log(error.response.error);
      toast.error(error.response.error);
    }
    return error;
  }
}
