import React from 'react';
import { Line } from 'react-chartjs-2';
import { FaAngleRight } from 'react-icons/all';
import styles from './TrendChart.module.css';

const chartData = [{ cValue: [2, 3, 2, 3.5] }, { cValue: [5, 3, 2, 3] }, { cValue: [4, 3, 3.5, 5] }, { cValue: [2, 3, 4, 4.5] }];
const chartSideName = [{ name: 'Velocity' }, { name: 'Group Rank' }, { name: 'Score' }, { name: 'Accuracy' }];

const TrendChart = (props) => {
  const [count, setCount] = React.useState(0);
  const [value, setValue] = React.useState(chartData[0]);
  const [verticalName, setVerticalName] = React.useState(chartSideName[0]);

  const data = {
    labels: ['13 sep', '14 sep', '15 sep', '16 sep', '17 sep', '18 sep', '19 sep'],
    datasets: [
      {
        label: verticalName.name,
        data: value.cValue,
        dataGroupRank: [30, 56, 12, 39],
        borderColor: '#4C7DF0',
        pointBorderColor: '#00237B',
        pointBackgroundColor: '#4C7DF0',
        lineTension: 0,
        fill: false,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const type = data.datasets[tooltipItem.datasetIndex].label;
          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          let total = 0;
          for (let i = 0; i < data.datasets.length; i++) total = data.datasets[i].dataGroupRank[tooltipItem.index];
          if (count === 1) {
            return [type + ' : ' + value];
          } else {
            return [type + ' : ' + value, 'Group Rank : ' + total];
          }
        },
        title: function () {},
      },
      backgroundColor: '#E9F0FF',
      radius: 2,
      titleFontColor: '#404040',
      bodyFontColor: '#404040',
      bodyFontSize: 12,
      displayColors: false,
      width: 104,
      height: 47,
    },
    layout: {
      padding: {
        left: 15,
        right: 5,
        top: 25,
      },
    },
    bezierCurve: false,
    elements: {
      point: {
        radius: 5,
      },
      line: {
        tension: 0,
      },
    },
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: verticalName.name,
      position: 'left',
      backgroundColor: '#f4d5a6',
    },
    dataset: {
      barPercentage: 0.6,
      categoryPercentage: 0.7,
    },
    scales: {
      scaleSteps: 5,
      scaleStepWidth: 50,
      yAxes: [
        {
          gridLines: {
            display: true,
            borderDash: [8, 4],
            color: '#e8dcdc',
          },
          ticks: {
            min: 0,
            max: 5,
            stepSize: 1,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          // categoryPercentage: .7,
          // barPercentage:.6,
          ticks: {
            max: 20,
            maxTicksLimit: 20,
            padding: 5,
            stepSize: 2,
          },
        },
      ],
      responsive: true,
      maintainAspectRatio: false,
    },
  };

  const handleChange = (index) => {
    setCount(index);
    setValue(chartData[index]);
    setVerticalName(chartSideName[index]);
  };
  // console.log(value)

  return (
    <div className={styles.left_div_flex}>
      <div className={styles.left_div_col}>
        {chartSideName.map((el, index) => (
          <div
            className={count === index ? styles.trend_div_active : styles.trend_div}
            onClick={(e) => handleChange(index)}
            key={index}
          >
            <div className={styles.trend_div_name}>{el.name}</div>
            {count === index ? (
              <div style={styles.icon}>
                <FaAngleRight
                  color="#ffffff"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignSelf: 'center',
                  }}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        ))}
      </div>
      <div className={styles.right_div_col}>
        <Line options={options} data={data} width={500} height={205} />
      </div>
    </div>
  );
};

export default TrendChart;
