import React from 'react';
import studio from '../../assets/images/studio.png';
import raiseOrg from '../../assets/images/raise-org.png';

const AssessmentStudioSection = (props) => {
  const { styles, readVelocitySession, assessmentDetails } = props;
  return (
    <div className={styles.studioSection}>
      <div className={styles.box3_left}>
        <div className={styles.box3_left_img}>
          <img src={studio} alt="img" />
        </div>
        <div className={styles.box3_left_text}>
          {/* {assessmentDetails.orgenizer_type == "Educator" ? "Classroom" : "Studio"} */}
          {'Studio'}
          <div className={styles.sessionTypeDisplay}>{assessmentDetails.assessment_type}</div>
        </div>
      </div>
      <div className={styles.box3_border}>
        <div className={styles.box3_middle_text}>
          <div className={styles.box3_center_body}>
            <div className={styles.box3_center_body_txt1}>Course</div>
            <div className={styles.box3_center_body_txt2}>{assessmentDetails.study_circle}</div>
          </div>
          <div className={styles.box3_center_body}>
            <div className={styles.box3_center_body_txt1}>subject</div>
            <div className={styles.box3_center_body_txt2}>{assessmentDetails.subject}</div>
          </div>
          <div className={styles.box3_center_body}>
            <div className={styles.box3_center_body_txt1}>sub-subject</div>
            <div className={styles.box3_center_body_txt2}>{assessmentDetails.sub_subject}</div>
          </div>
          <div className={styles.box3_center_body}>
            <div className={styles.box3_center_body_txt1}>studio educator</div>
            <div className={styles.box3_center_body_txt2}>{assessmentDetails.group_educator_name}</div>
          </div>
          <div className={styles.box3_center_body}>
            <div className={styles.box3_center_body_txt1}>time</div>
            <div className={styles.box3_center_body_txt2}>
              {assessmentDetails?.start_time?.slice(0, 5) + ' - ' + assessmentDetails?.end_time?.slice(0, 5)}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.box3_right}>
        <div className={styles.box3_right_img}>
          <img src={raiseOrg} alt="img" />
        </div>
        <div className={styles.box3_right_text}>
          {readVelocitySession['AssessmentResponse.handRaiseCount'] ? readVelocitySession['AssessmentResponse.handRaiseCount'] : 0}
        </div>
      </div>
    </div>
  );
};

export default AssessmentStudioSection;
