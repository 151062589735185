import React from 'react';
import ReactLoading from 'react-loading';
import styled from './loadingBars.module.css';

const LoadingBars = () => {
  return (
    <div className={styled.loading_animation}>
      <ReactLoading type="bars" color="#FC8802" height="10%" width="10%" className={styled.reactLoadingBars} />
    </div>
  );
};

export default LoadingBars;
