import React from 'react';
import { Bar } from 'react-chartjs-2';

const DifficultyChart = ({ values }) => {
  const correct = values.correct;
  const nearCorrect = values.nearCorrect;
  const incorrect = values.incorrect;
  const notAnswered = values.notAnswered;
  const totalValue = values.totalValue;

  const data = {
    labels: values.difficulty,
    datasets: [
      {
        label: 'correct',
        data: correct,
        total: totalValue,
        borderColor: '',
        backgroundColor: '#0FC155',
        pointBackgroundColor: 'black',
        pointBorderColor: 'red',
      },
      {
        label: 'near correct',
        data: nearCorrect,
        borderColor: '',
        backgroundColor: '#4C7DF0',
        pointBackgroundColor: 'black',
        pointBorderColor: 'red',
      },
      {
        label: 'incorrect',
        data: incorrect,
        borderColor: '',
        backgroundColor: '#E50E33',
        pointBackgroundColor: 'black',
        pointBorderColor: 'red',
      },
      {
        label: 'not answered',
        data: notAnswered,
        borderColor: '',
        backgroundColor: '#9B9B9B',
        pointBackgroundColor: 'black',
        pointBorderColor: 'red',
      },
    ],
  };
  const options = {
    animation: {
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        ctx.textAlign = 'center';
        ctx.fillStyle = 'rgba(0, 0, 0, 1)';
        ctx.textBaseline = 'bottom';
        this.data.datasets[0].total.forEach(function (data, index) {
          var barValue = this.data.datasets[0].total[index];
          var meta = chartInstance.controller.getDatasetMeta(3);
          var posX = meta.data[index]._model.x;
          var posY = meta.data[index]._model.y;

          ctx.fillStyle = 'black';
          ctx.fillText(barValue, posX, posY - 8);
        }, this);
      },
    },
    legend: {
      display: false,
    },
    plugins: {
      labels: false,
    },
    dataset: {
      barPercentage: 0.8,
      categoryPercentage: 0.4,
    },
    scales: {
      yAxes: [
        {
          stacked: true,
          ticks: {
            fontSize: 12,
            fontColor: '#000000',
            fontWeight: 800,
            min: 0,
            max: 120,
            stepSize: 20,
            padding: 10,
            callback: function (value) {
              if (value > 100) {
                return '';
              }
              return value.toFixed(0) + '%'; // convert it to percentage
            },
          },
          gridLines: {
            drawBorder: true,
            display: true,
            borderDash: [5, 6],
            color: '#e8dcdc',
          },
          scaleLabel: {
            display: true,
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
          },
          // categoryPercentage: .4,
          // barPercentage:.8,
          ticks: {
            maxTicksLimit: 20,
            padding: 5,
            fontWeight: 600,
            fontColor: '#000000',
            fontSize: 13,
          },
        },
      ],
      responsive: true,
      maintainAspectRatio: false,
    },
  };
  return (
    <div>
      <Bar data={data} options={options} width={450} height={150} />
    </div>
  );
};

export default DifficultyChart;
